<template>
    <div class="mdui-call">
        <div class="mdui-call-link">
            <ul>
                <li><a href="javascript:;" @click="$router.push({name:'ReturnPolicy'})">退換貨政策</a></li>
                <li><a href="javascript:;" @click="$router.push({name:'Questions'})">付款&運送方式</a></li>
                <li><a href="javascript:;" @click="$router.push({name:'AboutUs'})">關於&聯絡我們</a></li>
                <li><a href="javascript:;" @click="$router.push({name:'PrivacyPolicy'})">隱私條款</a></li>
                <li id="content-pc"><a href="javascript:;" @click="$router.push({name:'ServiceTerms'})">服務政策</a></li>
            </ul>
        </div>
        <div class="mdui-call-text">客服電話:{{config.service_hotline}} Email:{{config.shop_service_email}}</div>
        <div class="mdui-call-text">全館免運費、支援線上付款、宅配及超商取貨</div>
        <div class="mdui-call-text">Copyright © {{config.web_site_name}} All Rights Reserved</div>
        <div id="content-pc-service" class="service-float">
            <span class="contact line" v-if="config.shop_service"><a :href="config.shop_service"><i class="icon-line"></i></a></span>
            <span class="contact messenger" v-if="config.messenger"><a :href="config.messenger" target="_blank"><i class="icon-messenger"></i></a></span>
            <!--<span class="contact facebook" v-if="config.facebook_home"><a :href="config.facebook_home" target="_blank"><i class="icon-facebook"></i></a></span>-->
	    </div>
    </div>
</template>
<script>
    export default {
        name: "footmain",
        created(){},
        data() {
            return {
                config:{},
           };
        },
        mounted(){
            this.config = this.utils.config()
        },
        methods: {},
        watch: {},
    }
</script>
<style scoped>
.mdui-call {width: 100%;color:#7b7b7b;background: #FFF;padding-bottom: 25px;}
.mdui-call .mdui-call-link ul li {margin: 8px 0;padding: 0;border-right: 1px solid #BCBCBC;border-radius: 0;text-align: center;flex: 1;display: block;}

@media (min-width:768px) {
.mdui-call .mdui-call-link ul {list-style: none;padding:5px 6%;margin: 0;display: flex;}
.mdui-call .mdui-call-link ul li {font-size: 16px;}
}

.mdui-call .mdui-call-link ul li:last-child {border-right: 0}

@media (max-width:767px) {
.mdui-call .mdui-call-link ul {list-style: none;padding: 0 3px;margin: 0;display: flex;}
.mdui-call .mdui-call-link ul li {font-size: 12px;}
.mdui-call .mdui-call-link ul li:nth-child(4) {border-right: 0;}
.mdui-call .mdui-call-text {font-size: 12px;}
}

.mdui-call .mdui-call-link ul li a {text-decoration: none;color:#7b7b7b;}
.mdui-call .mdui-call-text {padding: 0 0 8px;text-align: center;}
.mdui-call .mdui-call-text img{margin-right: 3px;}

.service-float{position:fixed;bottom:160px;right: 30px;z-index:100;}
.service-float .contact{margin: 5px 0;height: 42px;width: 42px;border-radius: 50%;opacity: .8;}
.service-float .contact i{margin: auto;padding-top: 5px;}
.contact{display: block;margin: 5px 20px;height: 45px;border-radius: 3px;background-color:#1296db;font-size: 18px;color: #fff;text-align: center;line-height: 45px;}
.contact a{color:#fff;font-size:18px;text-decoration:none;}
.contact i{margin-right: .5rem;}
.tel{background-color:#1296db;}
.phone{background-color:#1296db;}
.email{background-color:#34c782;}
.facebook{background-color:#3c5b9b;}
.messenger{background-image:linear-gradient(to left,#00c3ff 22%,#0075ff 76%);}
.line{background-color:#00c300;}
.whatsapp{background-color:#3ac34c;}
.contact_qq{background-color:#1296db;}
.icon-tel{display: inline-block;width: 1.5rem;height: 1.5rem;background: url(../../assets/images/tel.svg) no-repeat;background-size: 1.5rem;vertical-align: middle;}
.icon-phone{display: inline-block;width: 1.5rem;height: 1.5rem;background: url(../../assets/images/phone.svg) no-repeat;background-size: 1.5rem;vertical-align: middle;}
.icon-facebook{display: inline-block;width: 1.5rem;height: 1.5rem;background: url(../../assets/images/facebook.svg) no-repeat;background-size: 1.5rem;vertical-align: middle;}
.icon-messenger{display: inline-block;width: 1.8rem;height: 1.8rem;background: url(../../assets/images/messenger.svg) no-repeat;background-size: 1.8rem;vertical-align: middle;}
.icon-line{display: inline-block;width: 2rem;height: 2rem;background: url(../../assets/images/line.svg) no-repeat;background-size: 2rem;vertical-align: middle;}
.icon-whatsapp{display: inline-block;width: 1.8rem;height: 1.8rem;background: url(../../assets/images/whatsapp.svg) no-repeat;background-size: 1.8rem;vertical-align: middle;}
</style>