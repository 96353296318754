<template>
<div>
    <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0 mdui-appbar-scroll-toolbar-hide mdui-headroom .mdui-appbar-scroll-toolbar-hide">
        <div class="mdui-toolbar-title">
            <div class="top-left-options">
                <div class="left">
                    <a class="text-icon-options" href="javascript:void(0)"  @click="utils.goBack()">
                        <img src="../assets/images/icon-back.png">
                    </a>
                </div>
            </div>

            <div class="top-right-options">
                <a id="customer_service" class="text-icon-options" :href="shop_service">
                    <img src="../assets/images/icon-customer-service.png">
                    <span class="text-service">客服</span>
                </a>
                <a id="initSearch" class="text-icon-options" href="/search">
                    <img src="../assets/images/icon-search.png" />
                    <span class="text-foot">搜尋</span>
                </a>
                <a id="footprint" class="text-icon-options" href="javascript:void(0)" @click="showFootprint = true">
                    <img src="../assets/images/icon-footprint.png">
                    <span class="text-foot">瀏覽</span>
                </a>
            </div>
        </div>

        <div class="mdui-nav" id="headerNav">
            <div class="li" :class="{'active':item.tag == tag}" v-for="(item,index) in tagsList" @click="goList(item.tag)">
                {{item.tag}}
            </div>
            <div class="li" style="min-width:300px;"></div>
        </div>
    </div>

    <headerTopPc></headerTopPc>
    <div id="content-pc">
        <section class="breadcrumb-area">
            <div class="breadcrumb-text">
                <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>商品類型</span>
            </div>
        </section>
        <div class="tags-cate">
        <div id="headerNav">
            <div class="li" :class="{'active':item.tag == tag}" v-for="(item,index) in tagsList" @click="goList(item.tag)">
                {{item.tag}}
            </div>
        </div>
        </div>
    </div>
    <div class="mdui-container-fluid mdui-page-container">
        <div class="pro_box">
            <div class="module-31">
                <div class="module-31-card" v-for="(item,index) in goodsList">
                    <a href="javascript:void(0)" @click="utils.url('detail',{id:item.id})">
                        <div class="mdui-card-media">
                            <img :src="item.images">
                        </div>
                        <div class="mdui-card-content module-31-content">
                            <div class="module-31-title"><div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}</div>
                            <div class="module-31-bottom">
                                <div class="module-31-price">
                                    <span class="module-31-discountprice">{{item.sale_price}}</span>
                                    <span class="module-31-sales"></span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="no-data"></div>
        </div>
    </div>
    <div id="content-pc">
        <footmain></footmain>
    </div>
    <footprint v-if="showFootprint" @close="showFootprint = false"></footprint>
    <loader :loader="loader"></loader>
</div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import footmain from '../views/common/footmain.vue';
    import footprint from '../views/common/footprint.vue';
    import vuescroll from '../components/vue-scroll.vue';
    import loader from '../views/common/loader.vue';
    export default {
        components:{headerTopPc,footmain,footprint,vuescroll,loader},
        name: "tags",
        data() {
            return {
                showFootprint:false,
                tagsList:[],
                tag:'',
                goodsList:[],
                shop_service:'',
                config:{},
                page:1,
                isLast:false,
                limit:8,
                isLoading:false,
                loader:false
            }
        },
        methods: {

            getList(){

                if( this.isLast || this.isLoading ) {
                    return false;
                }
                this.isLoading = true;
                this.loader = true

                this.api.get('goods/listByTag',{
                    tag:this.tag,
                    page:this.page,
                    limit:this.limit,
                }).then( res => {
                    setTimeout(()=>{
                        this.loader = false;
                        this.isLoading = false;
                    },600)
                    if( res.code != 200 ){
                        this.$layer.msg( res.message );
                        return false;
                    }

                    let data = res.data;
                    if(data.data.length > 0){
                        this.utils.merge(this.goodsList,data.data);
                        this.page++
                    }
                    if( data.last_page == 0 || data.current_page == data.last_page ){
                        this.isLast = true;
                    }
                } )
            },

            scrollList() {
                if( this.utils.getFootHeight() < 150){
                    this.getList();
                }
            },

            goList(tags){
                location.href = '/tags?key='+tags
            },

            setScroll(){
                let active = document.querySelector("#headerNav .li.active")
                let width = document.body.offsetWidth; // 屏幕宽度
                let activeLeft = active.offsetLeft;
                let left = activeLeft + ( active.offsetWidth / 2 ) - ( width / 2 );
                document.querySelector("#headerNav").scrollTo(left,0)
            },
        },
        watch: {},
        mounted(){
            this.tag = this.$route.query.key;
            this.api.get('goods/tagsList').then(res=>{
                this.tagsList = res.data
                setTimeout(()=>{
                    this.setScroll();
                },1000)
            })

            this.page = 1;
            this.isLast = false;
            this.isLoading = false;
            this.getList()
            addEventListener("scroll",this.scrollList,true);

            this.api.get('config/get',{name:'shop_service'}).then(res=>{
                this.shop_service = res.data
            })

            this.config = this.utils.config()
             // 添加像素
            this.utils.addXiangsu()
        },

        destroyed() {
            removeEventListener('scroll',this.scrollList,true)
        }
    }
</script>

<style scoped>
.mdui-nav { overflow-x:scroll;overflow-y:hidden;width:100%;white-space: nowrap;display: -webkit-box; background:#fff;scroll-behavior:smooth;cursor:pointer; }
.mdui-nav::-webkit-scrollbar {border-width:10px;}
.mdui-nav .li { display:inline-block;width:auto;height:36px;line-height:36px;text-align:center;font-weight:bold;cursor:pointer;overflow:hidden;border-bottom:3px solid #fff;margin:0 10px;font-size:16px;}
.mdui-nav .li.active { color:#E94D8A;border-bottom:3px solid #E94D8A;}
@media (max-width:767px) {
    .mdui-page-container{min-height:100px;padding-left:0;padding-right:0;margin:40px 0 50px;}
    .module-31{background:#f4f4f4;color:#000;padding:8px 2.4% 0;box-sizing:border-box;display:flex;justify-content:space-between;flex-wrap:wrap;}
    .module-31 .module-31-card{width:48.8%;margin-bottom:2%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;}
    .module-31 .module-31-card:nth-last-child(1){margin-left:0;}
}

@media (min-width:768px) {
    .tags-cate{width:750px;margin:0 auto;padding:8px 1%; }
    .mdui-page-container{margin-top:10px;}
    .module-31{width:750px;margin:0 auto;background:#f4f4f4;color:#000;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:992px) {
    .tags-cate{width:970px;margin:0 auto;padding:8px 1%; }
    .mdui-page-container{margin-top:10px;}
    .module-31{width:970px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:1200px) {
    .tags-cate{width:1170px;margin:0 auto;padding:8px 1%; }
    .mdui-page-container{margin-top:10px;}
    .module-31{width:1170px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

.module-31 .module-31-card {display:inline-block;}
.module-31 .module-31-card:nth-child(2n) {float:right;}
.tags-cate .li { border: 1px solid #ddd;padding: 5px 10px;border-radius: 5px;text-decoration: none;color: #666;background: #fff;margin-right: 10px;display: inline-block;margin-bottom: 5px; cursor: pointer;}
.tags-cate .li:hover { border: 1px solid #E94D8A;color: #E94D8A;}
.tags-cate .li.active { color:#E94D8A;border:1px solid #E94D8A;}
.mdui-overlay{background:rgba(0,0,0,.5)}
.mdui-toolbar-title{box-shadow:none}
.mdui-nav::-webkit-scrollbar{display:none;}
.mdui-nav .mdui-tab{box-sizing:border-box;min-height:initial;height:auto;padding-top:2px;-webkit-overflow-scrolling:touch;position:absolute;overflow-x:visible;overflow-y:visible;}
.mdui-nav .mdui-tab::-webkit-scrollbar{display:none}
.mdui-nav .mdui-tab a{/* margin-right:12px;*/text-transform:initial;padding:0 6px;min-width:auto;width:auto;min-height:initial;color:#363636;opacity:1;height:30px;cursor:pointer;font-size:16px;font-weight:bold;display:inline-block;flex:none;line-height:27px;padding:0 10px;} 
/* .mdui-nav .mdui-tab a:first-child{margin-left:12px;} */
.mdui-nav .mdui-tab a.mdui-tab-active{color:#E94D8A;position:relative;}
.mdui-nav .mdui-tab a.mdui-tab-active::after{display:block;background:#E94D8A;height:3px;border-radius:10px;content:'';width: 100%;/*position:absolute;*/bottom:0;}
.mdui-tab-flex{height:auto;background:#FAFAFA;position:relative;display:none;}
.mdui-tab-flex.show{display:block!important;}
.mdui-tab-flex.hide{display:none!important;}
.mdui-tab-flex .mdui-tab-box{width:100%;display:flex;min-height:initial;max-height:initial;justify-content:center;align-items:center;}
.mdui-tab-flex .arrow-down{border-radius:0;min-width:36px;padding:0;color:#7B7B7B;display:flex;align-items:center;}
.mdui-tab-flex .arrow-down:active,.mdui-tab-flex .arrow-down:hover{background:transparent;}
.mdui-tab-flex .mdui-tab-mes{background:#FAFAFA;height:44px;line-height:44px;padding:0 4px;font-size:14px;color:#777;}
.mdui-tab-flex .mdui-tab-toggle{display:flex;align-items:center;justify-content:flex-end;color:#777;height:44px;line-height:44px;}
.mdui-tab-flex .mdui-tab-navport{width:100%;background:#fff;padding:15px 4%;box-sizing:border-box;flex-wrap:wrap}
.mdui-tab-flex .mdui-tab-navport a.nav{flex:initial;width:48%;opacity:1;border:1px solid #DFDEDB;border-radius:8px;min-height:initial;min-width:initial;display:flex;align-items:center;justify-content:flex-start;margin-bottom:15px;height:44px;cursor:pointer;padding:0 15px;flex-direction:row;text-align:center;}
.mdui-tab-flex .mdui-tab-navport a.nav.active { border:1px solid #E94D8A; }
.mdui-tab-flex .mdui-tab-navport a.nav > img{width:40px;height:40px;margin-right:6px;border-radius:50%!important;}
.mdui-tab-flex .mdui-tab-navport a.nav > i{width:40px;height:40px;background:#f4f4f4;margin-right:6px;font-size:0;}
.mdui-tab-flex .mdui-tab-navport a.nav > span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.mdui-tab-flex .mdui-tab-navport a.nav.active > span { color:#E94D8A; }
.mdui-tab-flex .mdui-tab-navport a.nav:nth-child(2n+1){margin-right:2%;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-child(2n){margin-left:2%;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-last-child(1){margin-bottom:0;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-last-child(2){margin-bottom:0;}
.mdui-tab-flex .mdui-tab-navport a.nav-active{border-color:#E94D8A;color:#E94D8A;}
.mdui-loading{text-align:center;padding:8%;font-size:12px;color:#7B7B7B}
.mdui-loading i{font-size:16px;margin-left:4px;}

.module-31 .module-31-card > a{text-decoration:none;}
.module-31 .module-31-card .mdui-card-media{padding-bottom:100%;}
.module-31 .module-31-card .mdui-card-media > img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.module-31 .module-31-content{padding:8px;color:#000;}
.module-31 .module-31-content .module-31-title{height:36px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;width:100%;font-size:13px;line-height:18px;}
.module-31 .module-31-content .module-31-price{font-size: 0;line-height: normal;padding:6px 0 0;display:flex; justify-content: space-between;align-items: center;}
.module-31 .module-31-content .module-31-price > span:last-child{font-size:12px;color:#777777;line-height:12px;display:inline-block;}
.module-31 .module-31-content .module-31-discountprice{font-size:22px;color:#E94D8A;line-height:22px;margin-right:8px;}
.module-31 .module-31-content .module-31-discountprice:before{content:'NT$';margin-right:1px;font-size:12px;line-height:12px;}
.module-31 .module-31-content .module-31-sales{font-size: 12px;color: #777777; line-height: 22px; float:right;}
.module-31 .module-31-content .module-31-originalprice{text-decoration:line-through}
.module-31 .module-31-content .module-31-originalprice:before{content:'NT$';}
.module-31 .module-31-content .module-31-btn{display:flex;align-items:baseline;justify-content:space-between;}
.module-31 .module-31-content .module-31-btn button{background:#E94D8A;border-radius:3px;width:88px;height:26px;border:none;box-shadow:none;font-size:12px;color:#FFFFFF;line-height:12px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.module-31 .module-31-content .module-31-btn span{font-size:13px;color:#777777;text-align:right;line-height:13px;}
.module-31 .module-31-content .module-31-tag{height:16px;overflow:hidden;font-size:0;margin:5px 0;}
.module-31 .module-31-content .module-31-tag span{display:inline-block;padding:0 7px;border:none;border-radius:8px;font-size:12px;color:#E56214;line-height:16px;height:16px;background:#FCE9D5;box-sizing:border-box;margin-right:6px;}
.module-31 .module-31-content .module-31-tag span:last-child{margin-right:0;}
.module-31 .module-31-more{background:#FFF;color:#7B7B7B;padding:0;border:none!important}
.module-31 .module-31-more a{position:relative;color:#7b7b7b;display:block;padding-bottom:100%;border:1px solid #f4f4f4;}
.module-31 .module-31-more a .textBox{position:absolute;left:50%;margin-left:-25px;top:50%;margin-top:-28px;text-align:center;}
.module-31 .module-31-more a span{display:inline-block;}
.module-31 .module-31-more a span.circle{border:1px solid #7B7B7B;border-radius:50%;padding:3px;margin-top:12px;}
.mdui-row,[class*="mdui-row-"]{margin-left:0;margin-right:0}
.flex { display: flex; }
.hide { display: none; }
</style>