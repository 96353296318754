<template>
  <div>
    <headerTop></headerTop>
    <headerTopPc></headerTopPc>
    <!-- 首页开始 -->
    <div class="mdui-container-fluid mdui-page-container">
        <div class="home">
            <div class="pro_box">
                <div class="mdui-row1">
                    <a :href="banner[0].href" v-if="banner.length == 1">
                        <img :src="banner[0].images" style="width:100%">
                    </a>
                    <swiper style="width:100%" :options="swiperOption" v-else>
                        <swiper-slide class="swiper-slide" v-for="item in banner">
                            <a :href="item.href" :target="item.target">
                                <img :src="item.images" style="width:100%">
                            </a>
                        </swiper-slide>
                        <div class="swiper-pagination"  slot="pagination"></div>   
                    </swiper>
                </div>
            </div>

            <div id="content-mobile" class="module-01-a-check">
                <div>
                    <img class="mdui-custom-icons" src="../assets/images/icon-check-circle.png"/>全館免運費
                </div>
                <div>
                    <img class="mdui-custom-icons" src="../assets/images/icon-check-circle.png"/>七日鑒賞期可退換貨
                </div>
                <div>
                    <img class="mdui-custom-icons" src="../assets/images/icon-check-circle.png"/>線上付款/貨到付款
                </div>
            </div>

            <div id="content-pc" class="service-main">
                <div class="service-items">
                    <div class="service-items-icon">
                        <img src="../assets/images/icon-service-1.png">
                    </div>
                    <div class="service-items-info">
                        <h3>免運費</h3>
                        <p>全館商品免運費</p>
                    </div>
                </div>
                <div class="service-items">
                    <div class="service-items-icon">
                        <img src="../assets/images/icon-service-2.png">
                    </div>
                <div class="service-items-info">
                    <h3>七日鑒賞期</h3>
                    <p>七天內可退換貨</p>
                    </div>
                </div>
                <div class="service-items">
                    <div class="service-items-icon">
                        <img src="../assets/images/icon-service-3.png">
                    </div>
                    <div class="service-items-info">
                        <h3>付款方式</h3>
                        <p>可線上付款或貨到付款</p>
                    </div>
                </div>
            </div>

            <!-- 按一级分类显示 start -->
            <div class="pro_box">
                <div v-for="category in goodsList">
                    <div class="floor-list">
                        <span class="floor-name">{{category.title}}</span>
                        <span class="floor-more" @click="utils.goUrl('list',{id:category.id})">更多 ></span>
                    </div>
                    <div class="module-31">
                        <div class="module-31-card" @click="utils.url('detail',{id:item.id})" v-for="item in category.data">
                            <a href="javascript:void(0)">
                                <div class="mdui-card-media">
                                    <img :src="item.images"/>
                                </div>

                                <div class="mdui-card-content module-31-content">
                                    <div class="module-31-title"><div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}</div>
                                    <div class="module-31-bottom">
                                        <div class="module-31-price">
                                            <span class="module-31-discountprice">{{item.sale_price}}</span>
                                            <span class="module-31-sales"></span>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 按一级分类显示 end -->
        </div>
    </div>
    <!-- 首页结束 -->
    <footmain></footmain>
    <loader :loader="loader"></loader>
</div>
</template>
<script>
// @ is an alias to /src

import { WOW } from "wowjs";
import headerTopPc from '../views/common/headerPc.vue';
import headerTop from '../views/common/header.vue';
import vuescroll from '../components/vue-scroll.vue';
import footmain from '../views/common/footmain.vue';
import loader from '../views/common/loader.vue';

export default {
    name: "index",
    components: {
        headerTopPc,
        headerTop,
        vuescroll,
        footmain,
        loader
    },
    created() {},
    data() {
        return {
            banner:[],
            goodsCategoryTop:[],
            goodsList:[],
            config:{},
            showFootprint : false,
            swiperOption:{
                    //显示分页
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
                //自动轮播
                autoplay: {
                    delay: 2000,
                    //当用户滑动图片后继续自动轮播
                    disableOnInteraction: false,
                },
                //开启循环模式
                loop: true,
                autoHeight: true
            },
            loader:false
        };
    },
    filters: {},
    methods: {},
    mounted() {
        this.loader = true
         /**
         * 产品一级分类
         */
        this.api.get('goods/getAllGoodsCategory',{}).then( res => {
            this.loader = false
            try {
                this.goodsCategoryTop = res.data
            } catch (err){
                console.log(err)
            }
        })

        /**
         * 幻灯片列表
         */
        this.api.get('slide/module',{module_id:1}).then(res=>{
            this.loader = false
            try {
                this.banner = res.data
                console.log(this.banner)
            } catch (err){
                console.log(err)
            }
        })

        /**
         * 按分类显示产品
         */
        this.api.get('goods/getgoods',{}).then( res => {
            this.loader = false
            try {
                this.goodsList = res.data
            } catch (err) {
                console.log(err)
            }
        })

        this.config = this.utils.config()
         // 添加像素
        this.utils.addXiangsu()
    },
    watch: {

        /**
         * 监听路由变化
         */
        $route(to){}
    },
};
</script>

<style scoped>
.mdui-page-container{min-height:100px;padding-left:0;padding-right:0;margin-left:0;margin-right:0;margin-bottom:20px;}
.module-01-a .module-01-a-swiper{position:relative;}
.module-01-a .swiper-slide > a{display:block;position:relative;}
.module-01-a .swiper-slide > a .mdui-img-fluid{width:100%;height:100%;}
.module-01-a .swiper-pagination-fraction,.module-01-a .swiper-pagination-custom,.module-01-a .swiper-container-horizontal > .swiper-pagination-bullets{width:100%;right:0px;left:0px;text-align:center}
.module-01-a .swiper-container-horizontal > .swiper-pagination-bullets{bottom:4px;}
.module-01-a .swiper-pagination-bullet-active{border:2px solid #E94D8A!important;background:#E94D8A!important;}
.module-01-a .swiper-pagination-bullet{background:transparent;opacity:1;width:4px;height:4px;}
@media (max-width:767px) {.module-01-a-check {font-size:12px;height:30px;}}
@media (min-width:768px) {.module-01-a-check {font-size:14px;height:40px;}}
.module-01-a-check{background:#FFF;letter-spacing:0;color:#E94D8A;box-sizing:border-box;padding:0px 4%;display:flex;align-items:center;}
.module-01-a-check > div:nth-child(1){flex-grow:1;text-align:left;}
.module-01-a-check > div:nth-child(2){flex-grow:1;text-align:center;}
.module-01-a-check > div:nth-child(3){flex-grow:1;text-align:right;}
.module-01-a-check > div > .mdui-custom-icons{width:13px;height:13px;margin-right:5px;vertical-align:middle;}

.cate{display:block;overflow:hidden;padding:15px 0;}
.cate-div{float:left;width:16%;min-height:60px;margin:2%;}
.cate-img{width:auto;border-top-right-radius:44px;border-bottom-left-radius:40px;}
.cate-img img{width:80%;height:80%;margin-left:10%;border-radius:50%!important;}
.cate-title{text-align:center;}
.title-text{width:32%;font-size:10px;display:inline-block;color:#c75959;}
.title-text-div{height:20px;line-height:20px;background-color:#e8e8e8;}

@media (max-width:767px) {
    .floor-list{width: 90%; margin: 20px auto 0px}
    .floor-name{height:30px;line-height:30px;font-size:18px;font-weight:700;}
    .floor-more{float:right;font-size:12px;line-height:30px;color:#b2b2b2;}
    .module-31{background:#f4f4f4;color:#000;padding:8px 2.4% 0;box-sizing:border-box;display:flex;justify-content:space-between;flex-wrap:wrap;}
    .module-31 .module-31-card{width:48.8%;margin-bottom:2%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;}
    .module-31 .module-31-card:nth-last-child(1){margin-left:0;}
}

@media (min-width:768px) { /*ipad或平板 */
    .mdui-row1{margin-top:139px}
    .service-main{width:750px;height:50px;margin:30px auto 30px;}
    .service-main .service-items{width:33%; height:50px; float:left; padding:0 0 0 5%; border-right:1px solid #d2d2d2;}
    .service-main .service-items .service-items-info h3 {font-weight: 500;font-size: 22px;line-height: 1;}
    .service-main .service-items .service-items-info p {font-size: 13px;line-height: 1; margin-top: 10px;}
    .floor-list{width:750px;height:40px;margin:30px auto 0;padding:0 1% 0;}
    .floor-name{width:150px;height:40px;line-height:40px;font-size:25px;font-weight:700;}
    .floor-more{width:100px;height:40px;font-size:14px;line-height:40px;text-align:center;float:right;color: #fff;background: #E94D8A;cursor: pointer;}
    .module-31{width:750px;margin:0 auto;background:#f4f4f4;color:#000;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}
@media (min-width:992px) {
    .service-main{width:970px;height:50px;margin:30px auto 40px;}
    .service-main .service-items{width:33%; height:50px; float:left; padding:0 0 0 5%; border-right:1px solid #d2d2d2;}
    .service-main .service-items .service-items-info h3 {font-weight: 500;font-size: 22px;line-height: 1;}
    .service-main .service-items .service-items-info p {font-size: 13px;line-height: 1; margin-top: 10px;}
    .floor-list{width:970px;height:40px;margin:30px auto 0;padding:0 1% 0;}
    .floor-name{width:150px;height:40px;line-height:40px;font-size:25px;font-weight:700;}
    .floor-more{width:100px;height:40px;font-size:14px;line-height:40px;text-align:center;float:right;color: #fff;background: #E94D8A;cursor: pointer;}
    .module-31{width:970px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}
@media (min-width:1200px) {
    .mdui-row1{margin-top:105px}
    .service-main{width:970px;height:50px;margin:30px auto 50px;}
    .service-main .service-items{width:33%; height:50px; float:left; padding:0 0 0 5%; border-right:1px solid #d2d2d2;}
    .service-main .service-items .service-items-info h3 {font-weight: 500;font-size: 22px;line-height: 1;}
    .service-main .service-items .service-items-info p {font-size: 13px;line-height: 1; margin-top: 10px;}
    .floor-list{width:1170px;height:40px;margin:30px auto 0;padding:0 1% 0;}
    .floor-name{width:150px;height:40px;line-height:40px;font-size:25px;font-weight:700;}
    .floor-more{width:100px;height:40px;font-size:14px;line-height:40px;text-align:center;float:right;color: #fff;background: #E94D8A;cursor: pointer;}
    .module-31{width:1170px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}
.service-main .service-items:last-child{border-right:0;}
.service-main .service-items .service-items-icon{float: left; margin-right: 20px;}

.module-31 .module-31-card > a{text-decoration:none;}
.module-31 .module-31-card .mdui-card-media{padding-bottom:100%;}
.module-31 .module-31-card .mdui-card-media > img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.module-31 .module-31-content{padding:8px;color:#000;}
.module-31 .module-31-content .module-31-title{height:36px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;width:100%;font-size:13px;line-height:18px;}
.module-31 .module-31-content .module-31-price{font-size: 0;line-height: normal;padding:6px 0 0;display:flex; justify-content: space-between;align-items: center;}
.module-31 .module-31-content .module-31-price > span:last-child{font-size:12px;color:#777777;line-height:12px;display:inline-block;}
.module-31 .module-31-content .module-31-discountprice{font-size:22px;color:#E94D8A;line-height:22px;margin-right:8px;}
.module-31 .module-31-content .module-31-discountprice:before{content:'NT$';margin-right:1px;font-size:12px;line-height:12px;}
.module-31 .module-31-content .module-31-sales{font-size: 12px;color: #777777; line-height: 22px; float:right;}
.module-31 .module-31-content .module-31-originalprice{text-decoration:line-through}
.module-31 .module-31-content .module-31-originalprice:before{content:'NT$';}
.module-31 .module-31-content .module-31-btn{display:flex;align-items:baseline;justify-content:space-between;}
.module-31 .module-31-content .module-31-btn button{background:#E94D8A;border-radius:3px;width:88px;height:26px;border:none;box-shadow:none;font-size:12px;color:#FFFFFF;line-height:12px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.module-31 .module-31-content .module-31-btn span{font-size:13px;color:#777777;text-align:right;line-height:13px;}
.module-31 .module-31-content .module-31-tag{height:16px;overflow:hidden;font-size:0;margin:5px 0;}
.module-31 .module-31-content .module-31-tag span{display:inline-block;padding:0 7px;border:none;border-radius:8px;font-size:12px;color:#E56214;line-height:16px;height:16px;background:#FCE9D5;box-sizing:border-box;margin-right:6px;}
.module-31 .module-31-content .module-31-tag span:last-child{margin-right:0;}
.module-31 .module-31-more{background:#FFF;color:#7B7B7B;padding:0;border:none!important}
.module-31 .module-31-more a{position:relative;color:#7b7b7b;display:block;padding-bottom:100%;border:1px solid #f4f4f4;}
.module-31 .module-31-more a .textBox{position:absolute;left:50%;margin-left:-25px;top:50%;margin-top:-28px;text-align:center;}
.module-31 .module-31-more a span{display:inline-block;}
.module-31 .module-31-more a span.circle{border:1px solid #7B7B7B;border-radius:50%;padding:3px;margin-top:12px;}
input{border:0;background-color:#eaeaea;padding-left:5px;width:204px;outline:none;}
.mdui-row,[class*="mdui-row-"]{margin-left:0;margin-right:0;}

.flex { display: flex; }
.hide { display: none; }
.swiper-slide img { width:100%; }
</style>
