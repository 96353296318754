<template>
<div>
    <headerTopPc></headerTopPc>
    <div id="content-pc">
        <section class="breadcrumb-area">
            <div class="breadcrumb-text">
                <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>填寫收貨資料</span>
            </div>
        </section>
    </div>
    <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
        <div class="mdui-toolbar-title">
            <div class="top-left-options">
                <a class="text-icon-options" href="javascript:void(0)" @click="utils.goBack();">
                    <img src="../assets/images/icon-back.png">
                </a>
            </div>
            <div class="mdui-center mdui-valign point-progress point-progress-min">
                <ul>
                    <li class="active"><i>1</i></li>
                    <li></li>
                    <li><i>2</i></li>
                </ul>
                <ul>
                    <li class="active"><i>確認訂單</i></li>
                    <li><i>訂購完成</i></li>
                </ul>
            </div>
        	<div class="top-right-options">
            	<a id="customer_service" class="text-icon-options" :href="config.shop_service">
                	<img src="../assets/images/icon-customer-service.png">
                	<span class="text-service">客服</span>
            	</a>
        	</div>
        </div>
    </div>

    <div style="width:100%;float:left;background:#f4f4f4;padding-bottom:50px;">
    <div id="confirm-main">
        <div class="confirm-area-left">
        <div class="confirm-list-title">
            收貨人資料
        </div>
        <div class="addressView card" style="background-color: #fff;">
            <div style="padding: 0 4% 10px;">
                <div class="view-bottom-content address-home">
                    <ul class="mdui-list">
                        <li class="mdui-list-item">
                            <label>姓名</label>
                            <input type="text" name="username" v-model="params.name" maxlength="20" placeholder="請填寫收貨人姓名">
                        </li>
                        <li class="mdui-list-item">
                            <label>電話</label>
                            <input type="tel" name="phone" v-model="params.mobile" maxlength="40" placeholder="請填寫聯絡手機號碼">
                        </li>
                        <li class="mdui-list-item">
                            <label>LINE</label>
                            <input type="email" v-model="params.email" maxlength="40" placeholder="填寫LINE ID，用於聯絡，可不填寫">
                        </li>
                        <li class="mdui-list-item">
                            <label>收貨方式</label>
                        	<li>
                            <div class="typeCon" @click="setExpressType(1)">
                                <img class="check-icon select" src="../assets/images/icon_check.png" width="20px" v-if="params.express_type == 1">
                                <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px" v-else>
                                <span class="typeCon-text"><span>宅配</span></span>
                            </div>
                            <div class="typeCon" @click="setExpressType(2)">
                                <img class="check-icon select" src="../assets/images/icon_check.png" width="20px" v-if="params.express_type == 2">
                            <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px" v-else>
                                <span class="typeCon-text"><span>7-11超取</span></span>
                            </div>
                            <div class="typeCon " @click="setExpressType(3)">
                                <img class="check-icon select" src="../assets/images/icon_check.png" width="20px" v-if="params.express_type == 3">
                            <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px" v-else>
                                <span class="typeCon-text"><span>全家超取</span></span>
                            </div>
                        	</li>
                        </li>
                        <li class="mdui-list-item">
                            <label>地址</label>
                            <div class="mdui-list-address">
                                <div style="position: relative;">
                                    <span class="city-picker-span" style="width:125px;" v-if="!addr.city.id" @click="showAddr">
                                        <span class="placeholder">請選擇縣市/區域</span>
                                        <div class="arrow"></div>
                                    </span>
                                    <span class="city-picker-span" style="width:125px;" v-else>
                                        <span>
                                            <span class="select-item" style="color:#333" v-if="addr.city" @click="showAddr('city')">{{addr.city.name}} </span>
                                            <span class="select-item" style="color:#333" v-if="addr.area" @click="showAddr('area')">/ {{addr.area.name}}</span>
                                        </span>
                                        <div class="arrow" @click="showAddr"></div>
                                    </span>
                                </div>
                            </div>
                        </li>
                        <li class="mdui-list-item">
                            <label></label>
                            <input type="text" style="font-size:12px;width:100%;border-bottom:1px solid #ccc" v-model="addr.address" placeholder="街道、樓牌號等">
                        </li>
						<li class="mdui-list-tip">提醒：宅配不能指定送貨時間。如果需管理員代收，請在地址後面加上“代收”。或選擇<b>超商取貨</b>，送到會簡訊通知您取貨</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="confirm-list-title">
            付款方式
        </div>
        <div class="mdui-color-white mdui-pannel mdui-pay-info card" style="padding: 10px 4%;">
            <div class="selectPayType">
                <div class="view-bottom-content havfooter">
                    <ul>
                        <li @click="setPayment(1)" v-if="showDf">
                            <img class="check-icon select" src="../assets/images/icon_check.png" width="20px" v-if="params.payment == 1">
                            <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px" v-else>
                            <div class="typeCon ">
                                <span class="typeCon-text"><span>貨到付款</span></span>
                            </div>
                        </li>
                        <li @click="$layer.msg('該區域不支援貨到付款')" v-else>
                            <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px">
                            <div class="typeCon ">
                                <span class="typeCon-text"><span style="color:#999">該區域不支援貨到付款</span></span>
                            </div>
                        </li>
                        <li @click="setPayment(2)">
                            <img class="check-icon select" src="../assets/images/icon_check.png" width="20px" v-if="params.payment == 2">
                            <img class="check-icon select" src="../assets/images/icon_no_check.png" width="20px" v-else>
                            <div class="typeCon">
                                <span class="card-text"><span>信用卡付款</span></span>
                                <img src="../assets/images/visa.png" width="20px" height="16px">
                                <img src="../assets/images/mastercard.png" width="20px" height="16px">
                                <img src="../assets/images/jcb.png" width="20px" height="16px">
                                <img src="../assets/images/unionpay.png" width="20px" height="16px">
                            </div>
                        </li>
                        <li class="tips" style="color:#E29EAD;padding-bottom:0;">使用信用卡付款，減NT$100</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>

        <div class="confirm-area-right">
        <div class="confirm-list-title">
            商品清單
        </div>
        <div class="mdui-color-white mdui-pannel mdui-pay-info card">
            <div class="mdui-cart-products-container" style="padding: 0 4%;">
                <div class="mdui-cart-products">
                    <ul class="mdui-list">
                        <li class="mdui-list-item" v-for="item in cartList" v-if="item.ac == 1">
                            <a href="javascript:void(0)">
                                <img class="mdui-cart-product-sku-img" :src="item.images">
                            </a>
                            <div class="mdui-cart-product-sku-info">
                                <div class="mdui-list-item-title mdui-list-item-one-line">
                                    <div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}
                                </div>
                                <div class="mdui-list-item-text mdui-list-item-one-line">{{item.sku_text}}</div>
                                <div class="mdui-list-item-text2">
                                    <span class="mdui-float-left mdui-list-item-text-price" style="color:#222222">NT${{item.sale_price}}</span>
                                    <div class="mdui-float-right" style="color:#777777;font-size:14px">
                                        <span>x</span><span>{{item.number}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="confirm-list-title">
            明細表
        </div>
        <div class="mdui-color-white mdui-pannel mdui-pay-info card">
            <ul class="mdui-list">
                <li class="mdui-list-item">
                    <span>商品總金額</span>
                    <div class="orderPay">${{total}}</div>
                </li>

            	<div class="detail-list" style="margin-top: 10px;" v-if="showExpress">
                	<li class="mdui-list-item"><span class="label">金額未滿NT${{express.freeprice}}，需補運費</span>
                    	<div>+${{express.feight}}</div>
                	</li>
            	</div>
                <li class="mdui-list-item" v-else>
                    <span class="label">運費</span>
                    <div class="orderExpressCount">${{express_fee}}</div>
                </li>
                <li class="mdui-list-item  typeMesDetail" v-if="goodsDiscount.money > 0">
                    <span class="label">滿{{goodsDiscount.count}}件{{goodsDiscount.percent / 10}}折</span>
                    <div style="color:#E94D8A">-${{goodsDiscount.money}}</div>
                </li>
                <li class="mdui-list-item  typeMesDetail" v-if="payment_discount > 0">
                    <span class="label">線上付款</span>
                    <div style="color:#E94D8A">-${{payment_discount}}</div>
                </li>
                <li class="mdui-list-item  typeMesDetail" v-if="couponData.money > 0 ">
                    <span class="label">折價劵優惠</span>
                    <div style="color:#E94D8A">-${{couponData.money}}</div>
                </li>
                <li id="content-pc" class="mdui-list-item  typeMesDetail" style="margin-top: 10px;">
                    <span class="label" style="font-size:16px;font-weight: bold;">應付</span>
                    <div style="color:#E94D8A;font-size:20px;font-weight: bold;"><span style="font-size:10px;">NT$</span> <span>{{paymoney}}</span></div>
                </li>
            </ul>
        </div>
        </div>

        <div class="confirm-area-left">		
        <div class="confirm-list-title2">
            折價券
        </div>
        <div class="mdui-color-white mdui-pannel mdui-pay-info card inputCouponView">
            <div class="useCoupon" style="margin: 0;padding: 0;float: left;">
                <input id="useCoupon-text" type="text" placeholder="輸入折價券號" v-model="params.coupon" style="border: 0px;">
            </div>
            <div id="useCoupon-btn" style="float: right; border: 1px solid #E94D8A; color: #E94D8A; padding: 8px 12px; border-radius: 6px; margin-top: 5px; margin-right: 10px; display: flex;" @click="taoyong">套用</div>
            <div id="useCoupon-del" style="float: right;border: 1px solid #222222; color:#222222;padding: 8px 12px;border-radius:6px ;margin-top: 5px;margin-right: 10px;" v-if="params.coupon" @click="deleteCoupon">刪除</div>
            <div id="useCoupon-err" style="width: 100%;float: left;"></div>
        </div>
        <div class="confirm-list-title2">
        	備註留言
        </div>
        <div class="mdui-cart-comments noWrite write-comments card" style="display:block">
        	<textarea class="mdui-cart-comments-area" placeholder="如果衣服無法確定尺寸，可填寫您的身高、體重、胸圍等，以便我們為您提供建議" v-model="params.remarks"></textarea>
        </div>
		<div class="mdui-color-white mdui-pannel mdui-pay-info remarks">
		<ul class="mdui-list" style="color:#E29EAD; line-height:24px">訂購時如有疑問或不懂操作，可點右上角的LINE聯絡客服或電話 <a :href="'tel:'+config.service_hotline" style="text-decoration:underline;">{{config.service_hotline}}</a> 諮詢。訂購後大概10~12天送到，如要取消或更改訂單請及時聯絡客服</ul>
		</div>
        <div id="content-pc" style=" width:90%; margin:10px 0 0 30px;">
            <button id="buyNow" class="mdui-btn mdui-btn-block mdui-color-red-900 no-active" @click="submit">
                <span></span>
                <span>結帳</span>
            </button>
        </div>
        </div>
    </div>

    <div id="content-mobile">
    <div class="mdui-bottom-nav">
        <div class="mdui-row mdui-color-white">
            <div style="width: 200px;float: left;">
                <div class="info"><span>應付:</span><span style="font-size:14px;">NT$</span><span class="allPrice"> {{paymoney}}</span><span> </span></div>
                <div style="width: 200px;padding-left: 16px;color:#7B7B7B;font-size: 12px;" class="seeDetail" @click="showMingxi = !showMingxi">
                    點擊此處，查看明細 <img src="../assets/images/icon-top.png" width="11px">
                </div>
            </div>
            <button id="buyNow" class="mdui-btn mdui-btn-block mdui-color-red-900 no-active" @click="submit">
                <span></span>
                <span>結帳</span>
            </button>
        </div>
        <div class="mdui-call-m">
            <div class="mdui-call-text-m">請點“結帳”,提交完成訂單</div>
        </div>
    </div>
    <div class="selectDetail view-bottom" style="bottom: 50px !important;display:block" v-if="showMingxi == true">
        <div class="view-bottom-box view-bottom-box-sml" style="height: 250px;top:calc(100% - 250px)">
            <div class="view-bottom-head">
                <button class="success" style="opacity: 0;">確認</button>
                <span>訂單明細表</span>
                <button class="closeDetail" @click="showMingxi = false"><img src="../assets/images/icon-close2.png" class="close-pub"></button>
            </div>
            <div class="view-bottom-content">
                <div class="mdui-color-white mdui-pannel mdui-pay-info card" style="padding: 0;margin: 0;">
                    <ul class="mdui-list" style="padding: 0;">
                        <li class="mdui-list-item">
                            <span class="orderProCount">共{{number}}件商品</span>
                        </li>
                        <li class="mdui-list-item">
                            <span>合計總金額</span>
                            <div class="orderPay">${{total}}</div>
                        </li>
                        <li class="mdui-list-item orderDiscountDepletion" style="display: none">
                            <span class="label">活動優惠</span>
                            <div></div>
                        </li>

                        <div class="detail-list" style="margin-top: 10px;" v-if="showExpress == true">
                            <li class="mdui-list-item"><span class="label">金額未滿NT${{express.freeprice}}，需補運費</span>
                                <div>+ ${{express.feight}}</div>
                            </li>
                        </div>
                        <li class="mdui-list-item" style="margin-top: 5px;" v-else>
                            <span class="label">運費</span>
                            <div class="orderExpressCount">${{express_fee}}</div>
                        </li>
                        <li class="mdui-list-item  typeMesDetail" v-if="goodsDiscount.money > 0">
                            <span class="label">滿{{goodsDiscount.count}}件{{goodsDiscount.percent / 10}}折</span>
                            <div style="color:#E94D8A">-${{goodsDiscount.money}}</div>
                        </li>
                        <li class="mdui-list-item  typeMesDetail" v-if="payment_discount > 0">
                            <span class="label">線上付款</span>
                            <div style="color:#E94D8A">-${{payment_discount}}</div>
                        </li>
                        <li class="mdui-list-item  typeMesDetail" v-if="couponData.money > 0">
                            <span class="label">折價劵優惠</span>
                            <div style="color:#E94D8A">-${{couponData.money}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>

    <div id="content-pc">
    <footmain></footmain>
    </div>
    <selectAddress v-if="selectAddr" :express="params.express_type"  @cancel="selectAddr = false" @submit="setAddr"></selectAddress>
    <loader :loader="loader"></loader>
</div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import selectAddress from '../views/common/address.vue';
    import footmain from '../views/common/footmain.vue';
    import loader from '../views/common/loader.vue';
    import '../assets/css/address.css';

    export default {
        components:{headerTopPc,selectAddress,footmain,loader},
        name: "",
        data() {
            return {
                selectAddr:false,
                showMingxi:false,
                showExpress:false,
                isRemarks:false,
                params:{
                    express_type:1,
                    payment:1,
                    coupon:'',
                },
                addr:{
                    city:{ df:0 },
                    area:{ df:0 }
                },
                goodsInfo:{},
                couponData:{},
                payment_discount:0,
                goodsDiscount:{},
                express_fee:0,
                express:{},
                goodsNumber:0,
                total:0,
                paymoney:0,
                number:0,
                cartList:[],
                zhekou:[],
                showDf:true,
                loader:true,
                config:{}
            }
        },
        methods: {
            getPrice(){

                let money = 0;
                let total = 0;
                let number = 0;
                let teMoney = 0;

                let cart = this.cartList;
                for(let i in cart){
                    if( cart[i].ac == 1 ){
                        if( this.cartList[i].is_te == 1 ){
                            total += this.cartList[i].sale_price * this.cartList[i].number;
                            teMoney += this.cartList[i].sale_price * this.cartList[i].number;
                        } else {
                            number += this.cartList[i].number;
                            total += this.cartList[i].sale_price * this.cartList[i].number;
                            money += this.cartList[i].sale_price * this.cartList[i].number
                        }
                    }
                }

                for(let i in this.zhekou){
                    if( number >= this.zhekou[i].count ){
                        this.goodsDiscount = this.zhekou[i];
                        this.goodsDiscount.money = parseInt( parseInt(money) * ( (100 - this.zhekou[i].percent) / 100 ) );
                        money = parseInt( parseInt(money) * ( (this.zhekou[i].percent) / 100 ) );
                        break;
                    }
                }

                money += this.express_fee;
                if( this.params.payment == 2 || this.params.payment == 3){
                    this.payment_discount = 100;
                    money -= 100;
                } else {
                    this.payment_discount = 0;
                }

                if( this.couponData.money && this.couponData.money > 0 ){
                    money = money - this.couponData.money;
                }

                this.paymoney = parseInt(money) + teMoney;
                this.total = total;
                this.number = number;

            },

            // 设置发货方式
            setExpressType(type){
                this.addr.address = '';
                this.addr.city = { df:0 };
                this.addr.area = { df:0 };
                this.params.express_type = type;
                this.getPrice()
            },

            // 设置付款方式
            setPayment(type){

                this.params.payment = type;
                this.getPrice()
            },

            // 删除优惠券
            deleteCoupon(){
                this.params.coupon = ''
                this.couponData = {}
                this.getPrice()
            },

            // 显示地址选择器
            showAddr(type){
                this.selectAddr = true
            },

            // 设置地址
            setAddr(data){
                this.selectAddr = false;
                if( data.market ){
                    data.address =  data.market.address + '（' + data.market.title + '，' + data.market.no + '）'
                }

                if( data.city.df == 1 && data.area.df == 1){
                    this.showDf = true
                    this.params.payment = 1;
                } else {
                    this.showDf = false
                    this.params.payment = 0;
                }
                this.addr = data

                this.api.get('orders/getFreight',{city_id:data.city.id}).then(res => {
                    this.express_fee = parseInt(res)
                    this.getPrice();
                })
            },

            taoyong(){
                if( !this.params.coupon ){
                    this.$layer.msg('請填寫折價券號')
                    return false;
                }

                this.api.get('coupon/get',{code:this.params.coupon}).then(res=>{
                    if(res.code != 200){
                        this.$layer.msg(res.message);
                        this.params.coupon = '';
                        return false;
                    }

                    let money = 0;

                    for(let i in this.cartList){
                        money += this.cartList[i].number * this.cartList[i].sale_price;
                    }

                    if( money < res.data.total_money ){
                        this.couponData = {};
                        this.params.coupon = '';
                        this.$layer.msg('該折價券最低消費NT$'+res.data.total_money+'才可用');
                        return false;
                    }

                    this.couponData = res.data;
                    this.getPrice();
                })
            },

             // 提交订单
            submit(){
                if( !this.params.name ){
                    this.$layer.msg('請填寫收貨人姓名');
                    this.utils.toTop();
                    return false;
                }

                if( !this.params.mobile ){
                    this.$layer.msg('請填寫可聯絡到的手機號碼');
                    this.utils.toTop();
                    return false;
                }

                // if( !/^(\+886\s)?[0]{1}[9]{1}\d{8}$/.test(this.params.mobile)){
                //     this.$layer.msg('請填寫正確的手機號碼');
                //     return false;
                // }

                if( !this.addr.city || !this.addr.city.id || !this.addr.area.id ){
                    this.$layer.msg('請選擇縣市');
                    this.utils.toTop();
                    return false;
                }

                // if( !this.addr.address || this.addr.address == undefined ){
                //     this.$layer.msg('請選擇郷鎮市區');
                //     return false;
                // }

                if( this.params.express_type == 1 && !this.addr.address ){
                    this.$layer.msg('請填寫完整寄送地址，如道路、街名、門牌號');
                    this.utils.toTop();
                    return false;
                }

                if( (this.params.express_type == 2 || this.params.express_type == 3) && !this.addr.market.code ){
                    this.$layer.msg('請選擇超商門市');
                    this.utils.toTop();
                    return false;
                }

                if( this.params.payment == 0 ){
                    this.$layer.msg('請選擇付款方式');
                    return false;
                }

                if( this.express_type == 1 && (this.addr.city.df != 1 || this.addr.area.df != 1)){
                    this.$layer.msg('該區域不支援貨到付款');
                    return false;
                }

                let params = this.params;
                params.address = this.addr.address;
                params.city_id = this.addr.city.id;
                params.area_id = this.addr.area.id;
                //params.payment = this.paymentType;//1货到付款2信用卡
                //params.coupon = this.coupon;

                if( this.params.express_type > 1){
                    params.market_id = this.addr.market.code;
                }

                params.goods_info = [];
                for(let i in this.cartList){
                    if( this.cartList[i].ac == 1 ){
                        params.goods_info.push({
                            goods_id:this.cartList[i].goods_id,
                            sku_id:this.cartList[i].id,
                            number:this.cartList[i].number
                        });
                    }
                }

                if( params.goods_info.length == 0 ){
                    this.$layer.msg('您還沒有加入商品');
                    return false;
                }

                params.domain = window.location.protocol+"//"+window.location.host;

                this.api.post('orders/create',params).then( res => {

                    if( res.code != 200){
                        this.$layer.msg(res.message )
                        return false;
                    }
                    let addre = this.addr;
                    addre.city.list = [];
                    this.utils.cookie('userInfo',{
                        address:addre,
                        name:this.params.name,
                        mobile:this.params.mobile,
                        email:this.params.email,
                        express_type:this.params.express_type
                    });

                    if( this.params.payment == 1 || this.params.payment == 3){
                        this.utils.removeCart();
                        this.utils.url('success',{no:res.data.out_trade_no});
                        return false;
                    } else if( this.params.payment == 2 ){

                        this.$Modal.confirm({
                            title: 'Tips',
                            content: '信用卡付款採用交易授權機制及簡訊驗證保證安全。進入信用卡付款頁面後，只需填寫：帶紅色*號的四項（信用卡號、有效期限、末3碼、手機號碼）即可',
                            okText: '去信用卡付款',
                            cancelText:'取消',
                            onOk: () => {
                                this.$Message.loading({
                                    content: '正在載入付款頁面...',
                                    duration: 1000000000
                                });
                                location.href = res.data.payurl + "/lvpay?no="+res.data.out_trade_no;
                            },
                            onCancel:()=>{
                                this.$Modal.remove();
                            }
                        });

                    }
                })
            },
        },
        watch: {},
        mounted(){
            let cart = this.utils.cart();
            console.log(cart)
            let sku = [];
            for(let i = 0; i < cart.length; i++){
                sku.push(cart[i].sku_id)
            }

            this.api.get('goods/cartList',{sku:sku.toString()}).then(res=>{
                let cartList = [];
                for(let i = 0; i < cart.length; i++){
                    for(let index = 0; index < res.data.length ;index ++){
                        if( cart[i].sku_id == res.data[index].id ){
                            res.data[index].number = cart[i].number;
                            res.data[index].ac = cart[i].ac;
                            cartList.push(res.data[index]);
                        }
                    }
                }
                this.cartList = cartList;
                this.loader = false
                this.getPrice()
            })
            this.api.get('config/cuxiao',{}).then(res => {
                this.zhekou = res.data.discount;
                let userInfo = this.utils.cookie('userInfo');
                if( userInfo ){
                    this.setAddr(userInfo.address);
                    this.params.express_type = userInfo.express_type;
                    this.params.name = userInfo.name;
                    this.params.email = userInfo.email;
                    this.params.mobile = userInfo.mobile;
                }
                this.loader = false
                this.getPrice()
            })

             // 添加像素
            this.utils.addXiangsu( () => {
                // 开始结账
                fbq('track', 'InitiateCheckout');
            }); 
            this.config = this.utils.config()
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    }
</script>

<style scoped>
body,html {background: #f4f4f4!important;}
@media (max-width:767px){
div.remarks{margin:10px 2.1% 100px;border-radius:6px;overflow:hidden}
}

@media (min-width:768px){
#confirm-main{width:750px;margin:0 auto;}
.confirm-area-left{width:50%;float:left}
.confirm-area-right{width:50%;float:right}
div.remarks{margin:10px 2.1% 10px;border-radius:6px;overflow:hidden}
}

@media (min-width:992px) {
#confirm-main{width:970px;margin:0 auto;}
.confirm-area-left{width:50%;float:left}
.confirm-area-right{width:50%;float:right}
div.remarks{margin:10px 2.1% 10px;border-radius:6px;overflow:hidden}
}
@media (min-width:1200px) {
#confirm-main{width: 1170px;margin:0 auto;}
.confirm-area-left{width:50%;float:left}
.confirm-area-right{width:50%;float:right}
div.remarks{margin:10px 2.1% 10px;border-radius:6px;overflow:hidden}
}

.confirm-list-title{padding: 16px 10px 0 10px;font-weight: 800;color: #222222; font-size: 16px;}
.confirm-list-title2{padding: 8px 10px 0 10px;font-weight: 800;color: #222222; font-size: 16px;}

.confirm-total-money{color:#E94D8A;font-size:16px;font-weight: bold; margin-top: 10px;}

.mdui-list{padding:0;}
.city-picker-dropdown{min-width:auto;max-width:79vw;position:relative}
.city-picker-span{height:42px;line-height:42px;}
.city-picker-span > .placeholder{color:#D1D1D1}
.mdui-address{position:relative;box-sizing:border-box;min-height:75px;background:#FFFCEC url(../assets/images/border.jpg) bottom repeat-x;font-size:14px;color:#363636;padding:12px 4%;cursor:pointer}
.mdui-address span{display:inline-block;margin-right:12px;line-height:24px;font-weight:700;font-size:16px}
.mdui-address span:last-child{display:block;font-weight:500;font-size:12px}
.mdui-address .mdui-icon{position:absolute;top:23px;right:4%;font-size:24px;margin-right:-10px;color:#979797!important}
.mdui-cart-products{background:#FFF;}
.mdui-cart-products .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;vertical-align:middle}
.mdui-cart-products .mdui-cart-product-sku-info{padding-top:2px;flex-grow:1;height:78px;position:relative;top:0;float:right}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2{position:absolute;width:100%;bottom:2px}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price{font-size:12px;color:#E94D8A}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price{font-size:12px;color:#777;text-decoration:line-through}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon{width:14px;height:14px;position:relative;top:2px;margin:0 10px}
.mdui-cart-products .mdui-list{padding:0}
.mdui-cart-products .mdui-list>.mdui-divider-inset{margin:0!important;background-color:rgba(0,0,0,.1)}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(180,39,45,.1);color:#E94D8A;margin-right:3px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item-title .mdui-list-item-promotion-tags-depletion{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(242,143,45,.1);color:#F56207;margin-right:3px;}
.mdui-cart-products .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item-remark{display:block;width:100%;color:#BCBCBC;line-height:10px;margin-top:8px}
.mdui-cart-products .mdui-list-item-remark span{margin-left:-16px;font-size:12px;display:inline-block;transform:scale(0.8);}

/* .mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags{display: flex;margin-top: 2px;width: 156px; border-radius: 12px;overflow: hidden;font-size: 0;border: 1px solid #979797}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span{font-size: 12px;display: block;text-align: center;line-height: 16px}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span:first-child{background: #979797;color: #FFF;width: 36px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span:last-child{color: #979797;background: #fff;flex: 1}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags-1{border: 1px solid #E29EAD;width: 52px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags-1 span:first-child{width: 52px;background:#fff;color: #E29EAD} */
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text{bottom:4px}
.mdui-cart-products .mdui-cart-product-sku-info .promotion-num{font-size:12px;color:#7B7B7B}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price{top:0}
.mdui-cart-products .mdui-cart-products-bottom{padding:0 4%;padding-left:calc(4% + 35px);height:40px;line-height:40px;border-top:1px solid #EDEDED;cursor:pointer}
.mdui-cart-products .mdui-cart-products-bottom .promotion-price{color:#E94D8A}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right{color:#E94D8A}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon{color:#E94D8A;margin-top:-2px;margin-right:-10px}
body.mdui-bottom-nav-fixed .mdui-bottom-nav{height:100px;display:block;}
.mdui-bottom-nav{padding:0;position:relative;box-sizing:border-box;z-index:1;border-top:1px solid #EDEDED; background-color:#000;}
.mdui-bottom-nav .info{color:#E94D8A;font-size:26px;padding-left:16px;width:200px;}
.mdui-bottom-nav .info span:first-child{font-size:14px;font-weight:400;color:#E94D8A}
.mdui-bottom-nav .info span:last-child{font-size:12px;font-weight:400;color:#E94D8A;}
.mdui-bottom-nav #buyNow{height:50px;width:120px;float:right;border-radius:0}
.mdui-bottom-nav #buyNow span{display:block;font-size:16px;height:16px;line-height:16px;}
.mdui-bottom-nav #buyNow span:first-child{font-size:12px;height:auto;line-height:12px;padding-bottom:3px;}
.open-comments{color:#E94D8A;font-size:14px;margin:18px 0;display:block;padding:0 4%}
.done-comments button{border:none;background:transparent;color:#E94D8A;padding:0;font-size:14px}
.mdui-cart-comments{margin-top:10px;margin-bottom:10px;padding:15px 10px 12px 10px;background:#FFF;font-size:#000;display:none}
.mdui-cart-comments > span{display:flex;justify-content:space-between;align-items:center;padding-bottom:8px;font-size:#000;font-size:14px}
.mdui-cart-comments > span img{width:18px;}
.mdui-cart-comments.noWrite > span img{opacity:0.3}
.mdui-cart-comments .mdui-cart-comments-area{width:100%;height:80px;resize:none;border:none;border-radius:2px;box-sizing:border-box;outline:0;font-size:12px; line-height:22px;}
.mdui-cart-comments .comments-bottom{text-align:right;}
.mdui-cart-comments .comments-bottom button{border:none;background:transparent;padding:7px 11px}
.mdui-cart-comments .comments-bottom button:focus{outline:none}
.mdui-cart-comments .comments-bottom .cancel{color:#777777}
.mdui-cart-comments .comments-bottom .submit{color:#fff;background:#E94D8A;border-radius:4px}
.mdui-cart-comments.noWrite .comments-bottom .submit{opacity:0.3}
.click-add-address{text-align:center;margin-top:10px}
.click-add-address>.mdui-btn{color:#E94D8A;font-size:16px;height:28px;line-height:28px;background:0 0;font-weight:700}

/*適配iphone5*/
@media (max-width:320px){
	.mdui-cart-products .mdui-cart-product-sku-img{width:90px;height:90px}
	.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 135px);height:90px}
}
.mdui-pay-info{margin-top:10px}
.mdui-pay-info .mdui-list{padding:10px 4%}
.mdui-pay-info .mdui-list .mdui-list-item{box-sizing:border-box;align-items:flex-start;padding:9px 0;font-size:14px;min-height:24px;line-height:12px;color:#000;display:flex;justify-content:space-between}
.mdui-pay-info .mdui-list .mdui-list-item:hover{background:#fff}
.mdui-pay-info .mdui-list .mdui-list-item:after{height:auto;}
.mdui-pay-info .mdui-list .mdui-list-item:last-child{border-bottom:0}
.mdui-pay-info .mdui-list .mdui-list-item span{flex-grow:1}
.mdui-pay-info .mdui-list .mdui-list-item > div{font-size:14px}

.openSelect{background:#fff;color:#000;padding:6px 4% 5px;}
.openSelect > div{display:flex;align-items:center;justify-content:space-between;padding:10px 0;border-top:1px dashed #EDEDED;width:100%;color:#000;}
.openSelect:first-child > div{border-top:0}
.openSelect .typeMes{display:flex;align-items:center;flex:1;justify-content:flex-end;}
.openSelect .typeMes div{color:#777;}
.selectMarketView .view-bottom-content{height:100%;padding:0}
.selectMarketView .view-bottom-content .step1{margin:0 4%;}
.selectMarketView .view-bottom-content .step1 ul{padding:0;margin-top:20px;display:flex;justify-content:center;align-items:center;list-style:none}
.selectMarketView .view-bottom-content .step1 ul li{border:1px solid #BCBCBC;width:50%;border-radius:8px;padding:8px;display:flex;justify-content:space-between;align-items:center;}
.selectMarketView .view-bottom-content .step1 ul li:nth-child(2n){margin-left:5px}
.selectMarketView .view-bottom-content .step1 ul li:nth-child(2n+1){margin-right:5px}
.selectMarketView .view-bottom-content .step1 ul li i{margin-right:-8px;}
.selectMarketView .view-bottom-content .step2{display:none;height:100%;}
.selectMarketView .view-bottom-content .step2 .breadNav{border-bottom:1px solid #EDEDED;height:44px;padding:0 4%;display:flex;align-items:center}
.selectMarketView .view-bottom-content .step2 .breadNav .noVal{color:#BCBCBC!important}
.selectMarketView .view-bottom-content .step2 .breadNav .level_type{display:flex;align-items:center}
.selectMarketView .view-bottom-content .step2 .breadNav img{width:24px;margin-right:6px}
.selectMarketView .view-bottom-content .step2 .selectAdd{height:calc(100% - 45px);overflow-y:auto;position:relative;width:100vw;overflow-x:hidden}
.selectMarketView .view-bottom-content .step2 .selectAdd ul{background:#fff;margin:0 4% 60px;list-style:none;padding:0;position:absolute;width:92%;box-sizing:border-box}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.level_town,.selectMarketView .view-bottom-content .step2 .selectAdd ul.level_market{left:100vw;}
.selectMarketView .view-bottom-content .step2 .selectAdd ul li{line-height:40px;color:#000;}
.selectMarketView .view-bottom-content .step2 .selectAdd ul li.active{color:#E94D8A;}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.level_market li{line-height:14px;padding-top:20px;}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.level_market li i{display:block;font-style:normal;font-size:12px;color:#777777;line-height:12px;padding-top:8px;}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.show{display:block!important}
.selectMarketView .view-bottom-content .step2 .selectAdd ul:not(.show){display:none!important}
@keyframes showNext{
	from{left:100vw;}
	to{left:0;}
}
@keyframes showPrev{
	from{left:0;}
	to{left:100vw;}
}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.showNext{left:0;animation:0s showNext ease-in-out;display:block}
.selectMarketView .view-bottom-content .step2 .selectAdd ul.showPrev{left:100vw;animation:0s showPrev ease-in-out;display:block}
.selectSuperMarket{display:block}
.selectSuperMarket > div:first-child{display:flex;justify-content:space-between;align-items:center;min-height:48px;}
.selectSuperMarket .typeMes{display:flex;justify-content:space-between;align-items:center;}
.selectSuperMarket .typeMes > div{color:#BCBCBC;justify-content:flex-end;align-items:center;display:flex;}
.selectSuperMarket .marketDetail{padding-right:10px;padding-right:15px;text-align:right;font-size:12px;}
.inputCouponView .useCoupon{margin-top:25px;padding:0 4%;}
.inputCouponView .useCoupon > div{display:flex;align-items:center}
.inputCouponView .useCoupon > span{font-size:12px;padding:8px 0;display:block;}
.inputCouponView .useCoupon input{font-size:14px;flex:1;border:1px solid #BCBCBC;border-radius:4px;height:44px;padding:0 12px;box-sizing:border-box}
.inputCouponView .useCoupon *:focus{outline:none;}
.inputCouponView .useCoupon button{font-size:14px;margin-left:12px;border:0;opacity:1;color:#FFFFFF;height:38px;padding:12px 18px;text-align:center;line-height:12px;background:#9F8A60;border-radius:4px;}

/* .inputCouponView .useCoupon button.noUse{opacity: 0.5} */
.addressView .view-bottom-head{position:relative}
.addressView .view-bottom-head button{position:relative;z-index:1}
.addressView .view-bottom-head span{position:absolute;text-align:center;left:0;right:0;z-index:0}
.addressView .view-bottom-head .success{border:1px solid #E94D8A;background:#fff;color:#E94D8A;font-size:12px;height:26px}
.addressView .saveAddress{margin-top:10px;height:40px;border:none;width:100%;background:#E94D8A;border-radius:4px;font-size:16px;color:#FFFFFF;text-align:center;line-height:16px;}
.addressView .mdui-list .mdui-list-item{padding:0;color:#363636;}
.addressView .mdui-list .mdui-list-item label{font-size:14px}
.addressView .mdui-list .mdui-list-tip{padding:3px 10px 0;font-size:11px;color:#E29EAD;margin:0 -4%;}
.addressView .mdui-list .mdui-list-item:hover{background-color:initial}
.addressView .address-home .mdui-list .mdui-list-item label{width:60px;min-width:60px}
.addressView .address-market .mdui-list .mdui-list-item label{width:100px;min-width:100px}
.addressView .mdui-list .mdui-list-item input{text-align:right;flex:1;outline:0;background:#FFF;height:40px;border:none;font-size:14px;font-family:PingFangSC-Light,helvetica,'Heiti SC',"Hiragino Sans GB","Microsoft Yahei",sans-serif}
.addressView .address-home .mdui-list .mdui-list-item input{text-align:left}
.mdui-list-address{font-size:14px}
input::-webkit-input-placeholder{color:#D1D1D1}
input::-moz-placeholder{color:#D1D1D1}
input:-moz-placeholder{color:#D1D1D1}
input:-ms-input-placeholder{color:#D1D1D1}

textarea::-webkit-input-placeholder{color:#D1D1D1}
textarea::-moz-placeholder{color:#D1D1D1}
textarea:-moz-placeholder{color:#D1D1D1}
textarea:-ms-input-placeholder{color:#D1D1D1}

.addressView .mdui-list-address .mdui-select{font-size:12px;color:#363636;width:100px;}
#card-errors{margin-top:10px;color:#E94D8A;}

/* 选择付款方式-当前页 */

/* .openPayType ul{padding: 0;list-style: none;margin: 0}
.openPayType ul li{cursor: pointer;padding: 10px 0;display: flex;align-items: center;font-size: 14px;color: #000;}
.openPayType ul li.tip-stripe{padding: 0 0 10px 30px;font-size: 12px;color: #777;margin-top: -5px}
.openPayType ul li .typeCon{display: flex;justify-content: flex-start;font-size: 14px;width: 100%;}
.openPayType ul li .typeCon p{margin: 0 10px 0 0;display: inline;line-height: 20px;text-wrap:none;}
.openPayType ul li .check-icon{height: 20px;margin-right: 10px}
.openPayType ul li img:not(.check-icon){height: 16px;margin-right: 6px} */

/* 选择付款方式-弹框 */
.selectPayType .view-bottom-footer{padding:0 4%}
.selectPayType .view-bottom-footer .successToNext{height:38px;margin-top:-3px}
.selectPayType .successToNext:focus{outline:none}
.selectPayType  .view-bottom-content ul{padding:0;list-style:none;margin:0}
.selectPayType   .view-bottom-content ul li{cursor:pointer;padding:10px 0;display:flex;align-items:center;font-size:14px;color:#000;}
.selectPayType   .view-bottom-content ul li.tips{font-size:12px;color:#777;line-height:12px;padding:0;margin-top:-7px;padding-left:30px;padding-bottom:14px}
.selectPayType   .view-bottom-content ul li .typeCon{display:flex;justify-content:center;font-size:14px;}
.selectPayType   .view-bottom-content ul li .typeCon span{margin:0 10px 0 0;display:inline;line-height:20px;word-break:keep-all;}
.selectPayType   .view-bottom-content ul li .check-icon{height:20px;margin-right:10px}
.selectPayType   .view-bottom-content ul li img:not(.check-icon){height:16px;margin-right:6px}
.mdui-dialog.mdui-noemail-dialog{z-index:10000;}
.mdui-dialog.mdui-noemail-dialog .mdui-dialog-actions{padding:0}
.mdui-dialog.mdui-noemail-dialog .mdui-dialog-actions .mdui-btn{background:#E94D8A;color:#FFF;text-align:center}
.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title{font-size:14px;color:#363636;padding:20px 15px 10px 15px;font-weight:bold}
.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title + .mdui-dialog-content{font-size:14px;color:#181818;padding:0 15px 10px 15px}
.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title + .mdui-dialog-content > p > span{display:block;margin-left:50px;font-size:13px}
.mdui-dialog{width:85%}
@media (min-width:600px){
	.mdui-dialog{width:60%}
	.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title + .mdui-dialog-content > p > span{display:inline-block;margin-left:20px}
}
.scrape-together-reminder{text-align:center;color:#E94D8A;position:fixed;right:0;bottom:50px;left:0;margin:0px auto;background:#F7E9EA;height:30px;line-height:30px;padding:0px 4%;box-sizing:border-box;z-index:1;cursor:pointer;font-size:12px;box-shadow:inset 0 0 0 0 #DFDEDB;}
.scrape-together-reminder i{font-weight:700;font-style:normal}
body.mdui-bottom-nav-fixed{padding-bottom:96px}
input{-webkit-user-select:text;}
div.card{margin:10px 2.1% 0;border-radius:6px;overflow:hidden}
.StripeElement{box-sizing:border-box;height:40px;padding:10px 12px;border:1px solid transparent;border-radius:4px;background-color:white;box-shadow:0 1px 3px 0 #e6ebf1;-webkit-transition:box-shadow 150ms ease;transition:box-shadow 150ms ease;}
.StripeElement--focus{box-shadow:0 1px 3px 0 #cfd7df;}
.StripeElement--invalid{border-color:#fa755a;}
.StripeElement--webkit-autofill{background-color:#fefde5!important;}

/* 購物車 */
.mdui-cart-products-container{margin-top:-1px;}
.mdui-cart-products{background:#FFFFFF;border-radius:6px;padding:5px 0px 10px;}
.mdui-cart-products .mdui-list-item{padding:0;font-size:0;flex-wrap:wrap;border:0;}
.mdui-cart-products .mdui-list-item:last-child{margin-bottom:0;}
.mdui-cart-products .mdui-list-item:after{height:0}
.mdui-cart-products .mdui-list-item.gift-item{padding-bottom:6px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark{display:block;width:100%;color:#BCBCBC;line-height:10px;margin-top:8px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark span{font-size:12px;display:block;transform:scale(0.8);}
.mdui-cart-products .mdui-list-item .swiper-wrapper .swiper-slide{display:flex;align-items:center;}
.mdui-cart-products .mdui-list-item:hover{background-color:initial;}
.mdui-cart-products .mdui-list-item .check-icon,.mdui-cart-products .mdui-list-item .exchange-check{width:20px;height:20px;margin-right:15px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title{font-size:12px;color:#000;height:18px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(242,143,45,.1);color:#F56207;margin-right:3px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-text{font-size:12px;color:#7B7B7B;height:12px;margin-top:8px}
.mdui-cart-products .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;vertical-align:middle;}
.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 130px);height:80px;position:relative;top:0px;float:right;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2{position:absolute;width:100%;bottom:0px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price{font-size:12px;color:#E94D8A;position:absolute;bottom:0;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price{font-size:12px;color:#7B7B7B;text-decoration:line-through;padding-left:2px}
.mdui-cart-products .delete-icon{width:22px;height:22px;position:relative;top:2px;margin-right:18px;}
.mdui-cart-products .mdui-list{padding:0px!important;}
.mdui-cart-products .mdui-list > .mdui-divider-inset{height:8px;margin:0px!important;background:#F4F4F4;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(180,39,45,.1);color:#E94D8A;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text{bottom:4px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .promotion-num{font-size:12px;color:#7B7B7B;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price{top:0px;}
.mdui-cart-products .mdui-cart-products-bottom{margin:0 0 0 4%;padding-right:4%;margin-left:calc(4% + 35px);height:40px;line-height:40px;border-top:1px solid #EDEDED;cursor:pointer;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right{color:#E94D8A;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon{color:#E94D8A;margin-top:-2px;margin-right:-10px;}
.mdui-cart-products .swiper-container{width:100%;}
.mdui-cart-products .swiper-slide.menu{font-size:14px;min-width:80px;width:20%;max-width:100px;background:#dd524d;color:#FFF;height:80px;display:flex;justify-content:center;align-items:center;margin-left:4px;}

/* .mini-cart{z-index: 5099;}
.mini-cart .view-bottom-head{border-bottom: 1px solid #EDEDED;}
.mini-cart .view-bottom-head span{color: #E29EAD; font-size: 14px}
.mini-cart .view-bottom-head button{display: inline-flex; align-items: center;}

.mini-cart .view-bottom-footer{display: flex; align-items: center; padding: 0;box-shadow:0px -1px 0px 0px rgba(237,237,237,1);}
.mini-cart .info{flex: 1;padding: 0 16px; height: 50px;display: flex;flex-direction: column;justify-content: center;}
.mini-cart .info div:first-child{font-size: 12px; line-height: 12px; color: #E94D8A;}
.mini-cart .info div:last-child{font-size: 10px; line-height: 10px; color: #7B7B7B;padding-top: 4px;}
.mini-cart .info div span{font-size: 22px; line-height: 22px;}

.mini-cart #buyNow{height: 50px; width: 36%; border-radius: 0px;line-height: 50px; border: 0;}
.mini-cart #buyNow.no-active{background-color: #EF9A9A !important;}
.mini-cart #buyNow:active{opacity: 1;} */
.empty-carts{text-align:center;height:260px;background:#FFF;box-sizing:border-box;padding-top:48px;}
.empty-carts .cart-img-empty{display:block;margin:0px auto;width:24%;max-width:100px;}
.empty-carts .cart-tip-empty{display:block;color:#7B7B7B;padding:16px 0px;}
.empty-carts .cart-empty-to-buy{border:1px solid #E94D8A;border-radius:2px;color:#E94D8A;line-height:14px;width:110px;height:30px;line-height:30px;background:#FFF;}
.empty-carts .mdui-page-bottom{background:#FFF;position:absolute;bottom:0px;width:100%;}
.delete-confirm{width:84%;}
.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;}
.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info{width:calc(100% - 90px);height:90px;position:relative;top:0px;float:right;}
.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-title{font-size:14px;color:#363636;margin-top:12px;}
.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-text{font-size:12px;color:#7B7B7B;margin-top:12px;}
.delete-confirm .mdui-dialog-title{text-align:center;}
.delete-confirm .mdui-dialog-actions{padding:0px;display:flex;}
.delete-confirm .mdui-dialog-actions .mdui-btn{margin:0px;padding:0px;width:50%;background:#FAFAFA;height:48px;line-height:48px;border-top:1px solid #EDEDED;color:#7B7B7B;font-size:16px;}
.delete-confirm .mdui-dialog-actions .mdui-btn:first-child{border-right:1px solid #EDEDED;color:#E94D8A;}
.mdui-numbox{display:inline-block;position:relative;width:100px;height:28px;border:1px solid #EDEDED;border-radius:2px;color:#363636;}
.mdui-numbox .mdui-numbox-btn i{top:50%;left:50%;margin-left:-8px;margin-top:-8px;position:absolute;}
.mdui-numbox .mdui-numbox-btn{width:28px;height:28px;border:none;background:#FFF;outline:none;position:absolute;top:0;cursor:pointer;}
.mdui-numbox .mdui-numbox-btn .mdui-icon{font-size:16px;color:#363636;}
.mdui-numbox .mdui-numbox-input{font-size:14px;width:100%;height:100%;margin:0;overflow:hidden;text-align:center;text-overflow:ellipsis;word-break:normal;border:none;outline:none;padding:0;text-align:center;}
.mdui-numbox .mdui-numbox-btn-minus{left:0;border-right:1px solid #EDEDED}
.mdui-numbox .mdui-numbox-btn-plus{right:0;border-left:1px solid #EDEDED}
@media (min-width:600px){
	.mdui-dialog{width:60%}
	.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title + .mdui-dialog-content > p > span{display:inline-block;margin-left:20px}
}

/*適配iphone5*/
@media (max-width:320px){
	.mdui-cart-products .mdui-cart-product-sku-img{width:64px;height:64px;}
	.mdui-numbox{width:85px;}
	.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 109px);height:64px;}
	.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon{margin:0px 4px;}
	.mdui-cart-products .swiper-slide.menu{height:64px;}
	.mdui-card-aggregation > .mdui-card-product-content .mdui-card-product{width:110px;}
	.mdui-card-aggregation > .mdui-card-content{height:190px;}
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:100px;}
}
@media (min-width:750px){
	.delete-confirm{width:60%;}
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:180px;}
	.mdui-card-aggregation > .mdui-card-content{height:auto;}
}
.mdui-cart-promotion-container{background:#FFF5FB;padding:8px 2% 8px 4%;display:none;}
.mdui-cart-promotion-container a{color:#E29EAD;font-size:14px;text-decoration:none;display:flex;justify-content:space-between;align-items:center;}
.mdui-overlay{background:transparent;}
.mdui-overlay::after{content:'';width:160px;height:70px;top:30%;left:50%;margin-left:-80px;margin-top:80px;border-radius:10px;position:fixed;background:rgba(0,0,0,.7)}

.city-picker-input{opacity:0!important;top:-9999px;left:-9999px;position:absolute;}
.city-picker-span{position:relative;display:block;outline:0;-webkit-tap-highlight-color:rgba(0,0,0,0);border-bottom:1px solid #ccc;background-color:#fff;color:#ccc;cursor:pointer;}
.city-picker-span > .placeholder{color:#aaa;}
.city-picker-span > .arrow{position:absolute;top:50%;right:0;width:10px;margin-top:-3px;height:5px;background:url(../assets/images/drop-arrow.png) -10px -25px no-repeat;}
.city-picker-span.focus,.city-picker-span.open{border-bottom-color:#46A4FF;}
.city-picker-span.open > .arrow{background-position:-10px -10px;}
.city-picker-span > .title > span{color:#333;padding:5px;border-radius:3px;}
.city-picker-span > .title > span:hover{background-color:#f1f8ff;}
.city-picker-dropdown{position:absolute;width:315px;left:-9999px;top:-9999px;outline:0;-webkit-tap-highlight-color:rgba(0,0,0,0);z-index:999999;display:none;min-width:330px;margin-bottom:20px;}
.city-select-wrap{box-shadow:0 1px 5px rgba(0,0,0,0.5);}
.city-select-tab{border-bottom:1px solid #ccc;background:#f0f0f0;font-size:13px;}
.city-select-tab > a{display:inline-block;padding:8px 22px;border-left:1px solid #ccc;border-bottom:1px solid transparent;color:#4D4D4D;text-align:center;outline:0;text-decoration:none;cursor:pointer;font-size:14px;margin-bottom:-1px;}
.city-select-tab > a.active{background:#fff;border-bottom:1px solid #fff;color:#46A4FF;}
.city-select-tab > a:first-child{border-left:none;}
.city-select-tab > a:last-child.active{border-right:1px solid #ccc;}
.city-select-content{width:100%;min-height:10px;background-color:#fff;padding:10px 15px;box-sizing:border-box;}
.city-select{font-size:13px;}
.city-select dl{line-height:2;clear:both;padding:3px 0;margin:0;}
.city-select dt{position:absolute;width:2.5em;font-weight:500;text-align:right;line-height:2;}
.city-select dd{margin-left:0;line-height:2;}
.city-select.province dd{margin-left:3em;}
.city-select a{display:inline-block;padding:0 10px;outline:0;text-decoration:none;white-space:nowrap;margin-right:2px;text-decoration:none;color:#333;cursor:pointer;}
.city-select a:hover,.city-select a:focus{background-color:#f1f8ff;border-radius:2px;color:#46A4FF;}
.city-select a.active{background-color:#46A4FF;color:#fff;border-radius:2px;}

.typeCon { display: inline-block;vertical-align: middle; margin-right:8px;}
.typeCon img { float: left;margin-right: 3px; }
.typeCon .typeCon-text { display: inline-block;float: left; margin-top:3px; }

.mdui-call-m {
    width: 100%;
    height:50px;
    color:#7b7b7b;
    background: #FFF;
    position: fixed;
    margin: 0;
    bottom:0;
    border-top: 1px solid #DFDEDB;
    z-index:999;
}
.mdui-call-m .mdui-call-text-m {
    text-align: center;
    line-height: 50px;
}
</style>