<template>
    <div>
        <headerTopPc></headerTopPc>
        <div id="content-pc">
            <section class="breadcrumb-area">
                <div class="breadcrumb-text">
                    <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>查詢訂單</span>
                </div>
            </section>
        </div>
        <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
            <div class="mdui-toolbar-title">
                <div class="top-left-options" @click="utils.goBack()">
                    <a class="text-icon-options" href="javascript:void(0)">
                        <img src="../assets/images/icon-back.png">
                    </a>
                </div>
                <div class="mdui-center mdui-valign" style="font-size: 16px;color: #000">查詢訂單</div>
                <div class="top-right-options">
                    <a id="customer_service" class="text-icon-options" :href="config.shop_service">
                        <img src="../assets/images/icon-customer-service.png">
                        <span class="text-service">客服</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="mdui-container-fluid">
            <div id="htmlFlag" class="mdui-row" style="position: relative;">
                <img class="mdui-img-fluid mdui-custom-img" src="../assets/images/order-bg.jpg" style="border-radius:0px!important">
                <div class="mdui-custom-input">
                    <input class="mdui-float-left keywords-input" type="text"  v-model="keywords" placeholder="請輸入手機號或訂單號...">
                    <button class="clear-btn" v-if="keywords != ''" onClick="location.href = '/query'">
                        <img src="../assets/images/close-2.png" alt="">
                    </button>
                    <button class="mdui-float-right mdui-btn search-btn" tyle="button" @click="query">查詢</button>
                </div>
            </div>
            <div id="searchResult" class="mdui-row" v-if="isSearch">
                <div class="mdui-row mdui-custom-order" v-for="(item,index) in orderList">
                    <div class="order-header openOrder">
                        <div>
                            <div class="order-code">訂單號：{{item.out_trade_no}}</div>
                            <div class="order-desc">
                                <span>共{{item.goods_number}}件商品</span>
                                <span>{{item.create_time}}</span>
                            </div>
                        </div>
                        <a style="display:block" href="javascript:;" @click="utils.url('orderinfo',{no:item.out_trade_no})">
                            <span style="font-size:12px;color:#777">查看詳情 ＞</span>
                        </a>
                    </div>
                    <ul class="mdui-list order-package">
                        <li class="mdui-list-item">
                            <div class="mdui-list-item-flex">
                                <span class="package-tit">
                                    <span class="package-name">貨態</span>
                                    <i style="color:#C5221A">{{item.express_text}}</i>
                                </span>
                                <div class="package-detail">
                                    <div class="skuImg">
                                        <ul>
                                            <li v-for="goods in item.goods" @click="utils.url('detail',{id:goods.goods_id})">
                                                <img :src="goods.goods_images">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="btnBox">
                                <button class="remindShip over" @click="remind(item.out_trade_no,index)" v-if="!item.remind && (item.status == 2 || item.status == 9)">提醒出貨</button>
                                <button class="proDetail" v-if="item.status == 1 && item.payment == 2" @click="pay(item.out_trade_no)">線上付款</button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="empty-carts" style="display:none" v-if="orderList.length <= 0" :style="{display:isEmpty ? 'block' : 'none'}">
                    <img class="cart-img-empty" src="../assets/images/icon-cart-empty.png">
                    <span class="cart-tip-empty">沒有找到您的訂單哦</span>
                    <a href="javascript:;" @click="utils.url('index',{v:1})" class="mdui-btn cart-empty-to-buy">去逛逛</a>
                </div>
            </div>
            <div class="mdui-custom mdui-row mdui-call" style="display:block"   v-if="!isSearch">
                <div class="mdui-custom-header">
                    <span class="mdui-float-left">聯絡我們</span>
                </div>
                <div class="">
                    <div class="mdui-call-action">
                        <ul>
                            <li v-if="config.facebook_home">
                                <a :href="config.facebook_home" target="_blank">
                                    <img src="../assets/images/icon-fb.png">
                                    <span>FB粉絲專頁</span>
                                </a>
                            </li>
                            <li v-if="config.shop_service">
                                <a :href="config.shop_service">
                                    <img src="../assets/images/icon-line.png">
                                    <span>LINE@</span>
                                </a>
                            </li>
                            <li v-if="config.service_hotline">
                                <a :href="'tel:'+config.service_hotline">
                                    <img src="../assets/images/icon-tel.png">
                                    <span style="line-height: 0"><span>客服電話</span><br>
                                        <span style="font-size:12px;padding-top:6px;line-height: 12px">{{config.service_hotline}}</span>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div id="content-mobile" class="mdui-call-link">
                        <ul>
                            <li><a href="javascript:;" @click="$router.push({name:'PrivacyPolicy'})">客戶隱私條款</a></li>
                            <li><a href="javascript:;" @click="$router.push({name:'ServiceTerms'})">客戶服務政策</a></li>
                    		<li><a href="javascript:;" @click="$router.push({name:'ReturnPolicy'})">退換貨政策</a></li>
                        </ul>
                        <ul>
                            <li><a href="javascript:;" @click="$router.push({name:'AboutUs'})">關於&聯絡我們</a></li>
                            <li><a href="javascript:;" @click="$router.push({name:'Questions'})">付款方式</a></li>
                            <li><a href="javascript:;" @click="$router.push({name:'Questions'})">運送方式&運費</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mdui-custom mdui-row mdui-footer" v-if="!isSearch">
                <div class="mdui-custom-header">
                    <span class="mdui-float-left">最近瀏覽過的商品({{footprintList.length}})</span>
                </div>
                <div class="mdui-custom-content">
                    <div class="mdui-pannel-content mdui-pannel-length" v-for="item in footprintList" @click="utils.url('detail',{id:item.id})">
                        <a class="mdui-product-card" href="javascript:;" >
                            <div class="mdui-product-card-img">
                                <img class="mdui-img-fluid" :src="item.images">
                            </div>
                            <div class="mdui-product-card-title">{{item.title}}</div>
                            <div class="mdui-product-card-subtitle">
                                <span class="price1">{{item.sale_price}}</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-pc">
            <footmain></footmain>
        </div>
        <share v-if="showShare" @cancel="cancelShare"></share>
        <loader :loader="loader"></loader>
    </div>

</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import footmain from '../views/common/footmain.vue';
    import share from '../views/common/share.vue';
    import loader from '../views/common/loader.vue';
    export default {
        components:{
            headerTopPc,
            footmain,
            share,
            loader
        },
        name: "query",
        data() {
            return {
                keywords:'',
                isSearch:false,
                showShare: false,
                config:{},
                footprintList:[],
                orderList:[],
                loader:false,
                isEmpty:false
            }
        },
        methods: {
            cancelShare(data){
                this.showShare = false
            },


            query()
            {
                if( !this.keywords ){
                    this.$layer.msg('請輸入搜尋關鍵字');
                    return false;
                }
                //this.isSearch = true
                location.href = '/query?keys=' + this.keywords
            },

            remind(no,index){
                this.api.get('orders/remind',{no:no}).then( res => {
                    this.$layer.msg('提醒成功');
                    let data = this.orderList[index];
                    data.remind = 1;
                    this.$set(this.orderList,index,data);
                } )
            },

            submit(){
                if( !this.keywords ){
                    this.$layer.msg('請輸入搜尋關鍵字');
                    return false;
                }
                console.log(this.keywords)
                this.isSearch = true
                this.loader = true

                this.api.get('orders/query',{keywords:this.keywords}).then(res=>{
                    if( res.data.length <= 0 ){
                        this.isEmpty = true
                    } else {
                        this.isEmpty = false
                    }
                    this.orderList = res.data
                    this.loader = false
                })
            },

            pay(no){
                this.$Modal.confirm({
                    title: 'Tips',
                    content: '信用卡付款採用交易授權機制及簡訊驗證保證安全。進入信用卡付款頁面後，只需填寫：帶紅色*號的四項（信用卡號、有效期限、末3碼、手機號碼）即可',
                    okText: '去信用卡付款',
                    cancelText:'取消',
                    onOk: () => {
                        this.$Message.loading({
                            content: '正在載入付款頁面...',
                            duration: 1000000000
                        });

                        this.api.get('orders/lvpay',{no:no}).then(result => {
                            location.href = result.data.domain + '/lvpay?no=' + no;
                        })
                    },
                    onCancel:()=>{
                        this.$Modal.remove();
                    }
                });
            }
        },
        watch: {},
        mounted() {

            this.keywords = this.$route.query.keys || ''
            if( this.keywords ){
                this.submit()
            }

            this.config = this.utils.config()
            let ids = this.utils.footprint().toString();
            //console.log(ids)
            this.api.get('goods/records',{ids:ids}).then( res => {
                this.footprintList = res.data
            })
        }
    }
</script>

<style scoped>
@media (max-width:767px){
.mdui-container-fluid{margin:0 0 55px;}
}
@media (min-width:768px){
.mdui-container-fluid{width:750px;margin:10px auto 10px;}
}
@media (min-width:992px) {
.mdui-container-fluid{width:970px;margin:10px auto 10px;}
}
@media (min-width:1200px) {
.mdui-container-fluid{width: 1170px;margin:10px auto 10px;}
}

.empty-carts {
    text-align: center;
    height: 260px;
    box-sizing: border-box;
    margin-top: 48px;
    padding-top: 48px;
}

.empty-carts .cart-img-empty {
    display: block;
    margin: 0px auto;
    width: 24%;
    max-width: 100px;
}

.empty-carts .cart-tip-empty {
    display: block;
    color: #7B7B7B;
    padding: 16px 0px;
}

.empty-carts .cart-empty-to-buy {
    border: 1px solid #E94D8A;
    border-radius: 2px;
    color: #E94D8A;
    line-height: 14px;
    width: 110px;
    height: 30px;
    line-height: 30px;
    background: #FFF;
}

.empty-carts .mdui-page-bottom {
    background: #FFF;
    position: absolute;
    bottom: 0px;
    width: 100%;
}
.mdui-appbar-with-toolbar {
    padding-top: 37px;
}

.mdui-custom-header {
    overflow: hidden;
    height: auto!important;
}

.mdui-list-item:hover {
    background: #fff;
}

.mdui-custom-img {
    width: 100%;
    max-height: 140px
}

.mdui-custom-input {
    background: #FFF;
    position: absolute;
    width: 89.3%;
    top: calc(50% - 20px);
    left: 5.3%;
    border-radius: 2px
}

.mdui-custom-input .keywords-input {
    outline: 0;
    height: 24px;
    line-height: 24px;
    border: none;
    font-size: 14px;
    box-sizing: border-box;
    margin-top: 8px;
    padding: 0 30px 0 10px;
    width: calc(100% - 60px);
    border-right: 1px solid #DFDEDB;
    border-radius: 0
}

.mdui-custom-input .search-btn {
    height: 40px;
    line-height: 40px;
    width: 60px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #E94D8A;
    font-size: 16px;
    min-width: initial
}

.mdui-custom-input .search-btn[disabled] {
    color: rgba(180, 39, 45, .3) !important
}

.mdui-custom-input .clear-btn {
    position: absolute;
    background: transparent;
    border: 0;
    height: 40px;
    right: 62px;
    display: flex;
    align-items: center
}

.mdui-custom-input .clear-btn:focus {
    outline: none
}

.mdui-custom-input .clear-btn.hide {
    display: none;
}

.mdui-custom-input .clear-btn img {
    height: 16px;
}

.mdui-custom-header {
    height: 18px;
    line-height: 18px;
    background: #FFF;
    padding: 15px 2.7% 12px;
    font-size: 18px;
}

.mdui-custom-header .mdui-float-right {
    color: #E94D8A;
    text-decoration: none
}

.cart-empty-to-buy {
    border: 1px solid #E94D8A;
    width: 100px;
    border-radius: 2px;
    height: 28px;
    line-height: 14px;
    color: #E94D8A;
    margin-top: 20px
}

.mdui-custom .mdui-custom-content {
    display: flex;
    flex-wrap: wrap;
    background: #FFF;
}

@media (max-width: 768px){
.mdui-custom .mdui-custom-content .mdui-pannel-content {width: 29.7%;margin-left: 2.7%;}
}
@media (min-width: 769px) {
.mdui-custom .mdui-custom-content .mdui-pannel-content {width: 22.5%;margin-left: 2%;}
}

.mdui-custom .mdui-product-card {
    margin-top: 15px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none
}

.mdui-custom .mdui-product-card .mdui-product-card-title {
    margin: 0 auto;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 90%;
    white-space: nowrap
}

.mdui-custom .mdui-product-card .mdui-product-card-subtitle {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 10px;
}

.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price1 {
    font-size: 16px;
    color: #E94D8A;
    line-height: 16px
}

.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price1::before {
    font-size: 12px;
    content: 'NT$';
    line-height: 16px
}

.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price2 {
    padding-left: 1px;
    font-size: 12px;
    color: #777777;
    line-height: 12px;
    text-decoration: line-through
}

.mdui-custom-order {
    background: #FFF;
    margin-bottom: 12px;
    margin: 10px 2.8% 0;
    border-radius: 8px;
    overflow: hidden;
}

.mdui-custom-order .order-time {
    height: 30px;
    line-height: 30px;
    background: #FFF5FB;
    font-size: 12px;
    color: #B19F7B;
    padding: 0 4%
}

.mdui-custom-order .order-header {
    line-height: 30px;
    border-bottom: 1px solid #EDEDED;
    color: #000;
    padding: 12px 2.7%;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.mdui-custom-order .order-header>span {
    font-size: 12px;
    color: #777777;
    text-align: right;
    line-height: 12px;
}

#searchResult .mdui-custom-order .order-header>span .material-icons {
    margin-right: -8px;
    margin-left: -6px
}

.mdui-custom-order .order-header .order-code {
    line-height: 14px;
    padding-bottom: 8px;
}

.mdui-custom-order .order-header .order-code .order-state {
    background: #E29EAD;
    color: #fff;
    display: inline-block;
    border-radius: 14px;
    padding: 3px 8px;
    margin-left: 4px;
    font-size: 12px;
    line-height: 12px;
    vertical-align: text-bottom;
}

.mdui-custom-order .order-header .order-code .mdui-btn {
    height: 22px;
    min-width: initial;
    padding: 0;
    width: 40px;
    line-height: 22px;
    font-size: 12px;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    color: #000;
    margin-left: 6px;
}

.mdui-custom-order .order-header .order-desc {
    font-size: 12px;
    color: #bcbcbc;
    line-height: 12px;
}

.mdui-custom-order .order-header .order-desc span:first-child {
    margin-right: 12px;
}

.order-package {
    padding: 0;
}

.order-package .package-tit {
    width: 62px;
    text-align: center;
    line-height: 10px;
}

.order-package .package-tit .package-name {
    font-size: 10px;
    color: #000000;
    padding: 3px 6px;
    background: #F4F4F4;
    border-radius: 8px;
    word-break: keep-all;
    display: inline-block;
}

.order-package .package-tit i {
    margin-top: 6px;
    font-size: 10px;
    display: block;
    font-style: normal;
}

.order-package .mdui-list-item {
    padding: 0;
    display: block;
    border-bottom: 1px dashed #DDD;
}

.order-package .mdui-list-item:last-child {
    border-bottom: 0;
    margin-bottom: 0;
}

.order-package .mdui-list-item .mdui-list-item-flex {
    display: flex;
    align-items: center
}

.order-package .package-detail {
    width: calc(100% - 64px);
    padding: 12px 10px 12px 0;
}

.order-package .package-detail .expressInfo span {
    display: block;
}

.order-package .package-detail .expressInfo span:first-child {
    line-height: 10px;
    font-size: 10px;
    color: #777777;
    padding: 8px 0 6px;
}

.order-package .package-detail .expressInfo span:last-child {
    font-size: 12px;
    color: #007AFF;
    line-height: 16px;
}

.order-package .btnBox {
    margin-bottom: 10px;
    padding-left: 60px;
}

.order-package .btnBox button {
    background: #FFFFFF;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    padding: 6px 10px;
    font-size: 12px;
    color: #000000;
    line-height: 12px;
    margin-right: 3px
}

.order-package .btnBox button:hover {
    outline: none
}

.skuImg {
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
}

.skuImg ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.skuImg ul li {
    margin-right: 6px;
    width: 60px;
    height: 60px;
    min-width: 60px;
    max-width: 60px;
    position: relative;
}

.skuImg ul li .bandage {
    position: absolute;
    right: 3px;
    bottom: 3px;
    background: rgba(0, 0, 0, .5);
    border-radius: 8px;
    display: block;
    font-size: 10px;
    color: #FFFFFF;
    padding: 2px 5px;
}

.skuImg ul li img {
    width: 100%;
    height: 100%;
    border-radius: 4px
}

.order-content,
.package-content {
    background: #F4F4F4;
    border-top: 1px solid #EDEDED
}

.order-content>div,
.package-content>div {
    border-radius: 8px;
    background: #fff;
    margin-top: 10px;
    overflow: hidden;
}

.order-content>div:last-child,
.package-content>div:last-child {
    margin-bottom: 80px;
}

.order-contains {
    overflow-y: auto;
}

.order-contains a {
    text-decoration: none;
}

.order-contains .order-item {
    margin: 14px 4%;
    position: relative
}

.order-contains .order-item img {
    width: 80px;
    height: 80px;
}

.order-contains .order-item .order-item-details {
    width: calc(100% - 90px);
    float: right
}

.order-contains .order-item .order-item-details .top .tags {
    border: 1px solid #7B7B7B;
    width: 25px;
    background: #fff;
    border-radius: 12px;
    font-size: 12px;
    line-height: 12px;
    color: #7B7B7B;
    margin-top: 2%;
    padding: 2px 8px;
    display: block
}

.order-contains .order-item .order-item-details .top {
    font-size: 0;
}

.order-contains .order-item .order-item-details .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    white-space: nowrap;
    font-size: 14px;
    color: #363636
}

.order-contains .order-item .order-item-details .sku {
    font-size: 12px;
    color: #979797;
    display: block;
    margin-top: 8px
}

.order-contains .order-item .order-item-details .bottom {
    position: absolute;
    width: calc(100% - 90px);
    bottom: 4px
}

.order-contains .order-item .order-item-details .bottom span {
    font-size: 14px;
    color: #E94D8A
}

.order-contains .order-item .order-item-details .bottom .originalPrice {
    margin-left: 2px;
    font-size: 12px;
    color: #7B7B7B;
    text-decoration: line-through
}

.order-content {
    font-size: 12px;
    line-height: 18px;
    color: #7B7B7B;
    padding: 9px 4%;
}

.order-price {
    box-sizing: border-box;
    border-top: 1px solid #EDEDED;
    padding: 15px 4%;
    font-size: 12px;
    color: #777;
}

.order-price ul {
    padding: 0;
}

.order-price ul li {
    display: flex;
    padding: 0;
    min-height: 12px;
    line-height: 12px;
    font-size: 12px;
    margin-bottom: 18px;
}

.order-price ul li:after {
    height: auto;
}

.order-price ul li:last-child {
    margin-bottom: 0;
}

.order-price ul li span {
    width: 50%;
}

.order-price ul li div {
    flex: 1;
    text-align: right;
}

.order-price ul li .mdui-btn-copy {
    background: #fff;
    color: #E94D8A;
    font-size: 12px;
    border: 0;
    line-height: 12px;
}

.order-price ul li .mdui-btn-copy:hover {
    outline: none
}

.order-price ul li .orderDiscount::before {
    content: '-';
    font-size: 18px;
    line-height: 14px;
}

.order-price ul li.order-pay {
    box-sizing: border-box;
    border-top: 1px dashed #EDEDED;
    font-size: 16px;
    color: #363636;
    display: flex;
    margin: 4px 0 0;
    padding-top: 10px;
}

.order-price ul li.order-pay span {
    color: #000
}

.order-price ul li.order-pay div {
    color: #E94D8A;
    text-align: right;
    display: flex;
    justify-content: flex-end
}

.order-price ul li.order-pay div i {
    font-size: 12px;
    color: #E94D8A;
    font-style: normal;
    margin-right: 2px;
}

.mdui-btn-copy {
    color: #E94D8A;
    padding: 0 6px;
}

.package-content .order-service a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    text-decoration: none;
    color: #000000;
}

.package-content .order-service a img {
    width: 25px;
    height: 25px;
    margin-right: 12px;
}

.package-content .order-service a span {
    display: flex;
    align-items: center
}

.package-price {
    display: flex;
    padding: 15px 4%;
    font-size: 16px;
    justify-content: space-between;
    align-items: center
}

.package-price i {
    font-style: normal;
    font-size: 12px;
    margin-right: 2px;
}

.package-price>div {
    color: #E94D8A;
}

.expressScroll {
    overflow: auto;
    min-height: 126px;
    max-height: 376px;
}

.expressInfos {
    list-style: none;
    padding: 12px;
}

.expressInfos li {
    color: #979797;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.expressInfos li:last-child {
    margin-bottom: 0;
}

.expressInfos li:not(.showMore):after {
    content: '';
    display: block;
    border-left: 1px solid #979797;
    min-height: 60px;
    position: absolute;
    left: 69px;
    bottom: -44px;
}

.expressInfos li:last-child:after {
    display: none;
}

.expressInfos li i {
    font-style: normal;
}

.expressInfos li span {
    display: block;
}

.expressInfos li span.time {
    width: 56px;
    min-width: 56px;
    text-align: center;
    font-size: 12px;
}

.expressInfos li span.time i {
    display: block;
    font-size: 10px;
    margin-top: 4px;
}

.expressInfos li span.icon {
    margin-right: 10px;
    min-width: 27px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    overflow: hidden;
    display: block;
    z-index: 10;
    position: relative;
}

.expressInfos li span.icon:not(.noPic) {
    border: 1px solid #BCBCBC;
    background: #fff;
}

.expressInfos li span.icon img {
    width: 27px;
    margin: 0 auto;
    display: block;
}

.expressInfos li span.noPic i {
    background: #BCBCBC;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    display: block;
    margin: 11px auto;
}

.expressInfos li span.detail {
    font-size: 14px
}

.expressInfos li:first-child span {
    color: #000
}

.expressInfos li:first-child span.icon {
    border-color: #007AFF;
    background: #007AFF;
}

.expressInfos li:first-child span.icon.noPic {
    background: transparent
}

.expressInfos li:first-child span.icon>i {
    background: #007AFF;
}

.expressInfos li:first-child span.detail {
    color: #007AFF
}

.expressInfos li.hide {
    display: none;
}

.expressInfos li.showMore {
    font-size: 12px;
    justify-content: center
}

.expressInfos li.showMore img {
    height: 12px;
}

.expressInfos.havHide li:nth-child(4)::after {
    display: none;
}

.expressDetail .skuImg {
    padding: 10px 4%;
    background: #FFF5FB;
    box-sizing: border-box
}

.expressNone {
    text-align: center;
    color: #777;
    font-size: 14px;
    padding-top: 38px;
}

.expressNone span {
    display: block;
    margin-bottom: 12px;
}

.expressNone a {
    display: block;
    text-decoration: none;
    color: #E94D8A;
}

.productDetail .package-price {
    padding: 15px 3%;
    background: #FFF5FB;
    color: #000000;
    line-height: 16px;
    font-size: 16px;
}

.productDetail .package-price span i {
    margin-left: 6px;
}

.productDetail .package-price span.price {
    color: #E94D8A;
}

.productDetail .package-price span.price:before {
    content: '$';
    font-size: 12px;
    line-height: 12px;
}

.mdui-call {
    background: #FFF;
}

.mdui-call ul {
    list-style: none;
    padding: 0 6px;
    margin: 0;
    display: flex;
}

.mdui-call ul li {
    text-align: center;
    flex: 1;
    font-size: 14px;
    margin: 0 6px 10px;
    background: #F4F4F4;
    border-radius: 6px;
    display: block;
    padding-bottom: 10px;
}

.mdui-call ul a {
    text-decoration: none;
    color: #363636;
}

.mdui-call ul img {
    display: block;
    margin: 15px auto 10px;
    height: 40px;
}

.mdui-call ul span {
    display: block;
    line-height: 35px;
}

.mdui-call ul span span {
    display: block;
    line-height: 14px;
}

.mdui-call .mdui-call-link ul {
    margin: 0 12px 0;
    border-radius: 6px;
    background: #F4F4F4;
}

.mdui-call .mdui-call-link ul li {
    margin: 8px 0;
    padding: 0;
    border-right: 1px solid #BCBCBC;
    border-radius: 0
}

.mdui-call .mdui-call-link ul li:last-child {
    border-right: 0
}

.stopReturn .tip-center-head {
    padding-top: 3px;
}

.stopReturn .tip-center-box {
    width: 84%;
    min-height: 100px;
}

.stopReturn .tip-center-content {
    padding: 15px;
    min-height: 100px;
}

.stopReturn .tip-center-content p {
    margin: 0;
    margin-bottom: 20px;
    line-height: 20px;
}

.stopReturn .tip-center-content .close {
    border-radius: 4px;
    background: #E94D8A;
    font-size: 14px;
    color: #fff;
    padding: 11px 76px;
    border: 0;
    margin: 36px auto 9px;
    display: block;
}

.card {
    margin: 10px 0 0;
    border-radius: 6px;
    overflow: hidden;
    background: #fff;
}

.mdui-address {
    position: relative;
    box-sizing: border-box;
    height: 75px;
    background: #FFFCEC url(../assets/images/border.jpg) bottom repeat-x;
    font-size: 14px;
    color: #363636;
    padding: 12px 4%;
    cursor: pointer
}

.mdui-address span {
    display: inline-block;
    margin-right: 12px;
    line-height: 24px;
    font-weight: 700;
    font-size: 16px
}

.mdui-address span:last-child {
    display: block;
    font-weight: 500;
    font-size: 12px
}

.mdui-address .mdui-icon {
    position: absolute;
    top: 23px;
    right: 4%;
    font-size: 24px;
    margin-right: -10px;
    color: #979797!important
}

.order-content .mdui-address {
    background: #FFFCEC url(../assets/images/border.jpg) bottom repeat-x;
}

.view-bottom-box .view-bottom-content.package-return,
.view-bottom-box .view-bottom-content.history-state,
.view-bottom-box .view-bottom-content.test-phone,
.view-bottom-box .view-bottom-content.history-select,
.view-bottom-box .view-bottom-content.fill-reason,
.view-bottom-box .view-bottom-content.post-form,
.view-bottom-box .view-bottom-content.select-replace,
.view-bottom-box .view-bottom-content.return-history {
    background-color: #f4f4f4;
    padding: 0 10px
}

.history-select+div.view-bottom-footer>button:not(.sure),
.fill-reason+div.view-bottom-footer>button:not(.sure),
.post-form+div.view-bottom-footer>button:not(.sure),
.select-replace+div.view-bottom-footer>button:not(.sure),
.test-phone+div.view-bottom-footer>button:not(.sure) {
    height: 50px;
    border: 0;
    background: #E94D8A;
    border-radius: 0;
    color: #fff;
    margin-right: -4%;
    width: 36%;
    font-size: 14px;
}

.history-select+div.view-bottom-footer>button:not(.sure):focus,
.fill-reason+div.view-bottom-footer>button:not(.sure):focus,
.post-form+div.view-bottom-footer>button:not(.sure):focus,
.select-replace+div.view-bottom-footer>button:not(.sure):focus,
.test-phone+div.view-bottom-footer>button:not(.sure):focus {
    outline: none
}

.test-phone+div.view-bottom-footer {
    padding: 0 4%;
}

.fill-reason+div.view-bottom-footer,
.select-replace+div.view-bottom-footer,
.post-form+div.view-bottom-footer {
    padding: 0;
}

.fill-reason+div.view-bottom-footer>button:not(.sure),
.select-replace+div.view-bottom-footer>button:not(.sure),
.post-form+div.view-bottom-footer>button:not(.sure) {
    margin-right: 0;
}

.fill-reason+div.view-bottom-footer>button.left,
.select-replace+div.view-bottom-footer>button.left,
.post-form+div.view-bottom-footer>button.left {
    float: left;
    color: #000000;
    background: #fff;
    margin-left: -4px;
    font-size: 16px;
    width: auto;
}

.option ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.option ul li,
.option ul li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    text-decoration: none;
    width: 100%
}

.option ul li:last-child h4 {
    line-height: 56px;
    margin: 0;
}

.option ul li img {
    width: 20px;
    margin-left: 18px;
    margin-right: 23px;
}

.option ul li div {
    border-bottom: 1px solid #EDEDED;
    height: 56px;
}

.option ul li:last-child div {
    border-bottom: 0
}

.option ul li div:nth-child(2) {
    flex: 1
}

.option ul li div h4 {
    font-size: 14px;
    line-height: 14px;
    margin: 11px 0 6px;
    font-weight: normal;
    color: #000
}

.option ul li div p {
    font-size: 12px;
    line-height: 14px;
    color: #777777;
    margin: 6px 0 11px;
}

.option ul li div i {
    margin: 16px 0;
}

div.card:last-child {
    margin-bottom: 40px;
}

.return-history .card {
    padding: 0 0 0 12px;
}

.return-history .card i {
    font-style: normal;
    line-height: 24px;
    display: inline-block;
}

.return-history .card i.material-icons {
    margin-right: -8px;
    margin-left: -6px
}

.return-history .card ul {
    margin: 10px 0;
}

.return-history .card .order-header {
    padding-left: 0;
    display: flex;
    align-items: center;
}

.return-history .card .order-header .order-desc {
    color: #000;
    flex: 1;
    padding: 0 6px;
}

.return-history .card .order-header>i {
    color: #fff;
    font-size: 12px;
    padding: 0 5px;
    border-radius: 3px;
    line-height: 16px;
}

.history-state {
    padding-bottom: 30px;
}

.history-state .customer_service img,
.history-state h3 img {
    width: 20px;
    margin: 0 6px 0 3px;
}

.history-state h3 {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin: 6px 0 18px;
    display: flex;
    align-items: center;
}

.history-state .card {
    padding: 12px;
    margin: 10px 0;
}

.history-state .card:nth-last-child(2) {
    background: #FFF url(../assets/images/border.jpg) top repeat-x;
}

.history-state .time {
    font-size: 14px;
    color: #777777;
    line-height: 14px;
    margin-bottom: 6px;
    display: block;
}

.history-state .card p {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    margin: 0;
}

.history-state .order-contains .order-item {
    margin: 10px 0;
    padding: 10px;
    background: #FFF5FB;
    border-radius: 4px;
}

.history-state .order-contains .order-item .bottom {
    bottom: 14px;
    width: calc(100% - 110px);
}

.history-state .price {
    font-size: 14px;
    color: #E94D8A;
    line-height: 14px
}

.history-state .price::before {
    font-size: 14px;
    content: '$';
    line-height: 14px;
    margin-left: 6px;
}

.history-state i {
    font-style: normal;
    font-size: 12px;
    line-height: 12px;
    color: #777777;
    margin: 8px 0;
    display: block;
}

.history-state i.material-icons {
    font-size: 24px
}

.history-state button {
    margin: 16px 0 6px 0;
    color: #000;
    font-size: 14px;
    border: 1px solid #BCBCBC;
    display: block;
    background: transparent;
    border-radius: 4px;
    padding: 7px 12px;
    line-height: 14px;
}

.history-state a.customer_service {
    color: #000;
    text-decoration: none;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.history-state a.customer_service span {
    flex: 1
}

.history-state .card>img {
    width: 100%;
    margin: 10px 0;
}

.test-phone .card {
    padding: 15px;
    text-align: center;
}

.test-phone h2 {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    margin: 1px 0 0;
    font-weight: normal;
}

.test-phone p {
    font-size: 12px;
    line-height: 12px;
    color: #777777;
    margin: 8px 0 18px;
}

.test-phone input {
    display: block;
    margin-bottom: 10px;
    border: 1px solid rgba(151, 151, 151, 1);
    line-height: 24px;
    border-radius: 4px;
    text-align: center;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
    padding: 8px 0
}

.mdui-cart-products {
    background: #FFFFFF;
    margin-bottom: 10px;
    border-radius: 6px;
    overflow: hidden
}

.mdui-cart-products .mdui-list-item {
    padding: 12px 4%;
    font-size: 0;
    flex-wrap: wrap;
}

.mdui-cart-products .mdui-list-item:after {
    height: 0
}

.mdui-cart-products .mdui-list-item.gift-item {
    padding-bottom: 6px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-remark {
    display: block;
    width: 100%;
    color: #BCBCBC;
    line-height: 10px;
    margin-top: 8px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-remark span {
    font-size: 12px;
    display: block;
    transform: scale(0.8);
}

.mdui-cart-products .mdui-list-item:hover {
    background-color: initial;
}

.mdui-cart-products .mdui-list-item .check-icon,
.mdui-cart-products .mdui-list-item .exchange-check {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title {
    font-size: 14px;
    color: #000;
    height: 18px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion {
    display: inline-block;
    border-radius: 8px;
    height: 16px;
    padding: 3px 4px;
    box-sizing: border-box;
    background: rgba(242, 143, 45, .1);
    color: #F56207;
    margin-right: 3px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span {
    font-size: 12px;
    display: block;
    text-align: center;
    transform: scale(0.8);
    line-height: 10px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-text {
    font-size: 12px;
    color: #7B7B7B;
    height: 12px;
    margin-top: 6px
}

.mdui-cart-products .mdui-cart-product-sku-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    vertical-align: middle;
}

.mdui-cart-products .mdui-cart-product-sku-info {
    width: calc(100% - 125px);
    height: 80px;
    position: relative;
    top: 0px;
    float: right;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 {
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price {
    font-size: 14px;
    color: #E94D8A;
    position: absolute;
    bottom: 0;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price {
    font-size: 12px;
    color: #7B7B7B;
    text-decoration: line-through;
    padding-left: 2px
}

.mdui-cart-products .mdui-list {
    padding: 0px !important;
}

.mdui-cart-products .mdui-list>.mdui-divider-inset {
    height: 8px;
    margin: 0px !important;
    background: #F4F4F4;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags {
    display: inline-block;
    border-radius: 8px;
    height: 16px;
    padding: 3px 4px;
    box-sizing: border-box;
    background: rgba(180, 39, 45, .1);
    color: #E94D8A;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span {
    font-size: 12px;
    display: block;
    text-align: center;
    transform: scale(0.8);
    line-height: 10px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text {
    bottom: 4px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .promotion-num {
    font-size: 12px;
    color: #7B7B7B;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price {
    top: 0px;
}

.mdui-cart-products .mdui-cart-products-bottom {
    margin: 0 0 0 4%;
    padding-right: 4%;
    margin-left: calc(4% + 35px);
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #EDEDED;
    cursor: pointer;
}

.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right {
    color: #E94D8A;
}

.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon {
    color: #E94D8A;
    margin-top: -2px;
    margin-right: -10px;
}

.mdui-numbox {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 28px;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    color: #363636;
    border-radius: 4px;
    overflow: hidden;
}

.mdui-numbox .mdui-numbox-btn i {
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
}

.mdui-numbox .mdui-numbox-btn {
    width: 28px;
    height: 28px;
    border: none;
    background: #FFF;
    outline: none;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.mdui-numbox .mdui-numbox-btn .mdui-icon {
    font-size: 16px;
    color: #363636;
}

.mdui-numbox .mdui-numbox-input {
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: normal;
    border: none;
    outline: none;
    padding: 0;
    text-align: center;
}

.mdui-numbox .mdui-numbox-btn-minus {
    left: 0;
}

.mdui-numbox .mdui-numbox-btn-plus {
    right: 0;
}

.mdui-numbox .mdui-numbox-btn.no-click .mdui-icon {
    color: #DFDEDB
}

.history-select h3 {
    font-size: 16px;
    color: #000;
    margin: 16px 0;
    text-align: center;
    font-weight: normal;
}

.history-select+div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.history-select+div .info {
    flex: 1;
    text-align: left;
    padding: 0;
    color: #AD2728;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
}

.history-select .order-contains h3 {
    font-size: 12px;
    color: #777777;
    margin: 12px 0;
    text-align: center;
    font-weight: normal;
}

.history-select .order-contains .order-item {
    margin: 10px 12px;
    padding: 10px;
    background: #FFF5FB;
}

.history-select .order-contains .order-item .order-item-details .bottom {
    width: calc(100% - 110px);
    bottom: 14px;
}

.fill-reason .order-contains {
    overflow: initial;
    padding: 14px 12px;
    overflow: hidden;
}

.fill-reason .order-contains .order-item {
    margin: 0;
    padding: 10px;
    background: #FFF5FB;
    border-radius: 4px;
}

.fill-reason .order-contains .order-item .bottom {
    width: calc(100% - 110px);
    bottom: 14px;
}

.fill-reason .mdui-select {
    display: block;
    margin-top: 15px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px 0;
    background-position: calc(100% - 12px) center;
}

.fill-reason .mdui-select-menu-item[selected] {
    color: #000
}

.fill-reason .mdui-select-menu-item:first-child {
    color: #777
}

.fill-reason .mdui-select-selected {
    padding: 0 12px
}

.fill-reason textarea {
    margin-top: 10px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px;
    height: 105px!important;
    overflow-y: auto;
}

.picBox ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.picBox ul li {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    margin: 0 20px 20px 0;
    display: inline-block;
    position: relative;
    vertical-align: middle;
}

.picBox ul li img {
    width: 80px;
    height: 80px;
    border-radius: 4px!important;
}

.picBox ul li.btn {
    display: inline-flex;
    align-items: center;
    border: 1px dashed #979797;
    flex-direction: column;
    justify-content: center;
}

.picBox ul li span {
    display: block;
    color: #777777;
    font-size: 24px;
}

.picBox ul li span i {
    font-size: 36px;
}

.picBox ul li span.del {
    width: 20px;
    height: 20px;
    background: #F12C20;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 0;
}

.picBox ul li span.del i {
    font-size: 16px;
    line-height: 20px;
    margin: 0 2px;
    color: #fff;
    transform: rotateZ(45deg);
}

.picBox ul li p {
    display: block;
    color: #BCBCBC;
    font-size: 12px;
    margin: 4px 0 0 0;
}

.post-form h3 {
    font-size: 16px;
    color: #000;
    margin: 16px 0;
    text-align: center;
    font-weight: normal;
}

.post-form input {
    margin-top: 10px;
    border: 1px solid #979797;
    border-radius: 4px;
    font-size: 14px;
    padding: 12px;
    display: block;
    width: calc(100% - 24px);
}

.post-form .card {
    padding: 12px
}

.select-replace h3 {
    font-size: 16px;
    color: #000;
    margin: 16px 0;
    text-align: center;
    font-weight: normal;
}

.select-replace .mdui-cart-product-sku-info {
    width: calc(100% - 90px);
}

.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text {
    padding: 10px;
    background: #F4F4F4;
    color: #000;
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
}

.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text i {
    color: #979797;
    font-size: 20px;
    margin-right: -4px;
}

.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text2 {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #777777;
}

.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text2 .mdui-list-item-text-price {
    position: initial;
    bottom: auto;
}

.returnTip {
    background: #FFF5FB;
    box-shadow: inset 0px 1px 0px 0px rgba(165, 150, 114, 0.2);
    border-radius: 4px;
    margin: 0 4%;
    padding: 0 10px;
    position: relative;
}

.returnTip::before {
    content: "";
    display: block;
    position: absolute;
    border: 8px solid transparent;
    border-bottom: 8px solid #FFF5FB;
    top: -15px;
    z-index: 1;
}

.returnTip::after {
    content: "";
    display: block;
    position: absolute;
    border: 8px solid transparent;
    border-bottom: 8px solid rgba(165, 150, 114, 0.2);
    top: -16px;
}

.returnTip ul {
    list-style: none;
    padding-left: 0;
    padding: 4px 0;
}

.returnTip ul li {
    line-height: 20px;
    font-size: 12px;
    color: #E72B0E;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.returnTip ul li i {
    font-size: 20px;
    margin-right: -8px;
}

.example-btn {
    float: right;
    color: #007AFF;
}

.example-btn i {
    height: 16px;
    margin-top: -6px;
    font-size: 20px;
    margin-right: -6px;
    margin-left: -4px;
}

.example-box {
    padding-bottom: 40px;
}

.example-box p {
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 8px;
    margin: 0;
}

.example-box img {
    border-radius: 8px!important;
    display: block;
    margin-bottom: 24px;
    width: 100%;
}

.picPlaceholder {
    background: rgba(190, 190, 190, 0.5);
    color: #979797;
    border: #fff 1px solid;
    text-align: center;
    line-height: 80px;
}

.examplePro .view-bottom-head,
.exampleCard .view-bottom-head {
    box-shadow: 1px 1px 1px #ededed;
}

.examplePro .view-bottom-box .view-bottom-content,
.exampleCard .view-bottom-box .view-bottom-content {
    padding: 16px 4%;
}

@media screen and (max-width:320px) {
    .sm-hide {
        display: none!important;
    }
}

.mdui-custom-input .keywords-input {
    background: none;
}
</style>