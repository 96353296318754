<template>
    <div style="position:fixed;top:0;left:0;z-index:1000;">
        <div class="mengcheng" style="position:fixed;top:0;left:0;width:100%;height:100%;background:rgba(0,0,0,0.5);z-index:1001;"></div>
        <div class="mdui-dialog delete-confirm mdui-dialog-open delgoods" style="display:block;top:calc(50% - 120px)">
            <div class="mdui-dialog-title">確定要刪除此商品?</div>
            <div class="mdui-dialog-content" style="height: 114px;">
                <img class="mdui-cart-product-sku-img" :src="sku.images">
                <div class="mdui-cart-product-sku-info">
                    <div class="mdui-list-item-title mdui-list-item-one-line">{{sku.title}}</div>
                    <div class="mdui-list-item-text mdui-list-item-one-line">{{sku.sku_text}}</div>
                </div>
            </div>
            <div class="mdui-dialog-actions ">
                <a href="javascript:void(0)"  class="mdui-btn mdui-ripple mdui-text-color-primary" @click="submit">確認</a>
                <a href="javascript:void(0)" class="mdui-btn mdui-ripple mdui-text-color-primary" @click="cancel">取消</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "delete",
        data() {
            return {}
        },
        props:['sku'],
        methods: {

            submit(){
                this.$emit('submit',{id:this.sku.id,action:'submit'})
            },
            cancel(){
                this.$emit('submit',{id:this.sku.id,action:'cancel'})
            }

        },
        watch: {},
        mounted(){}
    }
</script>

<style scoped>
.delete-confirm {
    width: 84%;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info {
    width: calc(100% - 90px);
    height: 90px;
    position: relative;
    top: 0px;
    float: right;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-title {
    font-size: 14px;
    color: #363636;
    margin-top: 12px;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-text {
    font-size: 12px;
    color: #7B7B7B;
    margin-top: 12px;
}

.delete-confirm .mdui-dialog-title {
    text-align: center;
}

.delete-confirm .mdui-dialog-actions {
    padding: 0px;
    display: flex;
}

.delete-confirm .mdui-dialog-actions .mdui-btn {
    margin: 0px;
    padding: 0px;
    width: 50%;
    background: #FAFAFA;
    height: 48px;
    line-height: 48px;
    border-top: 1px solid #EDEDED;
    color: #7B7B7B;
    font-size: 16px;
}

.delete-confirm .mdui-dialog-actions .mdui-btn:first-child {
    border-right: 1px solid #EDEDED;
    color: #E94D8A;
}

/*適配iphone5*/

@media (max-width:320px) {
    .mdui-cart-products .mdui-cart-product-sku-img {
        width: 64px;
        height: 64px;
    }
    .mdui-numbox {
        width: 85px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info {
        width: calc(100% - 109px);
        height: 64px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon {
        margin: 0px 4px;
    }
    .mdui-cart-products .swiper-slide.menu {
        height: 64px;
    }
}

@media (min-width:750px) {
    .delete-confirm {
        width: 60%;
    }
    .mdui-card-aggregation .mdui-card-product-content .mdui-card-product {
        width: 180px;
    }
    .mdui-card-aggregation>.mdui-card-content {
        height: auto;
    }
}
</style>