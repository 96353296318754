<template>
    <div class="address-box">
        <div class="address-main">
            <div class="address-title">
                <h4>選擇地址</h4>
                <div class="address-close" @click="$emit('cancel')">×</div>
            </div>
            <div class="address-sub">
                <span :class="{active:index == 'city'}" @click="index = 'city';areaList = [];area={}">{{city.name ? city.name :'選擇縣市'}}</span>
                <span :class="{active:index == 'area'}" @click="index = 'area'">{{area.name ? area.name :'選擇區域'}}</span>
                <span :class="{active:index == 'market'}" v-if="express != 1"  @click="index = 'market'">{{market.name ? market.name :'選擇超商'}}</span>
            </div>
            <div class="address-body">
                <ul v-if="index == 'city'">
                    <li :class="{active:city.id == item.id}" v-for="(item,index) in cityList" @click="getCity(index)">{{item.name}}</li>
                </ul>
                <ul v-if="index == 'area'">
                    <li :class="{active:area.id == item.id}" v-for="(item,index) in areaList" @click="getArea(index)">{{item.name}}</li>
                </ul>
                <ul v-if="index == 'market'">
                    <li class="" style="color: rgb(153, 153, 153);" class="active" v-for="(item,index) in marketList" @click="getMarket(index)">
                        <span style="color: rgb(51, 51, 51); font-weight: 700;">{{item.title}}【{{item.no}}】</span> <br>
                         地址：{{item.address}}
                    </li>
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "address",
        data() {
            return {
                address:{},
                city:{},
                area:{},
                market:{},
                cityList:[],
                marketList:[],
                index:'city',
            }
        },

        props:['express'],
        methods: {

            getCity(index){
                this.city = this.cityList[index]
                this.city.index = index
                this.areaList = this.cityList[index].list
                this.index = 'area'
            },

            getArea(index){
                this.area = this.areaList[index];
                this.area.index = index
                if( this.express == 1 ){
                    this.$emit('submit',{city:this.city,area:this.area})
                    return false;
                }

                this.api.get('market/get',{
                    cid:this.city.id,
                    aid:this.area.id,
                    type:this.express == 2 ? 2 : 1
                }).then( res => {
                    this.index = 'market';
                    this.marketList = res.data
                })
            },

            getMarket(index){
                this.market = this.marketList[index];
                this.$emit('submit',{
                    city:this.city,
                    area:this.area,
                    market:this.market 
                })
            }

        },
        watch: {},
        mounted() {
            this.api.get('address/get_city',{}).then( res => {
                this.cityList = res.data;
                //this.$Loading.finish();
            })
        }
    }
</script>