<template>
<div id="content-mobile">
<div class="mdui-appbar mdui-appbar-fixed mdui-shadow-0 mdui-appbar-scroll-toolbar-hide mdui-headroom mdui-appbar-scroll-toolbar-hide mdui-headroom-pinned-toolbar">
    <div class="mdui-toolbar-title">
        <div class="top-left-options" style="margin-right: 0px">
            <div class="left" @click="utils.url('category')">
                <a href="javascript:;" class="mdui-btn mdui-btn-icon">
                    <img src="../../assets/images/icon-menu.png" class="menuIcon openMenu" />
                </a>
            </div>
        </div>
        <div class="top-center-options" @click="utils.url('index',{v:1})">
            <img src="../../assets/images/logo.jpg" height="40px">
        </div>

        <div class="top-right-options">
            <a id="customer_service" class="text-icon-options" :href="config.shop_service">
                <img src="../../assets/images/icon-customer-service.png" />
                <span class="text-service">客服</span>
            </a>
            <a id="initSearch" class="text-icon-options" href="/search">
                <img src="../../assets/images/icon-search.png" />
                <span class="text-foot">搜尋</span>
            </a>
            <a id="footprint" class="text-icon-options" href="javascript:void(0)" @click="showFootprint = true">
                <img src="../../assets/images/icon-footprint.png" />
                <span class="text-foot">瀏覽</span>
            </a>
        </div>
    </div>
    <div class="mdui-nav" id="headerNav">
        <div class="li" @click="utils.url('index',{v:1})" :class="{'active':categoryId == ''}" >
            首頁
        </div>
        <div class="li" :class="{active:categoryId == item.id}" v-for="(item,index) in goodsCategoryTop" @click="goList(item.id)">
            {{item.title}}
        </div>
        
    </div>
    <div class="mdui-tab-flex" style="display:block;" v-if="$route.name == 'list'">
        <div class="mdui-tab-box" @click="showSub = !showSub">
            <div class="mdui-tab-mes">{{categorySub.title ? categorySub.title : '選擇二級分類'}}</div>
            <div class="mdui-tab-toggle">
                <span></span>
                <button class="mdui-btn arrow-down">
                <img src="../../assets/images/x.png" width="15px" v-if="!showSub">
                <img src="../../assets/images/s.png" width="15px" v-else>
                </button>
            </div>
        </div>
        <div class="mdui-tab-navport flex" v-if="showSub">
            <a class="mdui-btn nav" :class="{active:!sid}"  @click="goList(categoryId)">
                <img :src="category.images"/>
                <span>全部商品</span>
            </a>
            <a class="mdui-btn nav" v-for="item in category.child" href="javascript:;" @click="goList(categoryId,item.id)" :class="{active:sid == item.id}">
                <img :src="item.images"/>
                <span>{{item.title}}</span>
            </a>
        </div>
    </div>
</div>
    <div class="mdui-overlay mdui-overlay-show" style="z-index: 2000;" v-if="showSub"  @click="showSub = !showSub"></div>
    <footprint v-if="showFootprint" @close="showFootprint = false"></footprint>
</div>

</template>

<script>
    var vm = null;
    import footprint from '../../views/common/footprint.vue';
    export default {
        components:{footprint},
        name: "headerTop",
        data() {
            return {
                goodsCategoryTop:[],
                showFootprint : false,
                categoryId:'',
                category:{},
                categorySub:{},
                tags:[],
                showSub:false,
                sid:0,
                config:{},
                swiperOption:{
                    slidesPerView: 'auto',
                    centeredSlides: false,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    on:{
                        click(){
                            let id = vm.goodsCategoryTop[this.clickedIndex - 1].id;
                            vm.utils.url('list',{id:id});
                        }
                    }
                }
            }
        },
        methods: {

            goList(id,sid){
                if( sid ){
                    location.href = '/list?id=' + id + '&sid=' + sid ;
                } else {
                    location.href = '/list?id=' + id;
                }
            },

            goLink(key){
                location.href = '/tags?key=' + key
            }
        },
        watch: {
            $route(to,form){
                if( to.name == 'list' ){
                    this.categoryId = to.query.id
                    this.sid = to.query.sid
                    this.showSub = false
                    for(let i in this.goodsCategoryTop){
                        if( this.categoryId == this.goodsCategoryTop[i].id ){
                            this.category = this.goodsCategoryTop[i];

                            if( this.sid ){
                                for(let index in this.category.child ){
                                    if( this.sid == this.category.child[index].id ){
                                        this.categorySub = this.category.child[index]
                                    }
                                }
                            } else {
                                this.categorySub = {};
                            }

                            this.api.get('goods/getTagsListByCateId',{id:this.categoryId}).then(res=>{
                                this.tags = res.data
                            })
                            break;
                        }
                    }
                } else {
                    this.categoryId = ''
                    this.sid = ''
                }
            }
        },
        created(){
            vm = this;
        },
        mounted(){

            /**
             * 产品一级分类
             */
            this.api.get('goods/getAllGoodsCategory',{}).then( res => {
                try {
                    this.goodsCategoryTop = res.data
                    if( this.categoryId ){
                        for(let i in this.goodsCategoryTop){
                            if( this.categoryId == this.goodsCategoryTop[i].id ){
                                this.category = this.goodsCategoryTop[i];
                                this.$emit('banner',this.goodsCategoryTop[i].banner);
                                this.$emit('summary',this.goodsCategoryTop[i].summary);
                                if( this.sid ){
                                    for(let index in this.category.child ){
                                        if( this.sid == this.category.child[index].id ){
                                            this.categorySub = this.category.child[index]
                                        }
                                    }
                                } else {
                                    this.categorySub = {};
                                }
                                break;
                            }
                        }
                    }
                } catch (err){
                    console.log(err)
                }
            })

            
            if( this.$route.query.id ){
                this.categoryId = this.$route.query.id
                this.sid = this.$route.query.sid
                this.showSub = false
                this.api.get('goods/getTagsListByCateId',{id:this.categoryId}).then(res=>{
                    this.tags = res.data
                })
            }

            this.config = this.utils.config()
        }
    }
</script>

<style scoped>
@media (max-width:767px) {
body .mdui-appbar-fixed{z-index:2001}
.mdui-overlay{background:rgba(0,0,0,.5)}
.mdui-toolbar-title{box-shadow:none}
.mdui-page-container{min-height:100px;padding-left:0;padding-right:0;margin-left:0;margin-right:0;margin-bottom:20px;}
.mdui-nav { width:100%; background:#fff;scroll-behavior:smooth;cursor:pointer;}
.mdui-nav::-webkit-scrollbar {border-width:10px;}
.mdui-nav .li { display:inline-block;width:auto;height:36px;line-height:36px;text-align:center;font-weight:bold;cursor:pointer;border-bottom:3px solid #fff;margin:0 10px;font-size:16px;}
.mdui-nav .li.active { color:#E94D8A;border-bottom:3px solid #E94D8A;}
.mdui-nav::-webkit-scrollbar{display:none;}
.mdui-nav .mdui-tab{box-sizing:border-box;min-height:initial;height:auto;padding-top:2px;-webkit-overflow-scrolling:touch;position:absolute;overflow-x:visible;overflow-y:visible;}
.mdui-nav .mdui-tab::-webkit-scrollbar{display:none}
.mdui-nav .mdui-tab a{/* margin-right:12px;*/text-transform:initial;padding:0 6px;min-width:auto;width:auto;min-height:initial;color:#363636;opacity:1;height:30px;cursor:pointer;font-size:16px;font-weight:bold;display:inline-block;flex:none;line-height:27px;padding:0 10px;} 
/* .mdui-nav .mdui-tab a:first-child{margin-left:12px;} */
.mdui-nav .mdui-tab a.mdui-tab-active{color:#E94D8A;position:relative;}
.mdui-nav .mdui-tab a.mdui-tab-active::after{display:block;background:#E94D8A;height:3px;border-radius:10px;content:'';width: 100%;/*position:absolute;*/bottom:0;}
.mdui-tab-flex{height:auto;background:#FAFAFA;position:relative;display:none;}
.mdui-tab-flex.show{display:block!important;}
.mdui-tab-flex.hide{display:none!important;}
.mdui-tab-flex .mdui-tab-box{width:100%;display:flex;min-height:initial;max-height:initial;justify-content:center;align-items:center;}
.mdui-tab-flex .arrow-down{border-radius:0;min-width:36px;padding:0;color:#7B7B7B;display:flex;align-items:center;}
.mdui-tab-flex .arrow-down:active,.mdui-tab-flex .arrow-down:hover{background:transparent;}
.mdui-tab-flex .mdui-tab-mes{background:#FAFAFA;height:35px;line-height:35px;padding:0 4px;font-size:14px;color:#777;}
.mdui-tab-flex .mdui-tab-toggle{display:flex;align-items:center;justify-content:flex-end;color:#777;height:35px;line-height:35px;}
.mdui-tab-flex .mdui-tab-navport{width:100%;background:#fff;padding:15px 4%;box-sizing:border-box;flex-wrap:wrap}
.mdui-tab-flex .mdui-tab-navport a.nav{flex:initial;width:48%;opacity:1;border:1px solid #DFDEDB;border-radius:8px;min-height:initial;min-width:initial;display:flex;align-items:center;justify-content:flex-start;margin-bottom:15px;height:44px;cursor:pointer;padding:0 15px;flex-direction:row;text-align:center;}
.mdui-tab-flex .mdui-tab-navport a.nav.active { border:1px solid #E94D8A; }
.mdui-tab-flex .mdui-tab-navport a.nav > img{width:40px;height:40px;margin-right:6px;border-radius:50%!important;}
.mdui-tab-flex .mdui-tab-navport a.nav > i{width:40px;height:40px;background:#f4f4f4;margin-right:6px;font-size:0;}
.mdui-tab-flex .mdui-tab-navport a.nav > span{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}
.mdui-tab-flex .mdui-tab-navport a.nav.active > span { color:#E94D8A; }
.mdui-tab-flex .mdui-tab-navport a.nav:nth-child(2n+1){margin-right:2%;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-child(2n){margin-left:2%;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-last-child(1){margin-bottom:0;}
.mdui-tab-flex .mdui-tab-navport a.nav:nth-last-child(2){margin-bottom:0;}
.mdui-tab-flex .mdui-tab-navport a.nav-active{border-color:#E94D8A;color:#E94D8A;}
.mdui-loading{text-align:center;padding:8%;font-size:12px;color:#7B7B7B}
.mdui-loading i{font-size:16px;margin-left:4px;}
.mdui-depletion-box{display:none;}
.mdui-appbar-scroll-toolbar-hide.mdui-headroom-unpinned-toolbar{-webkit-transform:translate3d(0,-NaNpx,0)!important;transform:translate3d(0,-NaNpx,0)!important;}
.cate{display:block;overflow:hidden;padding:15px 0;}
.cate-div{float:left;width:16%;min-height:60px;margin:2%;}
.cate-img{width:auto;border-top-right-radius:44px;border-bottom-left-radius:40px;}
.cate-img img{width:80%;height:80%;margin-left:10%;border-radius:50%!important;}
.cate-title{text-align:center;}
.title-text{width:32%;font-size:10px;display:inline-block;color:#c75959;}
.title-text-div{height:20px;line-height:20px;background-color:#e8e8e8;}
.category_name{height:30px;line-height:30px;font-size:18px;font-weight:700;}
.more_list{float:right;font-size:12px;line-height:30px;color:#b2b2b2;}
.mdui-row,[class*="mdui-row-"]{margin-left:0;margin-right:0}
.flex { display: flex; }
.hide { display: none; }
.swiper-slide img { width:100%; }

.tags { width:100%;margin-top:10px; }
.tags h3 {height:3rem;line-height:3rem;font-size:16px;font-weight:bold;}
.tags button { border:1px solid #ddd;color:#666;padding:3px 10px;margin-right:5px;margin-bottom:5px;height:1.8rem; }
}
</style>