<template>
<div>
    <headerTopPc></headerTopPc>
    <div id="content-pc">
            <section class="breadcrumb-area">
                <div class="breadcrumb-text">
                    <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>訂單詳情</span>
                </div>
            </section>
        </div>
    <div class="orderDetail view-bottom" style="background: rgba(0, 0, 0, 0.6); display: block;">
        <div class="view-bottom-box view-bottom-box-big view-move-in">
            <div id="content-mobile" class="view-bottom-head">
                <button class="close" @click="utils.goBack()">
                    <img src="../assets/images/icon-close2.png" class="close-pub">
                </button>
                <span>訂單詳情</span>
                <button class="success" style="visibility: hidden;"></button>
            </div>
            <div class="view-bottom-content order-content havfooter">
                <div class="mdui-address card">
                    <span>{{ordersInfo.address.realname}}</span>
                    <span>{{ordersInfo.address.mobile}}</span>
                    <span>{{ordersInfo.address.addr}}</span>
                </div>
                <div class="order-contains">
                    <a href="javascript:;" v-for="item in ordersInfo.goods" @click="utils.url('detail',{id:item.goods_id})">
                        <div class="order-item">
                            <img :src="item.goods_images">
                            <div class="order-item-details">
                                <div class="top">
                                    <span class="title">{{item.goods_name}}</span>
                                    <span class="sku">{{item.sku_text}}</span>
                                </div>
                                <div class="bottom">
                                    <span class="mdui-float-left">NT${{item.sale_price}}</span>
                                    <span class="mdui-float-right" style="color:#979797;font-size: 12px;">x {{item.number}}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="order-price">
                    <ul class="mdui-list">
                        <li class="mdui-list-item">
                            <span class="orderProCount">共{{ordersInfo.goods_number}}件商品，原價合計</span>
                            <div class="orderPay">${{ordersInfo.goods_total}}</div>
                        </li>
                        <li class="mdui-list-item">
                            <span class="label">運費</span>
                            <div class="orderExpressCount">+ ${{ordersInfo.freight}}</div>
                        </li>
                        <li class="mdui-list-item" v-if="ordersInfo.discount > 0">
                            <span class="label">滿{{ordersInfo.rebate_number}}件{{ordersInfo.rebate / 10}}折</span>
                            <div class="orderExpressCount">- ${{ordersInfo.discount}}</div>
                        </li>
                        <li class="mdui-list-item" v-if="ordersInfo.payment_discount > 0">
                            <span class="label">線上付款</span>
                            <div class="orderExpressCount">- ${{ordersInfo.payment_discount}}</div>
                        </li>
                        <li class="mdui-list-item" v-if="ordersInfo.coupon_money > 0">
                            <span class="label">折價劵優惠</span>
                            <div class="orderExpressCount">- ${{ordersInfo.coupon_money}}</div>
                        </li>
                        <li class="mdui-list-item order-pay">
                            <span class="label">實際應付</span>
                            <div><i>NT$</i>{{ordersInfo.orders_payable}}</div>
                        </li>
                    </ul>
                </div>
                <div class="order-price">
                    <ul class="mdui-list">
                        <li class="mdui-list-item">
                            <span>下單時間</span>
                            <div>{{ordersInfo.create_time}}</div>
                        </li>
                        <li class="mdui-list-item">
                            <span>訂單編號</span>
                            <div>{{ordersInfo.out_trade_no}}</div>
                        </li>
                        <li class="mdui-list-item" v-if="ordersInfo.address.express_no">
                            <span>運單編號</span>
                            <div>{{ordersInfo.address.express_no}}<button class="mdui-btn-copy" v-clipboard:copy="ordersInfo.address.express_no" v-clipboard:success="copy">複製</button></div>
                        </li>
                        <li class="mdui-list-item" v-if="ordersInfo.address.express_no">
                            <span>運單查詢網站</span>
                            <div><a href="http://4128777.tw/Search.php" class="mdui-btn-copy">便利帶</a> <a href="https://eservice.7-11.com.tw/E-Tracking/search.aspx" class="mdui-btn-copy">7-11</a> <a href="https://www.famiport.com.tw/Web_Famiport/page/process.aspx" class="mdui-btn-copy">全家</a><br/>
                            Tips:先複製運單編號，再點擊進入對應網站粘貼編號查詢</div>
                        </li>
                    <li class="mdui-list-item">
                        <span>付款方式</span>
                        <div>{{ordersInfo.payment_text}}</div>
                    </li>
                </ul>
            </div>

            <div class="order-service option card" v-if="ordersInfo.status == 1 && ordersInfo.payment == '2'" @click="pay(ordersInfo.out_trade_no)">
                <ul>
                    <li>
                        <span><img src="../assets/images/visa.png" width="20px" height="16px">
                        <img src="../assets/images/mastercard.png" width="20px" height="16px">
                        <img src="../assets/images/jcb.png" width="20px" height="16px">
						<img src="../assets/images/unionpay.png" width="20px" height="16px"></span>
                        <div>
                            <h4>信用卡付款</h4>
                        </div>
                        <div><i class="mdui-icon material-icons mdui-text-color-grey-500 array-right" style="font-size:20px;margin-right:15px;">></i></div>
                    </li>
                </ul>
            </div>

            
            <div class="order-price" v-if="ordersInfo.status == 1 && ordersInfo.payment == '3'">
                <ul class="mdui-list">
                    <li class="mdui-list-item">
                        您選擇了銀行轉賬，請匯款到下面的銀行帳戶，匯款後聯絡客服出示匯款憑據
                    </li>
                    <li class="mdui-list-item">
                        銀行名稱：<b>{{config.bank_name}}</b>
                    </li>
                    <li class="mdui-list-item">
                        銀行帳戶：<b>{{config.bank_no}}</b><button class="mdui-btn-copy" v-clipboard:copy="config.bank_no" v-clipboard:success="copy">複製</button>
                    </li>
                </ul>
            </div>
                <div class="order-service option card">
                    <ul>
                        <li>
                            <a :href="shop_service" class="customer_service">
                                <span><img src="../assets/images/icon-customer-service.png" width="28px" height="28px"></span>
                                <div>
                                    <h4>透過LINE聯絡客服</h4>
                                </div>
                                <div><i class="mdui-icon material-icons mdui-text-color-grey-500 array-right" style="font-size:20px;margin-right:15px;">></i></div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- <div class="view-bottom-footer">
                <button class="buyAgain">再次購買</button>
            </div> -->
        </div>
    </div>
    <loader :loader="loader"></loader>
    <div id="content-pc">
        <footmain></footmain>
    </div>
</div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import footmain from '../views/common/footmain.vue';
    import loader from '../views/common/loader.vue';
    export default {
        name: "orderinfo",
        components: {
            headerTopPc,footmain,loader
        },
        data() {
            return {
                ordersInfo:{
                    address:{}
                },
                shop_service:'',
                loader:true,
                config:{} //在這加載config
            }
        },
        methods: {

            pay(no){
                this.$Modal.confirm({
                    title: 'Tips',
                    content: '信用卡付款採用交易授權機制及簡訊驗證保證安全。進入信用卡付款頁面後，只需填寫：帶紅色*號的四項（信用卡號、有效期限、末3碼、手機號碼）即可',
                    okText: '去信用卡付款',
                    cancelText:'取消',
                    onOk: () => {
                        this.$Message.loading({
                            content: '正在載入付款頁面...',
                            duration: 1000000000
                        });

                        location.href = this.ordersInfo.pay_domain + '/lvpay?no=' + no
                    },
                    onCancel:()=>{
                        this.$Modal.remove();
                    }
                });
            },

            copy(e){
                this.$layer.msg('複製成功');
            }
        },
        watch: {},
        mounted(){
            this.api.get('orders/detailsByOutTradeNo',{no:this.$route.query.no}).then(res=>{
                this.ordersInfo = res.data
                this.loader = false
            })
            this.shop_service = this.utils.config('shop_service');
            this.config = this.utils.config()//在這設置config
             // 添加像素
            this.utils.addXiangsu()
        }
    }
</script>

<style scoped>
.view-bottom-box .view-bottom-content.package-return,.view-bottom-box .view-bottom-content.history-state,.view-bottom-box .view-bottom-content.test-phone,.view-bottom-box .view-bottom-content.history-select,.view-bottom-box .view-bottom-content.fill-reason,.view-bottom-box .view-bottom-content.post-form,.view-bottom-box .view-bottom-content.select-replace,.view-bottom-box .view-bottom-content.return-history{background-color:#f4f4f4;padding:0 10px}
.view-bottom {z-index:1;}
.examplePro .view-bottom-head,.exampleCard .view-bottom-head{box-shadow:1px 1px 1px #ededed;}
.examplePro .view-bottom-box .view-bottom-content,.exampleCard .view-bottom-box .view-bottom-content{padding:16px 4%;}
.view-bottom-head .close{margin-top:15px;}

.order-content,.package-content{background:#F4F4F4;border-top:1px solid #EDEDED}
@media (max-width:767px){
div.card:last-child{margin-bottom:40px;}
.order-content{font-size:12px;line-height:18px;color:#7B7B7B;padding:9px 4%;}
}
@media (min-width:768px){
.havfooter{margin-top:170px;}
div.card:last-child{margin-bottom:80px;}
.order-content{font-size:12px;line-height:18px;color:#7B7B7B;padding:9px 5%;}
}
@media (min-width:1200px) {
.havfooter{margin-top:120px;}
div.card:last-child{margin-bottom:40px;}
.order-content{font-size:12px;line-height:18px;color:#7B7B7B;padding:9px 20%;}
}
.order-content > div,.package-content > div{border-radius:8px;background:#fff;margin-top:10px;overflow:hidden;}
.order-content > div:last-child,.package-content > div:last-child{margin-bottom:80px;}
.order-contains{overflow-y:auto;}
.order-contains a{text-decoration:none;}
.order-contains .order-item{margin:14px 4%;position:relative}
.order-contains .order-item img{width:80px;height:80px;}
.order-contains .order-item .order-item-details{width:calc(100% - 90px);float:right}
.order-contains .order-item .order-item-details .top .tags{border:1px solid #7B7B7B;width:25px;background:#fff;border-radius:12px;font-size:12px;line-height:12px;color:#7B7B7B;margin-top:2%;padding:2px 8px;display:block}
.order-contains .order-item .order-item-details .top{font-size:0;}
.order-contains .order-item .order-item-details .title{overflow:hidden;text-overflow:ellipsis;display:block;width:100%;white-space:nowrap;font-size:14px;color:#363636}
.order-contains .order-item .order-item-details .sku{font-size:12px;color:#979797;display:block;margin-top:8px}
.order-contains .order-item .order-item-details .bottom{position:absolute;width:calc(100% - 90px);bottom:4px}
.order-contains .order-item .order-item-details .bottom span{font-size:14px;color:#E94D8A}
.order-contains .order-item .order-item-details .bottom .originalPrice{margin-left:2px;font-size:12px;color:#7B7B7B;text-decoration:line-through}

.mdui-appbar-with-toolbar{padding-top:37px;}
.mdui-list-item:hover{background:#fff;}
.mdui-custom-img{width:100%;max-height:140px}
.mdui-custom-input{background:#FFF;position:absolute;width:89.3%;top:calc(50% - 20px);left:5.3%;border-radius:2px}
.mdui-custom-input .keywords-input{outline:0;height:24px;line-height:24px;border:none;font-size:14px;box-sizing:border-box;margin-top:8px;padding:0 30px 0 10px;width:calc(100% - 60px);border-right:1px solid #DFDEDB;border-radius:0}
.mdui-custom-input .search-btn{height:40px;line-height:40px;width:60px;background:transparent;border:none;outline:none;cursor:pointer;color:#E94D8A;font-size:16px;min-width:initial}
.mdui-custom-input .search-btn[disabled]{color:rgba(180,39,45,.3)!important}
.mdui-custom-input .clear-btn{position:absolute;background:transparent;border:0;height:40px;right:62px;display:flex;align-items:center}
.mdui-custom-input .clear-btn:focus{outline:none}
.mdui-custom-input .clear-btn.hide{display:none;}
.mdui-custom-input .clear-btn img{height:16px;}
.mdui-custom-header{height:18px;line-height:18px;background:#FFF;padding:15px 2.7% 12px;font-size:18px;}
.mdui-custom-header .mdui-float-right{color:#E94D8A;text-decoration:none}
.cart-empty-to-buy{border:1px solid #E94D8A;width:100px;border-radius:2px;height:28px;line-height:14px;color:#E94D8A;margin-top:20px}
.mdui-custom .mdui-custom-content{display:flex;flex-wrap:wrap;background:#FFF;}
.mdui-custom .mdui-custom-content .mdui-pannel-content{width:29.7%;margin-left:2.7%;}
.mdui-custom .mdui-custom-content .mdui-pannel-content:nth-child(3n){margin-right:2.7%;}
.mdui-custom .mdui-product-card{margin-top:15px;box-sizing:border-box;cursor:pointer;text-decoration:none}
.mdui-custom .mdui-product-card .mdui-product-card-title{margin:0 auto;height:30px;line-height:30px;font-size:13px;color:#000;overflow:hidden;text-overflow:ellipsis;display:block;width:90%;white-space:nowrap}
.mdui-custom .mdui-product-card .mdui-product-card-subtitle{width:90%;margin:0 auto;padding-bottom:10px;}
.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price1{font-size:16px;color:#E94D8A;line-height:16px}
.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price1::before{font-size:12px;content:'$';line-height:16px}
.mdui-custom .mdui-product-card .mdui-product-card-subtitle .price2{padding-left:1px;font-size:12px;color:#777777;line-height:12px;text-decoration:line-through}
.mdui-custom-order{background:#FFF;margin-bottom:12px;margin:10px 2.8% 0;border-radius:8px;overflow:hidden;}
.mdui-custom-order .order-time{height:30px;line-height:30px;background:#FFF5FB;font-size:12px;color:#B19F7B;padding:0 4%}
.mdui-custom-order .order-header{line-height:30px;border-bottom:1px solid #EDEDED;color:#000;padding:12px 2.7%;display:flex;align-items:center;justify-content:space-between}
.mdui-custom-order .order-header > span{font-size:12px;color:#777777;text-align:right;line-height:12px;}
#searchResult .mdui-custom-order .order-header > span .material-icons{margin-right:-8px;margin-left:-6px}
.mdui-custom-order .order-header .order-code{line-height:14px;padding-bottom:8px;}
.mdui-custom-order .order-header .order-code .order-state{background:#E29EAD;color:#fff;display:inline-block;border-radius:14px;padding:3px 8px;margin-left:4px;font-size:12px;line-height:12px;vertical-align:text-bottom;}
.mdui-custom-order .order-header .order-code .mdui-btn{height:22px;min-width:initial;padding:0;width:40px;line-height:22px;font-size:12px;border:1px solid #EDEDED;border-radius:2px;color:#000;margin-left:6px;}
.mdui-custom-order .order-header .order-desc{font-size:12px;color:#bcbcbc;line-height:12px;}
.mdui-custom-order .order-header .order-desc span:first-child{margin-right:12px;}
.order-package{padding:0;}
.order-package .package-tit{width:62px;text-align:center;line-height:10px;}
.order-package .package-tit .package-name{font-size:10px;color:#000000;padding:3px 6px;background:#F4F4F4;border-radius:8px;word-break:keep-all;display:inline-block;}
.order-package .package-tit i{margin-top:6px;font-size:10px;display:block;font-style:normal;}
.order-package .mdui-list-item{padding:0;display:block;border-bottom:1px dashed #DDD;}
.order-package .mdui-list-item:last-child{border-bottom:0;margin-bottom:0;}
.order-package .mdui-list-item .mdui-list-item-flex{display:flex;align-items:center}
.order-package .package-detail{width:calc(100% - 64px);padding:12px 10px 12px 0;}
.order-package .package-detail .expressInfo span{display:block;}
.order-package .package-detail .expressInfo span:first-child{line-height:10px;font-size:10px;color:#777777;padding:8px 0 6px;}
.order-package .package-detail .expressInfo span:last-child{font-size:12px;color:#007AFF;line-height:16px;}
.order-package .btnBox{margin-bottom:10px;padding-left:60px;}
.order-package .btnBox button{background:#FFFFFF;border:1px solid #bcbcbc;border-radius:4px;padding:6px 10px;font-size:12px;color:#000000;line-height:12px;margin-right:3px}
.order-package .btnBox button:hover{outline:none}
.skuImg{overflow-x:auto;width:100%;overflow-y:hidden;}
.skuImg ul{display:flex;align-items:center;justify-content:flex-start;list-style:none;padding-left:0;margin:0;}
.skuImg ul li{margin-right:6px;width:60px;height:60px;min-width:60px;max-width:60px;position:relative;}
.skuImg ul li .bandage{position:absolute;right:3px;bottom:3px;background:rgba(0,0,0,.5);border-radius:8px;display:block;font-size:10px;color:#FFFFFF;padding:2px 5px;}
.skuImg ul li img{width:100%;height:100%;border-radius:4px}

.order-price{box-sizing:border-box;border-top:1px solid #EDEDED;padding:15px 4%;font-size:12px;color:#777;}
.order-price ul{padding:0;}
.order-price ul li{display:flex;padding:0;min-height:12px;line-height:12px;font-size:12px;margin-bottom:18px;}
.order-price ul li:after{height:auto;}
.order-price ul li:last-child{margin-bottom:0;}
.order-price ul li span{width:50%;}
.order-price ul li div{flex:1;text-align:right;}
.order-price ul li .mdui-btn-copy{background:#fff;color:#E94D8A;font-size:12px;border:0;line-height:12px;}
.order-price ul li .mdui-btn-copy:hover{outline:none}
.order-price ul li .orderDiscount::before{content:'-';font-size:18px;line-height:14px;}
.order-price ul li.order-pay{box-sizing:border-box;border-top:1px dashed #EDEDED;font-size:16px;color:#363636;display:flex;margin:4px 0 0;padding-top:10px;}
.order-price ul li.order-pay span{color:#000}
.order-price ul li.order-pay div{color:#E94D8A;text-align:right;display:flex;justify-content:flex-end}
.order-price ul li.order-pay div i{font-size:12px;color:#E94D8A;font-style:normal;margin-right:2px;}
.mdui-btn-copy{color:#E94D8A;padding:0 6px;}
.package-content .order-service a{display:flex;justify-content:space-between;align-items:center;padding:12px 16px;text-decoration:none;color:#000000;}
.package-content .order-service a img{width:28px;height:auto;margin-right:12px;}
.package-content .order-service a span{display:flex;align-items:center}
.package-price{display:flex;padding:15px 4%;font-size:16px;justify-content:space-between;align-items:center}
.package-price i{font-style:normal;font-size:12px;margin-right:2px;}
.package-price > div{color:#E94D8A;}
.expressScroll{overflow:auto;min-height:126px;max-height:376px;}
.expressInfos{list-style:none;padding:12px;}
.expressInfos li{color:#979797;display:flex;align-items:center;margin-bottom:24px;position:relative;}
.expressInfos li:last-child{margin-bottom:0;}
.expressInfos li:not(.showMore):after{content:'';display:block;border-left:1px solid #979797;min-height:60px;position:absolute;left:69px;bottom:-44px;}
.expressInfos li:last-child:after{display:none;}
.expressInfos li i{font-style:normal;}
.expressInfos li span{display:block;}
.expressInfos li span.time{width:56px;min-width:56px;text-align:center;font-size:12px;}
.expressInfos li span.time i{display:block;font-size:10px;margin-top:4px;}
.expressInfos li span.icon{margin-right:10px;min-width:27px;width:27px;height:27px;border-radius:50%;overflow:hidden;display:block;z-index:10;position:relative;}
.expressInfos li span.icon:not(.noPic){border:1px solid #BCBCBC;background:#fff;}
.expressInfos li span.icon img{width:27px;margin:0 auto;display:block;}
.expressInfos li span.noPic i{background:#BCBCBC;width:5px;height:5px;border-radius:50%;display:block;margin:11px auto;}
.expressInfos li span.detail{font-size:14px}
.expressInfos li:first-child span{color:#000}
.expressInfos li:first-child span.icon{border-color:#007AFF;background:#007AFF;}
.expressInfos li:first-child span.icon.noPic{background:transparent}
.expressInfos li:first-child span.icon > i{background:#007AFF;}
.expressInfos li:first-child span.detail{color:#007AFF}
.expressInfos li.hide{display:none;}
.expressInfos li.showMore{font-size:12px;justify-content:center}
.expressInfos li.showMore img{height:12px;}
.expressInfos.havHide li:nth-child(4)::after{display:none;}
.expressDetail .skuImg{padding:10px 4%;background:#FFF5FB;box-sizing:border-box}
.expressNone{text-align:center;color:#777;font-size:14px;padding-top:38px;}
.expressNone span{display:block;margin-bottom:12px;}
.expressNone a{display:block;text-decoration:none;color:#E94D8A;}
.productDetail .package-price{padding:15px 3%;background:#FFF5FB;color:#000000;line-height:16px;font-size:16px;}
.productDetail .package-price span i{margin-left:6px;}
.productDetail .package-price span.price{color:#E94D8A;}
.productDetail .package-price span.price:before{content:'$';font-size:12px;line-height:12px;}
.mdui-call{background:#FFF;display:none;}
.mdui-call ul{list-style:none;padding:0 6px;margin:0;display:flex;}
.mdui-call ul li{text-align:center;flex:1;font-size:14px;margin:0 6px 10px;background:#F4F4F4;border-radius:6px;display:block;padding-bottom:10px;}
.mdui-call ul a{text-decoration:none;color:#363636;}
.mdui-call ul img{display:block;margin:15px auto 10px;height:40px;}
.mdui-call ul span{display:block;line-height:35px;}
.mdui-call ul span span{display:block;line-height:14px;}
.mdui-call .mdui-call-link ul{margin:0 12px 10px;background:#F4F4F4;border-radius:6px;}
.mdui-call .mdui-call-link ul li{margin:15px 0;padding:0;border-right:1px solid #BCBCBC;border-radius:0}
.mdui-call .mdui-call-link ul li:last-child{border-right:0}
.stopReturn .tip-center-head{padding-top:3px;}
.stopReturn .tip-center-box{width:84%;min-height:100px;}
.stopReturn .tip-center-content{padding:15px;min-height:100px;}
.stopReturn .tip-center-content p{margin:0;margin-bottom:20px;line-height:20px;}
.stopReturn .tip-center-content .close{border-radius:4px;background:#E94D8A;font-size:14px;color:#fff;padding:11px 76px;border:0;margin:36px auto 9px;display:block;}
.card{margin:10px 0 0;border-radius:6px;overflow:hidden;background:#fff;}
.mdui-address{position:relative;box-sizing:border-box;height:75px;background:#FFFCEC url(../assets/images/border.jpg) bottom repeat-x;font-size:14px;color:#363636;padding:12px 4%}
.mdui-address span{display:inline-block;margin-right:12px;line-height:24px;font-weight:700;font-size:16px}
.mdui-address span:last-child{display:block;font-weight:500;font-size:12px}
.mdui-address .mdui-icon{position:absolute;top:23px;right:4%;font-size:24px;margin-right:-10px;color:#979797!important}
.order-content .mdui-address{background:#FFFCEC url(../assets/images/border.jpg) bottom repeat-x;height:auto;}

.history-select + div.view-bottom-footer > button:not(.sure),.fill-reason + div.view-bottom-footer > button:not(.sure),.post-form + div.view-bottom-footer > button:not(.sure),.select-replace + div.view-bottom-footer > button:not(.sure),.test-phone + div.view-bottom-footer > button:not(.sure){height:50px;border:0;background:#E94D8A;border-radius:0;color:#fff;margin-right:-4%;width:36%;font-size:14px;}
.history-select + div.view-bottom-footer > button:not(.sure):focus,.fill-reason + div.view-bottom-footer > button:not(.sure):focus,.post-form + div.view-bottom-footer > button:not(.sure):focus,.select-replace + div.view-bottom-footer > button:not(.sure):focus,.test-phone + div.view-bottom-footer > button:not(.sure):focus{outline:none}
.test-phone + div.view-bottom-footer{padding:0 4%;}
.fill-reason + div.view-bottom-footer,.select-replace + div.view-bottom-footer,.post-form + div.view-bottom-footer{padding:0;}
.fill-reason + div.view-bottom-footer > button:not(.sure),.select-replace + div.view-bottom-footer > button:not(.sure),.post-form + div.view-bottom-footer > button:not(.sure){margin-right:0;}
.fill-reason + div.view-bottom-footer > button.left,.select-replace + div.view-bottom-footer > button.left,.post-form + div.view-bottom-footer > button.left{float:left;color:#000000;background:#fff;margin-left:-4px;font-size:16px;width:auto;}
.option ul{margin:0;padding:0;list-style:none;}
.option ul li,.option ul li a{display:flex;justify-content:space-between;align-items:center;color:#000;text-decoration:none;width:100%}
.option ul li:last-child h4{line-height:56px;margin:0;}
.option ul li span{margin-left:18px;}
.option ul li img{margin-right:5px;}
.option ul li div{border-bottom:1px solid #EDEDED;height:56px;}
.option ul li:last-child div{border-bottom:0}
.option ul li div:nth-child(2){flex:1}
.option ul li div h4{font-size:14px;line-height:14px;margin:11px 0 6px;font-weight:normal;color:#000}
.option ul li div p{font-size:12px;line-height:14px;color:#777777;margin:6px 0 11px;}
.option ul li div i{margin:16px 0;}

.return-history .card{padding:0 0 0 12px;}
.return-history .card i{font-style:normal;line-height:24px;display:inline-block;}
.return-history .card i.material-icons{margin-right:-8px;margin-left:-6px}
.return-history .card ul{margin:10px 0;}
.return-history .card .order-header{padding-left:0;display:flex;align-items:center;}
.return-history .card .order-header .order-desc{color:#000;flex:1;padding:0 6px;}
.return-history .card .order-header > i{color:#fff;font-size:12px;padding:0 5px;border-radius:3px;line-height:16px;}
.history-state{padding-bottom:30px;}
.history-state .customer_service img,.history-state h3 img{width:20px;margin:0 6px 0 3px;}
.history-state h3{font-size:16px;line-height:20px;color:#000000;margin:6px 0 18px;display:flex;align-items:center;}
.history-state .card{padding:12px;margin:10px 0;}
.history-state .card:nth-last-child(2){background:#FFF url(../assets/images/border.jpg) top repeat-x;}
.history-state .time{font-size:14px;color:#777777;line-height:14px;margin-bottom:6px;display:block;}
.history-state .card p{font-size:14px;color:#000000;line-height:20px;margin:0;}
.history-state .order-contains .order-item{margin:10px 0;padding:10px;background:#FFF5FB;border-radius:4px;}
.history-state .order-contains .order-item .bottom{bottom:14px;width:calc(100% - 110px);}
.history-state .price{font-size:14px;color:#E94D8A;line-height:14px}
.history-state .price::before{font-size:14px;content:'$';line-height:14px;margin-left:6px;}
.history-state i{font-style:normal;font-size:12px;line-height:12px;color:#777777;margin:8px 0;display:block;}
.history-state i.material-icons{font-size:24px}
.history-state button{margin:16px 0 6px 0;color:#000;font-size:14px;border:1px solid #BCBCBC;display:block;background:transparent;border-radius:4px;padding:7px 12px;line-height:14px;}
.history-state a.customer_service{color:#000;text-decoration:none;width:100%;display:flex;align-items:center;justify-content:space-between;}
.history-state a.customer_service span{flex:1}
.history-state .card > img{width:100%;margin:10px 0;}
.test-phone .card{padding:15px;text-align:center;}
.test-phone h2{font-size:16px;line-height:16px;color:#000;margin:1px 0 0;font-weight:normal;}
.test-phone p{font-size:12px;line-height:12px;color:#777777;margin:8px 0 18px;}
.test-phone input{display:block;margin-bottom:10px;border:1px solid rgba(151,151,151,1);line-height:24px;border-radius:4px;text-align:center;width:100%;font-size:14px;box-sizing:border-box;padding:8px 0}
.mdui-cart-products{background:#FFFFFF;margin-bottom:10px;border-radius:6px;overflow:hidden}
.mdui-cart-products .mdui-list-item{padding:12px 4%;font-size:0;flex-wrap:wrap;}
.mdui-cart-products .mdui-list-item:after{height:0}
.mdui-cart-products .mdui-list-item.gift-item{padding-bottom:6px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark{display:block;width:100%;color:#BCBCBC;line-height:10px;margin-top:8px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark span{font-size:12px;display:block;transform:scale(0.8);}
.mdui-cart-products .mdui-list-item:hover{background-color:initial;}
.mdui-cart-products .mdui-list-item .check-icon,.mdui-cart-products .mdui-list-item .exchange-check{width:20px;height:20px;margin-right:15px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title{font-size:14px;color:#000;height:18px}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(242,143,45,.1);color:#F56207;margin-right:3px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-text{font-size:12px;color:#7B7B7B;height:12px;margin-top:6px}
.mdui-cart-products .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;vertical-align:middle;}
.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 125px);height:80px;position:relative;top:0px;float:right;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2{position:absolute;width:100%;bottom:0px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price{font-size:14px;color:#E94D8A;position:absolute;bottom:0;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price{font-size:12px;color:#7B7B7B;text-decoration:line-through;padding-left:2px}
.mdui-cart-products .mdui-list{padding:0px!important;}
.mdui-cart-products .mdui-list > .mdui-divider-inset{height:8px;margin:0px!important;background:#F4F4F4;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(180,39,45,.1);color:#E94D8A;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text{bottom:4px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .promotion-num{font-size:12px;color:#7B7B7B;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price{top:0px;}
.mdui-cart-products .mdui-cart-products-bottom{margin:0 0 0 4%;padding-right:4%;margin-left:calc(4% + 35px);height:40px;line-height:40px;border-top:1px solid #EDEDED;cursor:pointer;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right{color:#E94D8A;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon{color:#E94D8A;margin-top:-2px;margin-right:-10px;}
.mdui-numbox{display:inline-block;position:relative;width:100px;height:28px;border:1px solid #EDEDED;border-radius:2px;color:#363636;border-radius:4px;overflow:hidden;}
.mdui-numbox .mdui-numbox-btn i{top:50%;left:50%;margin-left:-8px;margin-top:-8px;position:absolute;}
.mdui-numbox .mdui-numbox-btn{width:28px;height:28px;border:none;background:#FFF;outline:none;position:absolute;top:0;cursor:pointer;}
.mdui-numbox .mdui-numbox-btn .mdui-icon{font-size:16px;color:#363636;}
.mdui-numbox .mdui-numbox-input{font-size:14px;width:100%;height:100%;margin:0;overflow:hidden;text-align:center;text-overflow:ellipsis;word-break:normal;border:none;outline:none;padding:0;text-align:center;}
.mdui-numbox .mdui-numbox-btn-minus{left:0;}
.mdui-numbox .mdui-numbox-btn-plus{right:0;}
.mdui-numbox .mdui-numbox-btn.no-click .mdui-icon{color:#DFDEDB}
.history-select h3{font-size:16px;color:#000;margin:16px 0;text-align:center;font-weight:normal;}
.history-select + div{display:flex;align-items:center;justify-content:space-between;}
.history-select + div .info{flex:1;text-align:left;padding:0;color:#AD2728;font-size:12px;line-height:12px;padding:0;}
.history-select .order-contains h3{font-size:12px;color:#777777;margin:12px 0;text-align:center;font-weight:normal;}
.history-select .order-contains .order-item{margin:10px 12px;padding:10px;background:#FFF5FB;}
.history-select .order-contains .order-item .order-item-details .bottom{width:calc(100% - 110px);bottom:14px;}
.fill-reason .order-contains{overflow:initial;padding:14px 12px;overflow:hidden;}
.fill-reason .order-contains .order-item{margin:0;padding:10px;background:#FFF5FB;border-radius:4px;}
.fill-reason .order-contains .order-item .bottom{width:calc(100% - 110px);bottom:14px;}
.fill-reason .mdui-select{display:block;margin-top:15px;border:1px solid #979797;border-radius:4px;font-size:14px;padding:12px 0;background-position:calc(100% - 12px) center;}
.fill-reason .mdui-select-menu-item[selected]{color:#000}
.fill-reason .mdui-select-menu-item:first-child{color:#777}
.fill-reason .mdui-select-selected{padding:0 12px}
.fill-reason textarea{margin-top:10px;border:1px solid #979797;border-radius:4px;font-size:14px;padding:12px;height:105px!important;overflow-y:auto;}
.picBox ul{list-style:none;padding-left:0;margin-bottom:0;}
.picBox ul li{width:80px;height:80px;border-radius:4px;margin:0 20px 20px 0;display:inline-block;position:relative;vertical-align:middle;}
.picBox ul li img{width:80px;height:80px;border-radius:4px!important;}
.picBox ul li.btn{display:inline-flex;align-items:center;border:1px dashed #979797;flex-direction:column;justify-content:center;}
.picBox ul li span{display:block;color:#777777;font-size:24px;}
.picBox ul li span i{font-size:36px;}
.picBox ul li span.del{width:20px;height:20px;background:#F12C20;border-radius:50%;position:absolute;top:-10px;right:-10px;font-size:0;}
.picBox ul li span.del i{font-size:16px;line-height:20px;margin:0 2px;color:#fff;transform:rotateZ(45deg);}
.picBox ul li p{display:block;color:#BCBCBC;font-size:12px;margin:4px 0 0 0;}
.post-form h3{font-size:16px;color:#000;margin:16px 0;text-align:center;font-weight:normal;}
.post-form input{margin-top:10px;border:1px solid #979797;border-radius:4px;font-size:14px;padding:12px;display:block;width:calc(100% - 24px);}
.post-form .card{padding:12px}
.select-replace h3{font-size:16px;color:#000;margin:16px 0;text-align:center;font-weight:normal;}
.select-replace .mdui-cart-product-sku-info{width:calc(100% - 90px);}
.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text{padding:10px;background:#F4F4F4;color:#000;margin-top:6px;display:flex;align-items:center;justify-content:space-between;border-radius:4px;}
.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text i{color:#979797;font-size:20px;margin-right:-4px;}
.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text2{display:flex;justify-content:space-between;font-size:12px;color:#777777;}
.select-replace .mdui-cart-products .mdui-list-item .mdui-list-item-text2 .mdui-list-item-text-price{position:initial;bottom:auto;}

.returnTip{background:#FFF5FB;box-shadow:inset 0px 1px 0px 0px rgba(165,150,114,0.2);border-radius:4px;margin:0 4%;padding:0 10px;position:relative;}
.returnTip::before{content:"";display:block;position:absolute;border:8px solid transparent;border-bottom:8px solid #FFF5FB;top:-15px;z-index:1;}
.returnTip::after{content:"";display:block;position:absolute;border:8px solid transparent;border-bottom:8px solid rgba(165,150,114,0.2);top:-16px;}
.returnTip ul{list-style:none;padding-left:0;padding:4px 0;}
.returnTip ul li{line-height:20px;font-size:12px;color:#E72B0E;display:flex;align-items:center;justify-content:space-between;}
.returnTip ul li i{font-size:20px;margin-right:-8px;}
.example-btn{float:right;color:#007AFF;}
.example-btn i{height:16px;margin-top:-6px;font-size:20px;margin-right:-6px;margin-left:-4px;}
.example-box{padding-bottom:40px;}
.example-box p{height:14px;font-size:14px;line-height:14px;padding-bottom:8px;margin:0;}
.example-box img{border-radius:8px!important;display:block;margin-bottom:24px;width:100%;}
.picPlaceholder{background:rgba(190,190,190,0.5);color:#979797;border:#fff 1px solid;text-align:center;line-height:80px;}
@media screen and (max-width:320px){
	.sm-hide{display:none!important;}
}

/* 新增订单取消弹框 */
.card-li{display:flex;display:-webkit-flex;font-size:16px;margin-bottom:15px;height:22px;line-height:22px;}
.card-li:last-child{margin-bottom:0;}
.card-li span{display:inline-block;width:20px;height:20px;margin-right:10px;}

/* .card-li span label{
	width:20px;height:20px;
	display:block;
	border:1px solid #BCBCBC;
	border-radius:50%;
} */
.card-li i{font-style:normal;color:#222222;}
.card-li span input[type="radio"]{display:none;}
.card-li span label:before{content:'';width:20px;height:20px;display:block;border:1px solid #BCBCBC;border-radius:50%;}
.card-li span input[type="radio"][checked="checked"] + label:before{width:22px;height:22px;background:url('../assets/images/icon_check.png') no-repeat;border:0;background-size:cover;}
.mdui-cart-products{background:#FFFFFF;border-radius:6px;padding:5px 0px 0;margin-bottom:5px;}
.mdui-cart-products .mdui-list-item{padding:0;font-size:0;flex-wrap:wrap;margin-bottom:10px;border:0;}
.mdui-cart-products .mdui-list-item:last-child{margin-bottom:0;}
.mdui-cart-products .mdui-list-item:after{height:0}
.mdui-cart-products .mdui-list-item.gift-item{padding-bottom:6px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark{display:block;width:100%;color:#BCBCBC;line-height:10px;margin-top:8px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark span{font-size:12px;display:block;transform:scale(0.8);}
.mdui-cart-products .mdui-list-item .swiper-wrapper .swiper-slide{display:flex;align-items:center;}
.mdui-cart-products .mdui-list-item:hover{background-color:initial;}
.mdui-cart-products .mdui-list-item .check-icon,.mdui-cart-products .mdui-list-item .exchange-check{width:20px;height:20px;margin-right:15px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title{font-size:14px;color:#000;height:18px}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(242,143,45,.1);color:#F56207;margin-right:3px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-text{font-size:12px;color:#7B7B7B;height:20px;margin-top:8px}
.mdui-cart-products .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;vertical-align:middle;}
.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 90px);height:80px;position:relative;top:0px;float:right;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2{position:absolute;width:100%;bottom:0px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price{font-size:14px;color:#E94D8A;position:absolute;bottom:0;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price{font-size:12px;color:#7B7B7B;text-decoration:line-through;padding-left:2px}
.mdui-cart-products .delete-icon{width:22px;height:22px;position:relative;top:2px;margin-right:18px;}
.mdui-cart-products .mdui-list{padding:0px!important;}
.orderCancel .view-bottom-footer{display:inline-block;height:50px;background:#fff;position:absolute;left:0;bottom:0;width:100%;}
.orderCancel .view-bottom-footer span{display:inline-block;border:1px solid #E94D8A;color:#E94D8A;height:35px;line-height:35px;text-align:center;border-radius:100px;width:48%;}
.orderCancel .view-bottom-footer span:last-child{background:#E94D8A;color:#fff;margin-left:1%;}

/* 新增取消订单  手机号校验 */
.phoneCode,.cacelSuccess{position:fixed;width:100vw;height:100vh;top:0;left:0;background:rgba(0,0,0,.6);z-index:10000;box-sizing:border-box;}
.newPhoneNumber{width:80%;display:block;margin:20px auto 10px;outline:none;border:1px solid #BCBCBC;padding:0 10px;border-radius:5px;height:40px;font-size:14px;}
.phoneSure{width:40%;margin:20px auto 0;display:block;height:36px;line-height:36px;background:#E94D8A;border-radius:6px;color:#fff;border:0;font-size:16px;}

/* 发送验证码 */
.checkedCode{width:90%;margin:0 auto;}
.getCodeMsg{display:flex;display:-webkit-flex;width:100%;margin-bottom:15px;}
.getCodeMsg input{width:18%;border:0;height:30px;margin-right:2%;border-bottom:1px solid #BCBCBC;outline:none;text-align:center;color:#222222;font-weight:bold;font-size:18px;position:relative;-webkit-appearance:none;border-radius:0;}

/* .getCodeMsg input:before{
	content:'';
	width:100%;
	border-bottom:1px solid #BCBCBC;
	position:absolute;
	bottom:0;left:0;
} */
.getCodeMsg input:last-child{margin-right:0;}
.checkedCode p{color:#777777;font-size:12px;}
.checkedCode p i{font-size:16px;font-weight:500;font-style:normal;margin-left:8px;}
.checkedCode p span{color:#007AFF;}
.checkedCode p span.disabled{pointer-events:none;color:#777777;}
#phoneIn,#codeIn{display:none;}
.showResult{width:90%;margin:0 auto;color:#222222;font-size:14px;line-height:22px;margin-top:20px;}
.showResult span:first-child{color:#007AFF;}
.cacelSpan{background:#777777;color:#fff!important;border-radius:2px;padding:2px 5px;margin-right:11px;font-size:12px;}
</style>