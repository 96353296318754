<template>
<div>
<headerTopPc></headerTopPc>
    <div id="content-pc">
        <section class="breadcrumb-area">
            <div class="breadcrumb-text">
                <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>訂購完成</span>
            </div>
        </section>
    </div>
<div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
    <div class="mdui-toolbar-title">
        <div class="top-left-options" @click="utils.url('index',{v:1})">
            <a href="javascript:;" class="mdui-btn mdui-btn-icon toHome text-icon-options">
                <img src="../assets/images/icon-homepage.png">
            </a>
        </div>
        <div class="mdui-center mdui-valign point-progress point-progress-min">
            <ul>
                <li class="active"><i>1</i></li>
                <li class="active"></li>
                <li class="active"><i>2</i></li>
            </ul>
            <ul>
                <li class="active"><i>確認訂單</i></li>
                <li class="active"><i>訂購完成</i></li>
            </ul>
        </div>
        <div class="top-right-options">
            <a id="customer_service" class="text-icon-options" :href="config.shop_service">
                <img src="../assets/images/icon-customer-service.png">
                <span class="text-service">客服</span>
            </a>
        </div>
    </div>
</div>

<div class="success-main">
    <div class="mdui-order" v-if="order.payment == 2 && order.status == 1">
        <div class="order-tips-icon"><img src="../assets/images/icon-fail.png"></div>
        <div class="order-tips-text">付款失敗</div>
        <div class="order-tips-remarks">您可查看訂單詳情，在訂單頁重新信用卡付款</div>
    </div>
 
    <div class="mdui-order" v-else>
        <div class="order-tips-icon"><img src="../assets/images/icon-success.png"></div>
        <div class="order-tips-text">下單成功</div>
        <div class="order-tips-remarks">將從廠家出貨，預計10~12個工作天送到</div>
    </div>

    <div class="mdui-detail">
        <div class="picList">
            <div class="picBox" style="width:80px;display:inline-block;" v-for="item in goods" @click="utils.url('detail',{id:item.goods_id})">
                <a href="javascript:;">
                    <img :src="item.goods_images">
                </a>
            </div>
        </div>
    </div>
   
    <div class="mdui-card mdui-card-aggregation serviceCard">
        <table class="mdui-table">
            <tbody>
                <tr>
                    <td colspan="2">訂單號：{{order.out_trade_no}}</td>
                </tr>
                <tr>
                    <td style=" width:22%;">應付金額</td>
                    <td style=" width:78%;"><span style="font-size:8px;">NT$</span> {{order.orders_payable}}</td>
                </tr>
                <tr>
                    <td>收貨人</td>
                    <td>{{address.realname}}</td>
                </tr>
                <tr>
                    <td>電話號碼</td>
                    <td>{{address.mobile}}</td>
                </tr>
                <tr>
                  <td colspan="2" style="color:#E29EAD">提醒：<span v-if="order.express_type == 2 || order.express_type == 3">您選擇了超商取貨，貨物送到門市後會簡訊通知您取貨，所以請填寫手機號碼並確認號碼是否正確。</span><span v-else>如果電話號碼有錯，送貨員會聯絡不上您，所以請再次確認是否填寫正確。</span>如果需要更正，請點右上角聯絡客服LINE</td>
                </tr>
                <tr v-if="address.email">
                  <td>LINE ID</td>
                  <td>{{address.email}}</td>
                </tr>
                <tr>
                  <td>收貨地址</td>
                  <td>{{address.addr}}</td>
                </tr>
            </tbody>
        </table>
    </div>
	
    <div class="mdui-card mdui-card-aggregation serviceCard" v-if="order.payment == 3">
        <div>您選擇了銀行轉賬，請匯款到下面的銀行帳戶，匯款後聯絡客服出示匯款憑據</div>
        <table class="mdui-table">
            <tbody>
                <tr>
                    <td>銀行名稱</td>
                    <td>{{config.bank_name}}</td>
                </tr>
                <tr>
                    <td>銀行帳戶</td>
                    <td>{{config.bank_no}} <button class="mdui-btn mdui-color-default mdui-btn-copy copy-btn" v-clipboard:copy="config.bank_no" v-clipboard:success="copy">複製</button></td>
                </tr>
            </tbody>
        </table>
    </div>
 
    <div style="background:#fff;padding-bottom: 24px;margin-top: -2px">
        <a href="javascript:;" id="see-detail" class="mdui-btn mdui-btn-block" style="height: 38px; border-radius: 4px; background: #FFFFFF;border: 1px solid #E94D8A; width: 90%; margin: 0px auto; font-size:16px;color: #E94D8A;" @click="utils.url('query',{keys:order.out_trade_no})">查看訂單詳情</a>
    </div>
    <div class="mdui-card mdui-card-aggregation mdui-card-suggest-list" style="margin-bottom:52px;">
        <div class="mdui-card-header">
            <div class="mdui-card-header-title">熱賣商品</div>
        </div>
        <div class="mdui-card-content">
            <div class="mdui-card-product-content">
                <a href="javascript:;" @click="utils.url('detail',{id:item.id})" v-for="item in goodsList">
                    <div class="mdui-card mdui-card-product">
                        <div class="mdui-card-media">
                            <img :src="item.images">
                        </div>
                        <div class="mdui-card-content mdui-card-product-content">
                            <div class="mdui-card-product-title">{{item.title}}</div>
                            <div class="mdui-card-product-discountprice">{{item.sale_price}}</div>
                            <div class="mdui-card-product-orignalprice">{{item.market_price}}</div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
    <div id="content-pc">
    <footmain></footmain>
    </div>
</div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import footmain from '../views/common/footmain.vue';
    export default {
        name: "success",
        components:{headerTopPc,footmain},
        data() {
            return {
                config:{},
                goodsList:[],
                address:{},
                goods:[],
                order:{}
            }
        },
        methods: {
            copy(e){
                this.$layer.msg( '複製成功' );
            }
        },
        watch: {},
        created(){
            document.body.scrollTop = 0 //返回顶部
            document.documentElement.scrollTop = 0
        },
        mounted(){
            this.api.get('orders/detailByNo',{no:this.$route.query.no}).then(res=>{
                this.address = res.data.address;
                this.goods = res.data.goods;
                this.order = res.data.order;

                // 清空购物车
                if( this.order.payment != 2 || ( this.order.payment == 2 && this.order.status != 1 ) ){
                    this.utils.removeCart();
                }
                
                // 添加像素
                this.utils.addXiangsu( () => {
                    // 完成
                    fbq('track', 'Purchase', {
                        value: this.order.orders_payable,
                        currency: 'TWD'
                    });
                });
            })

            this.config = this.utils.config()

            this.api.get('goods/hot').then(res=>{
                this.goodsList = res.data
            })

        }
    }
</script>

<style scoped>
body,html {background: #f4f4f4!important;}
.mdui-order{width:100%;padding:12px 0px 6px;text-align:center;float:left;background:#fff;}
.mdui-order .order-tips-icon{width:100%;text-align:center;}
.mdui-order .order-tips-text{font-size:16px;font-weight:bold;text-align:center;padding:12px 0px;}
@media (max-width:767px){
.success-main{background:#fff;}
.mdui-order .order-tips-icon img{width:50px;height:50px;}
.mdui-order .order-tips-remarks{font-size:12px;color:#363636;}
}
@media (min-width:768px){
.success-main{width:750px;margin:10px auto 0;}
.mdui-order .order-tips-icon img{width:80px;height:80px;}
.mdui-order .order-tips-remarks{font-size:14px;color:#363636;}
}
@media (min-width:992px) {
.success-main{width:970px;margin:10px auto 0;}
.mdui-order .order-tips-icon img{width:80px;height:80px;}
.mdui-order .order-tips-remarks{font-size:14px;color:#363636;}
}
@media (min-width:1200px) {
.success-main{width:1170px;margin:10px auto 0;}
.mdui-order .order-tips-icon img{width:80px;height:80px;}
.mdui-order .order-tips-remarks{font-size:14px;color:#363636;}
}

.mdui-detail{width:100%;float:left;padding:0 2%;background:#fff;}
.mdui-detail .picList{overflow-x:auto;margin-top:16px}
.mdui-detail .picList::-webkit-scrollbar{display:none}
.mdui-detail .picList .picBox{display:flex;}
.mdui-detail .picList img{height:70px;height:70px;margin:0 10px 5px 0;}
.mdui-detail .picList img:last-child{margin-right:0}

.mdui-toolbar{display:flex;color:#7B7B7B;justify-content:space-between;align-items:flex-end;padding:0 8px;background:#FFF;box-shadow:0 1px 1px 0 rgba(237,237,237,1);height:40px!important;line-height:40px!important}
.mdui-toolbar .right,.mdui-toolbar .left{display:flex;height:100%;align-items:end;}
.mdui-toolbar .text-icon-options{position:relative;height:30px;width:36px;display:inline-block;margin-top:2px;margin-left:0}
.mdui-toolbar .text-icon-options>img{width:20px;height:auto;margin-left:-10px;position:absolute;left:50%;top:2px}
.mdui-toolbar img{width:17px;}
.mdui-toolbar .mdui-btn-icon{border-radius:0;font-size:12px}
.mdui-toolbar a>span{width:100%;text-align:center;margin:0 auto;display:block;font-size:10px;line-height:12px;color:#363636;position:absolute;bottom:-4px}
.mdui-toolbar .mdui-logo{margin:0 auto;display:flex;height:100%;align-items:center;justify-content:center}
.mdui-toolbar .mdui-logo img{width:auto;height:19px;border-radius:0!important;}
.mdui-card-aggregation{border-radius:0px;box-shadow:none;}
.mdui-card-aggregation .mdui-card-product-content > a{text-decoration:none;display:inline-block}
.mdui-card-aggregation > .mdui-card-content{padding:0px;}
.mdui-card-aggregation .mdui-card-media{padding-bottom:100%;}
.mdui-card-aggregation .mdui-card-media img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.mdui-card-aggregation .mdui-card-product-content{-webkit-overflow-scrolling:touch;box-sizing:border-box;padding:12px 4% 6px;white-space:nowrap;overflow-x:auto;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:120px;margin-right:10px;box-shadow:none;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-content{padding:8px 4px 0}
.mdui-card-aggregation .mdui-card-header{height:initial;padding:12px 4%;border-bottom:1px solid #EDEDED;}
.mdui-card-aggregation .mdui-card-header .mdui-card-header-title{margin-left:initial;opacity:1;color:#363636;}
.mdui-card-product-content{padding:5px;color:#363636;}
.mdui-card-product-content .mdui-card-product-title{overflow:hidden;text-overflow:ellipsis;width:100%;white-space:nowrap;display:block;line-height:24px;}
.mdui-card-product-content .mdui-card-product-discountprice{color:#E94D8A;line-height:16px;display:inline-block;font-size:16px;}
.mdui-card-product-content .mdui-card-product-discountprice::before{content:'NT$';font-size:12px;line-height:12px;}
.mdui-card-product-content .mdui-card-product-orignalprice{color:#7B7B7B;line-height:12px;font-size:12px;display:inline-block;text-decoration:line-through;}
.mdui-card-product-content .mdui-card-product-orignalprice::before{content:'NT$';font-size:12px;line-height:12px;}

/*適配iphone5*/
@media (max-width:320px){
	.mdui-card-aggregation > .mdui-card-product-content .mdui-card-product{width:110px;}
	.mdui-card-aggregation > .mdui-card-content{height:190px;}
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:100px;}
}
@media (min-width:750px){
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:180px;}
	.mdui-card-aggregation > .mdui-card-content{height:auto;}
}
.serviceCard{padding:10px;}
.serviceCard div{padding:4px 0 10px;}
.serviceCard table{box-shadow:none}
.serviceCard table tr:first-child{background:#FBFBFB;font-weight:bold;}
.serviceCard table tr td{padding:9px 4px!important;line-height:18px;border-right:1px solid rgba(0,0,0,.12);}
.serviceCard table tr td:first-child{max-width:60px;}
.serviceCard table tr td:last-child{border-right:0;}
.serviceCard table tr td button{color:#E94D8A;border:1px solid #E94D8A;border-radius:4px;min-width:auto;padding:4px 7px;line-height:13px;height:22px;font-size:13px;}
</style>