<template>
<div>
<headerTopPc></headerTopPc>
<div id="content-pc">
    <section class="breadcrumb-area">
        <div class="breadcrumb-text">
            <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>搜尋</span>
        </div>
    </section>
</div>
<div class="search-main">
    <div class="mdui-appbar mdui-appbar-fixed mdui-shadow-0 mdui-appbar-scroll-toolbar-hide mdui-headroom">
        <div class="mdui-toolbar-title">
            <div class="top-left-options">
                <a class="text-icon-options" href="javascript:void(0)" @click="cancel">
                    <img src="../assets/images/icon-back.png">
                </a>
            </div>
            <div class="top-center-options">
                <div class="search_box">
                    <div class="search_box_bg" :style="{opacity:opacity}">
                        <img src="../assets/images/search.png" srcset="">
                    </div>
                    <form action="javascript:;" onsubmit="return false;">
                        <input type="search" class="search_input" maxlength="20" placeholder="輸入商品或貨號關鍵字"  v-model="keywords" @keyup="submit">
                        <img src="../assets/images/input-del.png" class="search_input_del" v-if="keywords" @click="keywords = '';type = 0">
                    </form>
                </div>
            </div>
            <div class="top-right-options">
                <span @click="cancel">取消</span>
            </div>
        </div>

        <div class="mdui-toolbar-tab" v-if="type == 1">
            <i>排序方式：</i>
            <div>
                <span @click="setSort(2)" v-if="sort == 1" class="active">
                    價格低到高
                    <i class="mdui-icon material-icons">
                        <img src="../assets/images/s.png" style="width:18px;margin-top:2px;">
                    </i>
                </span>
                <span @click="setSort(1)" class="active" v-else>
                    價格高到低
                    <i class="mdui-icon material-icons">
                        <img src="../assets/images/x.png" style="width:18px;margin-top:2px;">
                    </i>
                </span>
            </div>
        </div>
    </div>

    <div class="mdui-page-container2 history_box" v-if="type == 0 && historyList.length > 0">
        <span>搜尋記錄</span>
        <img class="delete-icon" @click="deleteHistory()" src="../assets/images/icon-del.png">
        <div class="history_list">
            <span @click="openList(item)"" v-for="item in historyList">{{item}}</span>
        </div>
    </div>

    <div class="mdui-page-container2 hot_box" style="display: block;" v-if="type == 0 && hotList.length > 0">
        <span>大家都在找</span>
        <div class="hot_list">
            <span @click="openList(item.words)" v-for="(item,index) in hotList">
                <img src="../assets/images/star-10.png" v-show="index < 3">
                {{item.words}}
            </span>
        </div>
    </div>

    <div class="Goodslist">
    <div class="mdui-container-fluid mdui-page-container" v-if="type == 1 && goodsList.length > 0" style="background:none;">
        <div class="pro_box">
            <div class="mdui-row module-31" style="padding-bottom:10px;">
                <div class="mdui-card module-31-card" v-for="item in goodsList" @click="utils.url('detail',{id:item.id})">
                        <div class="mdui-card-media">
                            <img :src="item.images">
                        </div>
                        <div class="mdui-card-content module-31-content">
                            <div class="module-31-title"><div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}</div>
                            <div class="module-31-bottom">
                                <div class="module-31-price">
                                    <span class="module-31-discountprice">{{item.sale_price}}</span>
                                    <span class="module-31-sales"></span>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <div class="no-data"></div>
        </div>
    </div>
    </div>
    <div class="mdui-empty" style="text-align: center;" v-if="type == 1 && goodsList.length <= 0 ">
        <img style="width: 24%; display: block; margin: 0px auto; margin-top: 30%;" src="../assets/images/no-order.png">
        <div style="display: block; margin-top: 18px; color: #7B7B7B;">
            <span style="display: block;">非常抱歉</span>
            <span style="display: block;">沒有找到相關商品</span>
        </div>
    </div>

    <loader :loader="loader"></loader>
</div>
    <div id="content-pc">
        <footmain></footmain>
    </div>
</div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import vuescroll from '../components/vue-scroll.vue';
    import loader from '../views/common/loader.vue';
 import footmain from '../views/common/footmain.vue';
    export default {
        name: "search",
        components:{headerTopPc,vuescroll,loader,footmain},
        data() {
            return {
                type:0,
                keywords:'',
                opacity:1,
                historyList:[],
                hotList:[],
                goodsList:[],
                sort:1,
                page:1,
                isLast:false,
                limit:8,
                isLoading:false,
                loader:false
            }
        },
        methods: {
            openList(key){
                this.historyList = this.utils.search(key);
                this.keywords = key;
                this.submit({keyCode:13});
            },

            setSort(sort){
                this.page = 1;
                this.isLast = false;
                this.sort = sort;
                this.goodsList = [];
                this.submit({keyCode:13});
            },

            getList(done){

                if( this.isLast || this.isLoading ) {
                    return false;
                }
                this.isLoading = true
                this.loader = true

                this.api.get('search/lists',{
                    words:this.keywords,
                    page:this.page,
                    sort:this.sort,
                    limit:this.limit
                }).then(res => {
                    setTimeout(()=>{
                        this.loader = false
                        this.isLoading = false;
                    },600)
                    if( res.code != 200 ){
                        this.$layer.msg( res.message );
                        return false;
                    }

                    let data = res.data;
                    if(data.data.length > 0){
                        this.utils.merge(this.goodsList,data.data);
                        this.page++
                    }
                    if( data.last_page == 0 || data.current_page == data.last_page ){
                        this.isLast = true;
                    }
                })
            },

            submit(e){
                if( e.keyCode == 13){
                    if( !this.keywords ){
                        this.$layer.msg( '請輸入關鍵字' )
                        return false;
                    }

                    this.historyList = this.utils.search(this.keywords);
                    this.type = 1;
                    this.page = 1;
                    this.isLast = false;
                    this.goodsList = [];
                    this.getList();
                }
            },

            deleteHistory(){
                this.utils.search(null);
                this.historyList = [];
                this.$layer.msg( '刪除成功' );
            },

            cancel(){
                if( this.type == 1 ){
                    this.type = 0;
                    this.keywords = '';
                    this.goodsList = [];
                    return false;
                } else {
                    this.utils.goBack();
                }
            },
            scrollList(){
                if( this.utils.getFootHeight() < 150 ){
                    this.getList();
                }
            }
        },
        watch: {},
        mounted(){
            this.historyList = this.utils.search();
            this.api.get('search/words').then(res=>{
                this.hotList = res.data
            })

            window.addEventListener('scroll',this.scrollList,true)
             // 添加像素
            this.utils.addXiangsu()
        },
        destroyed() {
            console.log('destroyed')
            window.removeEventListener('scroll',this.scrollList)
            
        }
    }
</script>

<style scoped>
.top-left-options a.text-icon-options{margin-right: 0!important;color: #363636;}
.top-right-options span{padding: 0 4px 0 12px; display: inline-block;}
@media (max-width: 767px){
    .search-main{margin:0 0 50px 0;background:#fff;}
    .module-31{background:#f4f4f4;color:#000;padding:8px 4% 0;box-sizing:border-box;display:flex;justify-content:space-between;flex-wrap:wrap;}
    .module-31 .module-31-card{width:48.8%;margin-bottom:2%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;}
    .module-31 .module-31-card:nth-last-child(1){margin-left:0;}
}

@media (min-width:768px){
    .search-main{width:750px;margin:10px auto 0;}
    .module-31{width:750px;margin:0 auto;background:#f4f4f4;color:#000;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:992px) {
    .search-main{width:970px;margin:10px auto 0;}
    .module-31{width:970px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:1200px) {
    .search-main{width:1170px;margin:10px auto 0;}
    .module-31{width:1170px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

.module-31 .module-31-card > a{text-decoration:none;}
.module-31 .module-31-card .mdui-card-media{padding-bottom:100%;}
.module-31 .module-31-card .mdui-card-media > img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.module-31 .module-31-content{padding:8px;color:#000;}
.module-31 .module-31-content .module-31-title{height:36px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;width:100%;font-size:13px;line-height:18px;}
.module-31 .module-31-content .module-31-price{font-size: 0;line-height: normal;padding:6px 0 0;display:flex; justify-content: space-between;align-items: center;}
.module-31 .module-31-content .module-31-price > span:last-child{font-size:12px;color:#777777;line-height:12px;display:inline-block;}
.module-31 .module-31-content .module-31-discountprice{font-size:22px;color:#E94D8A;line-height:22px;margin-right:8px;}
.module-31 .module-31-content .module-31-discountprice:before{content:'NT$';margin-right:1px;font-size:12px;line-height:12px;}
.module-31 .module-31-content .module-31-sales{font-size: 12px;color: #777777; line-height: 22px; float:right;}
.module-31 .module-31-content .module-31-originalprice{text-decoration:line-through}
.module-31 .module-31-content .module-31-originalprice:before{content:'NT$';}
.module-31 .module-31-content .module-31-btn{display:flex;align-items:baseline;justify-content:space-between;}
.module-31 .module-31-content .module-31-btn button{background:#E94D8A;border-radius:3px;width:88px;height:26px;border:none;box-shadow:none;font-size:12px;color:#FFFFFF;line-height:12px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.module-31 .module-31-content .module-31-btn span{font-size:13px;color:#777777;text-align:right;line-height:13px;}
.module-31 .module-31-content .module-31-tag{height:16px;overflow:hidden;font-size:0;margin:5px 0;}
.module-31 .module-31-content .module-31-tag span{display:inline-block;padding:0 7px;border:none;border-radius:8px;font-size:12px;color:#E56214;line-height:16px;height:16px;background:#FCE9D5;box-sizing:border-box;margin-right:6px;}
.module-31 .module-31-content .module-31-tag span:last-child{margin-right:0;}
.module-31 .module-31-more{background:#FFF;color:#7B7B7B;padding:0;border:none!important}
.module-31 .module-31-more a{position:relative;color:#7b7b7b;display:block;padding-bottom:100%;border:1px solid #f4f4f4;}
.module-31 .module-31-more a .textBox{position:absolute;left:50%;margin-left:-25px;top:50%;margin-top:-28px;text-align:center;}
.module-31 .module-31-more a span{display:inline-block;}
.module-31 .module-31-more a span.circle{border:1px solid #7B7B7B;border-radius:50%;padding:3px;margin-top:12px;}

.mdui-toolbar-tab{font-size: 14px; line-height: 40px;color: #979797; height: 40px; padding: 0 15px;display: flex;background: #fff;}
.mdui-toolbar-tab i{font-style: normal;}
.mdui-toolbar-tab div{flex: 1; display: flex; flex-wrap: nowrap; }
.mdui-toolbar-tab div span{margin-right: 32px;}
.mdui-toolbar-tab span.active{font-style: normal;color: #000;}
.tab_hide{display: none;}

.mdui-number{font-size: 12px; text-align: center; padding: 16px 0 46px 0;color: #bcbcbc;}
.top-left-options a.text-icon-options{margin-right: 0!important;color: #363636;}
.top-right-options span{padding: 0 4px 0 12px; display: inline-block;}

.mdui-page-container{padding-left:0;padding-right:0;margin-left:0;margin-right:0;}
.mdui-page-container2{margin: 9px 0 10px 0; background: #fff; padding: 14px 15px 3px;}
.mdui-page-container2:last-child{margin-bottom: 30px;}
.mdui-page-container2 > span{color: #979797; font-size: 14px; line-height: 14px;}
.mdui-page-container2 > div{padding-top: 12px;display: flex; align-items:flex-start; flex-wrap: wrap;}
.mdui-page-container2 > div > span{padding: 7px 22px; color: #363636;font-size: 14px; line-height: 14px;margin-right: 14px; display: inline-flex;border: 1px solid #DFDEDB;margin-bottom: 15px; border-radius: 4px; align-items: center;}
.mdui-page-container2 > div > span img{ height: 14px; margin-right: 4px;}
.delete-icon{width:14px;height:14px;margin:26px 10px 0;float: right;}
</style>