<template>
<div>
    <div class="mengcheng"></div>
    <div class="baozhang">
        <div class="baozhang-content-text">
        	<ul class="de-service-list">
				<li class="clearfix">
					<span class="iconfont icon-huodao-pay"></span>
					<div class="de-service-text">
						<div class="de-service-title">貨到付款</div>
						<div>可宅配時付款、超商取貨付款，讓您購物更放心</div>
					</div>
				</li>
				<li class="clearfix">
					<span class="iconfont icon-card"></span>
					<div class="de-service-text">
						<div class="de-service-title">信用卡付款</div>
						<div>可使用信用卡進行線上安全快捷付款，付款時直接折減 <span class="c-red">NT$100</span></div>
					</div>
				</li>
				<li class="clearfix">
					<span class="iconfont icon-seven-days"></span>
					<div class="de-service-text">
						<div class="de-service-title">七日鑑賞期</div>
						<div>商品簽收7天內，存在品質問題聯絡客服退換貨</div>
					</div>
				</li>
				<li class="clearfix">
					<span class="iconfont icon-shinshopzhaipei"></span>
					<div class="de-service-text">
						<div class="de-service-title">運送方式</div>
						<div>可宅配到府（住家或公司）或超商取貨，支援：7-11超商、全家超商。全館免運費</div>
					</div>
				</li>
			</ul>
    	</div>
        <div class="baozhang-bottom" @click="submit">了解</div>
    </div>
</div>
    
</template>

<script>
    export default {
        name: "",
        data() {
            return {}
        },
        methods: {
            submit(){
                this.$emit('submit',false)
            }
        },
        watch: {},
        mounted(){}
    }
</script>

<style scoped>
#description img {
    max-width:100%;
}
.mengcheng {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 1000%;
    top: 0;
    left: 0;
    z-index: 3000;
    opacity: 0.5;
}

@media (min-width:600px) {
    .mdui-dialog {
        width: 60%
    }
    .mdui-dialog.mdui-noemail-dialog>.mdui-dialog-title+.mdui-dialog-content>p>span {
        display: inline-block;
        margin-left: 20px
    }
}


/*適配iphone5*/

@media (max-width:320px) {
    .mdui-cart-products .mdui-cart-product-sku-img {
        width: 64px;
        height: 64px;
    }
    .mdui-numbox {
        width: 85px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info {
        width: calc(100% - 109px);
        height: 64px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon {
        margin: 0px 4px;
    }
    .mdui-cart-products .swiper-slide.menu {
        height: 64px;
    }
    .mdui-card-aggregation>.mdui-card-product-content .mdui-card-product {
        width: 110px;
    }
    .mdui-card-aggregation>.mdui-card-content {
        height: 190px;
    }
    .mdui-card-aggregation .mdui-card-product-content .mdui-card-product {
        width: 100px;
    }
}

@media (min-width:750px) {
    .delete-confirm {
        width: 60%;
    }
    .mdui-card-aggregation .mdui-card-product-content .mdui-card-product {
        width: 180px;
    }
    .mdui-card-aggregation>.mdui-card-content {
        height: auto;
    }
}

@media (max-width:768px) {
.baozhang {
    background-color: white;
    top: 25%;
    z-index: 9999;
    width: 95%;
    height: 50%;
    position: fixed;
    max-width: 750px;
    margin-left: 2.5%;
}
}
@media (min-width:769px) {
.baozhang {
    background-color: white;
    top: 25%;
    z-index: 9999;
    width: 50%;
    height: 40%;
    position: fixed;
    max-width: 750px;
    margin-left: 25%;
}
}

.baozhang-top {
    width: 100%;
    height: 72px;
    text-align: center;
    line-height: 72px;
    font-size: 18px;
}

.baozhang-bottom {
    width: 100%;
    position: absolute;
    background: #e94d8a;
    color: #FFF;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    bottom: 0;
}

.baozhang-content {
    width: 100%;
    height: 64%;
}

.baozhang-content-text {
    font-size: 12px;
    color: #181818;
    padding: 10px 15px 10px 15px;
    line-height: 18px;
}

.de-service-list{padding:0;}
.de-service-list li{padding:8px 8px 8px 35px;border-bottom: 1px solid #e6e6e6;position: relative; list-style-type:none;}
.de-service-list .iconfont{display: block;font-size: 20px;margin-right:5px;line-height:20px;position: absolute;left: 8px;}
.de-service-text{line-height: 20px;}
.de-service-title{width: 100%;font-size: 14px;line-height: 20px;}
.de-service-text .c-red{color: #e94d8a;}
</style>