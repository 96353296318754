<template>
    <div>
        <headerTopPc></headerTopPc>
        <div id="content-pc">
            <section class="breadcrumb-area">
                <div class="breadcrumb-text">
                    <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>隱私條款</span>
                </div>
            </section>
        </div>
        <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
            <div class="mdui-toolbar-title">
                <div class="top-left-options" @click="utils.goBack()">
                    <a class="text-icon-options" href="javascript:void(0)">
                        <img src="../assets/images/icon-back.png">
                    </a>
                </div>
                <div class="mdui-center mdui-valign" style="font-size: 16px;color: #000">隱私條款</div>
                <div class="top-right-options">
                    <a class="text-icon-options" href="javascript:;" @click="utils.url('index',{v:1})">
                        <img src="../assets/images/icon-homepage.png">
                    </a>
                </div>
            </div>
        </div>
        <div class="mdui-container-fluid mine-container">
            <div class="mdui-typo mine-mdui-typo" v-html="content"></div>
        </div>
        <div id="content-pc">
            <footmain></footmain>
        </div>
    </div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import footmain from '../views/common/footmain.vue';
    export default {
        name: "PrivacyPolicy",
        components:{headerTopPc,footmain},
        data() {
            return {
                content:''
            }
        },
        methods: {},
        watch: {},
        created(){
            document.body.scrollTop = 0 //返回顶部
            document.documentElement.scrollTop = 0 //进入时默认顶部
        },
        mounted() {
            this.api.get('config/get',{name:'other_PrivacyPolicy'}).then( res => {
                this.content = res.data
            })
        }
    }
</script>
<style scoped>
@media (max-width:767px) {
    .mdui-container-fluid{margin:0 0 55px;background: #fff;}
}

@media (min-width:768px) {
    .mdui-container-fluid{width:750px;margin:10px auto 10px;background: #fff;}
}

@media (min-width:992px) {
    .mdui-container-fluid{width:970px;margin:10px auto 10px;background: #fff;}
}

@media (min-width:1200px) {
    .mdui-container-fluid{width:1170px;margin:10px auto 10px;background: #fff;}
}
</style>