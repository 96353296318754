<template>
    <div class="footBox">
        <div class="foot-close" @click="close">
            <img src="../../assets/images/icon-close2.png"></div>
        <div class="foot-header">瀏覽過商品({{list.length}})</div>
        <div class="foot-content">
            <div class="foot-content-scroll">
                <a href="javascript:;" v-for="item in list" @click="detail(item.id)">
                    <div class="mdui-card mdui-card-product">
                        <div class="mdui-card-media">
                            <img :src="item.images"></div>
                        <div class="mdui-card-content mdui-card-product-content">
                            <div class="mdui-card-product-title">{{item.title}}</div>
                            <div class="mdui-card-product-discountprice">{{item.sale_price}}</div>
                        </div>
                    </div>
                </a>

            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "footprint",
        data(){
            return {
                list:[]
            }
        },
        methods: {
            close(){
                this.$emit('close',true)
            },

            detail(id){
                this.$emit('close',true)
                this.utils.url('detail',{id:id});
            }
        },
        watch: {},
        mounted() {
            let ids = this.utils.footprint().toString();
            this.api.get('goods/records',{ids:ids}).then( res => {
                this.list = res.data
            })
        }
    }
</script>