<template>
<div>
    <div class="mengcheng"></div>
    <div class="mdui-color-white cartDialog confirmSelected sku">
        <div class="mdui-row mdui-p-x-2">
            <div class="mdui-col-xs-3 mdui-p-x-0">
                <img class="mdui-img-rounded" id="productSpecificationImg" :src="image?image:goods.images">
            </div>
            <div class="mdui-col-xs-7 mdui-p-x-0">
                <div class="mdui-typo-body-1 mdui-text-color-red-900">價格：<span id="productSpecificationCurrency"><span>NT$</span></span>
                <span id="productSpecificationPrice" v-if="sku.sale_price">{{sku.sale_price}}</span>
                <span id="productSpecificationPrice" v-else>
                    <span v-if="goods.min_sku_price == goods.max_sku_price">{{goods.max_sku_price}}</span>
                    <span v-else>{{goods.min_sku_price}} ~ ${{goods.max_sku_price}}</span>
                </span>
                </div>
                <div class="mdui-typo-body-2" style="color: #7B7B7B;">
                    <span id="productSpecificationName" class="productSpecificationName" v-show="selectStr">已選：<span>{{selectStr}}</span></span>
                </div>
            </div>
            <div class="mdui-col-xs-2 mdui-text-right" style="padding-right:0;" @click="close">
                <button class="mdui-btn mdui-btn-icon">
                    <img src="../../assets/images/icon-close2.png" class="close-pub">
                </button>
            </div>
        </div>
        <div class="product-prams-box" style="max-height: 300px; height: 285px;">
            <div class="mdui-row">
                <div class="mdui-p-x-2 product-prams" v-for="(attr,index) in goods.goodsattr">
                    <div class="mdui-typo-body-1">{{attr.name}}</div>
                    <button class="mdui-btn mdui-color-default mdui-btn-dense mdui-btn-small" :class="{active:attrIds[index] == item.attr_val_id}" v-for="(item,i) in attr.lists" @click="selectSku(index,item)">{{item.attr_value}}</button>
                </div>
            </div>
            <div class="mdui-row mdui-p-x-2 ">
                <div class="mdui-typo-body-1">數量</div>
                <div class="mdui-numbox" data-numbox-step="1" data-numbox-min="1" data-numbox-max="999">
                    <button class="mdui-numbox-btn mdui-numbox-btn-minus" @click="jian">
                        <i class="mdui-icon material-icons">-</i>
                    </button>
                    <input class="mdui-numbox-input" type="number" value="number" v-model="number">
                    <button class="mdui-numbox-btn mdui-numbox-btn-plus" @click="number++">
                        <i class="mdui-icon material-icons">+</i>
                    </button>
                </div>
            </div>
        </div>
        <button id="confirmSelected" class="mdui-btn mdui-btn-block mdui-m-t-1 mdui-color-red-900" @click="submit">確定</button>
    </div>
</div>
 
</template>

<script>
    export default {
        name: "skubuy",
        data() {
            return {
                number:1,
                attrIds:[],
                attrArr:[],
                sku:{},
                selectStr:'',
                image:'',
            }
        },
        props:['goods'],
        methods: {
            submit(){
                this.$emit('submit',true)
                if( !this.sku.sku_id ){
                    this.$layer.msg( '請選擇規格' );
                    return false;
                }
                let arr = {};
                arr.sku_id = this.sku.sku_id
                arr.number = this.number;
                arr.goods_id = this.goods.id
                arr.ac = 1;
                this.utils.cart(arr);
                this.utils.url('confirm');
                this.$emit('cancel');
            },

            jian(){
                if( this.number <= 1 ){
                    return false;
                }
                this.number--
            },

            selectSku(index,item){
                this.$set(this.attrIds,index,item.attr_val_id)
                this.$set(this.attrArr,index,item);
                let path = this.attrIds.toString();
                if( item.image ){
                    this.image = item.image
                } else {
                    this.image = this.goods.images
                }
                let str = '';
                for(let i = 0; i < this.attrArr.length; i++){
                    if( this.attrArr[i] ){
                        str += str == '' ? this.attrArr[i].attr_value : ' / ' + this.attrArr[i].attr_value
                    }
                }
                this.selectStr = str;
                for(let i = 0; i < this.goods.goodssku.length; i++){
                    if( this.goods.goodssku[i].attr_val_path == path){
                        this.sku = this.goods.goodssku[i];
                        if( this.sku.images ){
                            this.image = this.sku.images
                        } else {
                            this.image = this.goods.images
                        }
                    }
                }
            },

            close(){
                this.$emit('cancel',true)
            }

        },
        watch: {},
        mounted(){
            this.image = this.goods.images
            console.log(this.goods)
        }
    }
</script>
<style scoped>

.mengcheng {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 1000%;
    top: 0;
    left: 0;
    z-index: 3000;
    opacity: 0.5;
}


/* 公共 */

div::-webkit-scrollbar {
    display: none
}

body.mdui-bottom-nav-fixed .mdui-bottom-nav .mdui-btn {
    font-size: 14px
}

.mdui-typo-body-1,
.mdui-typo-body-1-opacity {
    margin-top: 8px;
    margin-bottom: 0
}


/* 头部 */

.mdui-tab a {
    padding: 0
}


/* 下部按钮 */

.mdui-bottom-nav [class*=mdui-col-xs-] {
    padding: 0
}

.mdui-bottom-nav .mdui-btn-block {
    border-radius: 0;
    height: 50px;
    min-width: inherit
}

.mdui-bottom-nav button {
    display: none
}

.mdui-bottom-nav button.hasPrice,
.mdui-bottom-nav button.hasPrice_normarl {
    line-height: 14px;
    padding: 0;
    font-size: 13px
}

.mdui-bottom-nav button.hasPrice>span,
.mdui-bottom-nav button.hasPrice_normarl>span {
    display: block;
    font-size: 16px;
    line-height: 16px;
    padding-bottom: 4px
}

.mdui-bottom-nav #depletionEnd-depletion {
    color: rgba(255, 255, 255, .5);
    background: #ACACAC;
}

/* 购物车 */

#shopping_cart {
    position: static;
    border: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    padding: 0 8px;
    flex-direction: row
}

#shopping_cart .cartIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
}

#shopping_cart .cartTime p {
    margin: 0;
    color: #777;
    text-align: right;
    overflow: hidden
}

#shopping_cart .cartTime p:first-child {
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 2px
}

#shopping_cart .cartTime p:last-child {
    font-size: 10px;
    line-height: 12px;
}

#shopping_cart .shoppingCart-num {
    border: 1px solid #fff;
    width: auto;
    min-width: 16px;
    box-sizing: border-box;
    padding: 0 2px;
    border-radius: 8px;
    top: calc(50% - 18px);
    right: calc(50% - 20px);
    font-size: 10px
}

/* sku选择 */
@media (max-width:768px) {
.cartDialog {
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    z-index: 6100;
    width: 100%;
    max-width: 768px;
    max-height: 100%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    overflow: hidden;
    box-sizing: border-box
}
}

@media (min-width:769px) {
.cartDialog {
    padding: 10px 0;
    position: fixed;
    top: 20%;
    left:20%;
    z-index: 6100;
    width: 60%;
    height:650px;
    max-height: 100%;
    border-radius: 6px;
    overflow: hidden;
    box-sizing: border-box;
}
}

.cartDialog.btnBox .btnBox {
    display: block
}

.cartDialog.btnBox #confirmSelected {
    display: none
}

.cartDialog.confirmSelected .btnBox {
    display: none
}

.cartDialog.confirmSelected #confirmSelected {
    display: block
}

.cartDialog .mdui-row {
    margin-right: 0;
    margin-left: 0;
    padding-right: 0;
}

.cartDialog .mdui-row img:not(.close-pub) {
    width: 85%;
    background: #F4F4F4
}

.cartDialog .mdui-row .mdui-btn-small {
    border: 1px solid #DFDEDB;
    min-width: 50px;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 0 10px;
    color: #363636;
    font-size: 12px!important;
    height: initial!important;
    line-height: initial!important;
    min-height: 28px;
}

.cartDialog .mdui-row .mdui-btn-small.noInventory {
    border: 1px dashed #DFDEDB;
    color: #979797
}

.cartDialog .mdui-row .mdui-typo-body-1 {
    line-height: 30px;
    color: #7b7b7b
}

.cartDialog .product-prams-box {
    overflow-y: auto;
    max-height: initial!important;
    -webkit-overflow-scrolling: auto;
}

.cartDialog .mdui-row .product-prams .mdui-btn.active {
    color: #E94D8A;
    border-color: #E94D8A
}

.cartDialog .originalPriceHtml {
    font-size: 12px;
    color: #bcbcbc;
    text-decoration: line-through;
}

.cartDialog .originalPriceHtml::before {
    margin-left: 6px;
    content: '$';
}

.cartDialog .btnBox {
    margin: 0 auto;
    margin-top: 16px;
    width: 92%;
    border-radius: 19px!important;
    overflow: hidden!important
}

.cartDialog .btnBox .mdui-col-xs-6 {
    padding: 0;
    font-size: 16px
}

.cartDialog .btnBox #buyNow-dialog {
    border-top-left-radius: 19px;
    border-bottom-left-radius: 19px
}

.cartDialog .btnBox #addToCart-dialog {
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px
}

.cartDialog .btnBox button {
    height: 38px;
    border-radius: 0
}

.cartDialog .mdui-btn.no-active {
    opacity: 0.7!important
}

#productSpecificationCurrency,
#productSpecificationPrice+span {
    font-size: 12px
}

#confirmSelected {
    width: 92%;
    height: 38px;
    border-radius: 19px;
    margin-top: 16px!important;
    margin: 0 auto 50px;
    font-size: 16px
}

#noInventoryBtn {
    width: 92%;
    height: 38px;
    border-radius: 19px;
    margin-top: 16px!important;
    margin: 0 auto;
    background: #ACACAC;
    color: #FFFFFF;
    font-size: 16px;
    display: none
}


/* 数量 */

.mdui-numbox+span {
    display: inline-block;
    font-size: 12px;
    color: #E29EAD;
    line-height: 12px;
    margin-left: 6px
}

.mdui-numbox {
    display: inline-block;
    position: relative;
    width: 120px;
    height: 28px;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    color: #363636
}

.mdui-numbox .mdui-numbox-btn {
    box-sizing: border-box;
    width: 28px;
    height: 28px;
    border: none;
    background: #FFF;
    outline: 0;
    position: absolute;
    top: 0;
    font-size: 18px
}

.mdui-numbox .mdui-numbox-btn i {
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute
}

.mdui-numbox .mdui-numbox-btn .mdui-icon {
    font-size: 16px;
    color: #363636
}

.mdui-numbox .mdui-numbox-input {
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: normal;
    border: none;
    outline: 0;
    padding: 0;
    text-align: center
}

.mdui-numbox .mdui-numbox-btn-minus {
    left: 0;
    border-right: 1px solid #EDEDED
}

.mdui-numbox .mdui-numbox-btn-plus {
    right: 0;
    border-left: 1px solid #EDEDED
}


/* 选择规格 */

#chooseSize .label {
    color: #7B7B7B
}

#chooseSize .mdui-list-item-content {
    text-align: right
}

#chooseSize .mdui-list-item-content span {
    float: left
}

/* iPhone5 */

@media(max-width:320px) {
    .mdui-card-aggregation>.mdui-card-content {
        height: 170px;
    }
}


/* 桌面级 */

@media(min-width:750px) {
    .mdui-card-aggregation>.mdui-card-content {
        height: 350px;
    }
}
</style>