<template>
    <div>
        <headerTopPc></headerTopPc>
        <div id="content-pc">
            <section class="breadcrumb-area">
                <div class="breadcrumb-text">
                    <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>購物車</span>
                </div>
            </section>
        </div>
        <div class="cart-main">
        <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
            <div class="mdui-toolbar-title">
                <div class="top-left-options">
                    <div class="view-bottom-head">
                        <span class="price_top">購物車</span>
                    </div>
                </div>
                <div class="top-right-options" @click="utils.goBack();" style="overflow:hidden;">
                    <span class="view-bottom-head-text" style="color:#E29EAD;font-size:16px;font-weight:500;margin-right: 5px;">繼續逛 </span>
                    <a class="text-icon-options" href="javascript:void(0)"> <img src="../assets/images/icon-close3.png" style="width: 20px;"></a>
                </div>
            </div>
        </div>

        <div class="mdui-cart-products-container" v-if="cartList.length > 0">
            <div class="mdui-cart-products">
                <ul class="mdui-list">
                    <li class="mdui-list-item"  v-for="(item,index) in cartList">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide content">
                                    <img src="../assets/images/icon_check.png" class="check-icon active" v-if="item.ac == 1" @click="selected(index)">
                                    <img src="../assets/images/icon_no_check.png" class="check-icon active" @click="selected(index)" v-else>
                                    <a href="javascript:;">
                                        <img class="mdui-cart-product-sku-img" :src="item.images">
                                    </a>
                                    <div class="mdui-cart-product-sku-info">
                                        <div class="mdui-list-item-title mdui-list-item-one-line">
                                           <div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}
                                        </div>
                                        <div class="mdui-list-item-text mdui-list-item-one-line">{{item.sku_text}}</div>
                                        <div class="mdui-list-item-text2">
                                            <span class="mdui-float-left mdui-list-item-text-price">
                                                <span :style="{'text-decoration':item.te_price ? 'line-through':'','color':item.te_price ? '#7B7B7B':'#E94D8A'}"><span style="font-size:8px;">NT$</span>{{item.sale_price}}</span>
                                            </span>
                                            <div class="mdui-float-right">
                                                <img class="delete-icon" src="../assets/images/icon-del.png" @click="deleteCartConfirm(index)">
                                                <div class="mdui-numbox">
                                                    <button class="mdui-numbox-btn mdui-numbox-btn-minus" @click="jian(index)">
                                                        <i class="mdui-icon material-icons">-</i>
                                                    </button>
                                                    <input class="mdui-numbox-input" type="number"  readonly="" v-model="item.number">
                                                    <button class="mdui-numbox-btn mdui-numbox-btn-plus" @click="jia(index)">
                                                        <i class="mdui-icon material-icons">+</i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="empty-carts" style="background:none;" v-if="isEmpty">
            <img class="cart-img-empty" src="../assets/images/icon-cart-empty.png">
            <span class="cart-tip-empty">您還沒有添加任何商品喲</span>
            <a href="javascript:;" @click="utils.url('index',{v:1})" class="mdui-btn cart-empty-to-buy">去逛逛</a>
        </div>
        <div class="mdui-bottom-nav">
            <div class="mdui-row mdui-color-white" v-if="money > 0">
                <div style="width: 200px; float: left;">
                    <div class="info" style="line-height: 1.15;">
                        <span>實付</span> 
                        <span style="font-size: 14px;">NT$</span><span class="allPrice">{{money}}</span> <span style="font-size: 10px; color:#666; text-decoration: line-through;">原價NT${{total}}</span>
                    </div> 
                    <div class="seeDetail" style="width: 200px; padding-left: 16px; color: rgb(123, 123, 123); display: block;">
                        <span id="seeDetail-text" style="color:#E29EAD; font-size: 12px;">{{cx}}</span>
                    </div>
                </div> 
                <button id="buyNow" class="mdui-btn mdui-btn-block mdui-color-red-900" style="display:block;" @click="goConfirm"><span></span><span>去結帳</span></button>
            </div>
            <div id="content-mobile" class="mdui-call-m" v-if="money > 0">
                <div class="mdui-call-text-m">“去結帳”填寫收貨資料,“繼續逛”可看其他商品</div>
            </div>
        </div>
        
        </div>

        <div id="content-pc">
        <footmain></footmain>
        </div>

        <deleteConfirm v-if="showDelete" :sku="skuData" @submit="deleteCart"></deleteConfirm>
        <loader :loader="loader"></loader>
    </div>

</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import deleteConfirm from '../views/common/delete.vue';
    import footmain from '../views/common/footmain.vue';
    import loader from '../views/common/loader.vue';

    export default {
        name: "cart",
        components:{headerTopPc,deleteConfirm,footmain,loader},
        data() {
            return {
                cx:'任意多件有折扣',
                showDelete:false,
                cart:[],
                cartList:[],
                skuData:{},
                money:0,
                total:0,
                zhekou:[],
                count:0,
                zk:{},
                loader:true,
                isEmpty:false
            }
        },
        methods: {
            jian(index){
                let sku = this.cartList[index]
                if( sku.number <= 1 ){
                    this.$layer.msg('請至少選1件');
                    return false;
                }
                sku.number--;
                this.$set(this.cartList,index,sku);
                this.utils.addCartNumber(sku.id,sku.number);
                this.getPrice()
            },

            jia(index){
                let sku = this.cartList[index];
                sku.number++;
                this.$set(this.cartList,index,sku);
                this.utils.addCartNumber(sku.id,sku.number);
                this.getPrice()
            },

            /**
             * 删除确认
             */
            deleteCartConfirm(index){
                this.skuData = this.cartList[index];
                this.showDelete = true
            },

            /**
             * 删除购物车
             */
            deleteCart(data){
                if(data.action == 'cancel'){
                    this.showDelete = false;
                    return false;
                }

                console.log(data)

                if( data.action == 'submit' ){
                    this.utils.deleteCart(data.id)
                    let arr = [];
                    for (let i in this.cartList) {
                        if (this.cartList[i].id != data.id) {
                            arr.push(this.cartList[i])
                        }
                    }
                    if( arr.length <= 0 ){
                        this.isEmpty = true
                    } else {
                        this.isEmpty = false
                    }
                    this.cartList = arr;
                    this.getPrice()
                    this.showDelete = false
                    this.$layer.msg('刪除成功');
                }
            },

            /**
             * 计算价格
             */
            getPrice(){

                let count = 0;
                let total = 0;
                let money = 0;
                let teMoney = 0;

                if( !this.cartList || this.cartList.length <= 0){
                    this.money = 0;
                    return false;
                }

                for(let i in this.cartList){
                    if( this.cartList[i].ac == 1 ){
                        if( this.cartList[i].is_te == 1 ){
                            total += this.cartList[i].sale_price * this.cartList[i].number;
                            teMoney += this.cartList[i].sale_price * this.cartList[i].number;
                        } else {
                            count += this.cartList[i].number;
                            total += this.cartList[i].sale_price * this.cartList[i].number;
                            money += this.cartList[i].sale_price * this.cartList[i].number
                        }
                    }
                }

                this.total = total;

                let dis = this.zhekou.discount
                this.cx = '任意多件有折扣';
                for(let index in dis){
                    if( count >= dis[index].count ){
                        money = parseFloat( money * ( parseInt(dis[index].percent) / 100 ) ).toFixed(2)
                        this.zk = dis[index]
                        console.log(this.zk)
                        let f = dis[index].percent % 10 == 0 ? parseInt( dis[index].percent / 10 ) : parseFloat( dis[index].percent / 10 ).toFixed(1);
                        this.cx = '滿'+dis[index].count+'件'+f+'折優惠'
                        break;
                    }
                }

                this.money = parseInt( money ) + teMoney ;

            },

            goConfirm(){
                if( this.money <= 0 ){
                    this.$layer.msg('請添加商品到購物車');
                    return false;
                }
                this.utils.url('confirm')
            },

            selected(index){
                let arr = this.cartList[index];
                arr.ac = arr.ac == 1 ? 0 : 1;
                this.utils.selectedCart(arr.id,arr.ac)
                this.$set(this.cartList,index,arr);
                this.getPrice()
            }
        },
        watch: {},
        mounted() {

            this.api.get('config/cuxiao',{}).then(res => {
                this.zhekou = res.data
                this.getPrice()
            })

            let cart = this.utils.cart();
            console.log(cart)
            if( cart ){
                let sku = [];
                for(let i = 0; i < cart.length; i++){
                    sku.push(cart[i].sku_id)
                }

                if( sku.length > 0 ){
                    this.api.get('goods/cartList',{sku:sku.toString()}).then(res=>{
                        let cartList = [];
                        for(let i = 0; i < cart.length; i++){
                            for(let index = 0; index < res.data.length ;index ++){
                                if( cart[i].sku_id == res.data[index].id ){
                                    res.data[index].number = cart[i].number;
                                    res.data[index].ac = cart[i].ac;
                                    cartList.push(res.data[index]);
                                }
                            }
                        }
                        if( cartList.length <= 0 ){
                            this.isEmpty = true;
                        }
                        this.cartList = cartList;
                        this.loader = false
                        this.getPrice()
                    })
                } else {
                    this.loader = false
                    this.isEmpty = true;
                }
            } else {
                this.loader = false
                this.isEmpty = true;
            }

            // 添加像素
            this.utils.addXiangsu( () => {
                // 添加购物车
                fbq('track', 'AddToCart');
            });

        }
    }
</script>

<style scoped>
body,html {background: #f4f4f4!important;}

@media (max-width:767px) {
.cart-main{width:100%;margin: 0 auto;}
}

@media (min-width:768px){
.cart-main{width:750px;margin:0 auto;}
}

@media (min-width:992px) {
.cart-main{width:970px;margin:0 auto;}
}
@media (min-width:1200px) {
.cart-main{width: 1170px;margin:0 auto;}
}

body.mdui-bottom-nav-fixed .mdui-bottom-nav {
    width:100%;
    height: 100px;
    display: block;
}

.mdui-bottom-nav {
    width:100%;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    z-index: 1;
    border-top: 1px solid #EDEDED;
}

.mdui-bottom-nav .info {
    color: #E94D8A;
    font-size: 26px;
    padding-left: 16px;
    width: 200px;
}

.mdui-bottom-nav .info span:first-child {
    font-size: 14px;
    font-weight: 400;
    color: #E94D8A;
}

.mdui-bottom-nav .info span:last-child {
    font-size: 12px;
    font-weight: 400;
    color: #E94D8A;
}

.mdui-bottom-nav #buyNow {
    height: 50px;
    width: 120px;
    float: right;
    border-radius: 0
}

.mdui-bottom-nav #buyNow span {
    display: block;
    font-size: 16px;
    height: 16px;
    line-height: 16px;
}

.mdui-bottom-nav #buyNow span:first-child {
    font-size: 12px;
    height: auto;
    line-height: 12px;
    padding-bottom: 3px;
}

.mdui-bottom-nav #buyNow.no-active {
    background-color: #EF9A9A !important
}


/*適配iphone5*/

@media (max-width:320px) {
    .mdui-cart-products .mdui-cart-product-sku-img {
        width: 90px;
        height: 90px
    }
    .mdui-cart-products .mdui-cart-product-sku-info {
        width: calc(100% - 135px);
        height: 90px
    }
}


body.mdui-bottom-nav-fixed {
    padding-bottom: 96px
}

.StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

/* 購物車 */

@media (max-width: 767px){
.mdui-cart-products-container {
    padding-bottom: 104px;
}
}
@media (min-width: 767px){
.mdui-cart-products-container {
    padding-bottom: 5px;
    margin-top: 10px;
}
}


.mdui-cart-products {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 15px 12px;
}

.mdui-cart-products .mdui-list-item {
    padding: 0;
    font-size: 0;
    flex-wrap: wrap;
    margin-bottom: 24px;
    border: 0;
}

.mdui-cart-products .mdui-list-item:last-child {
    margin-bottom: 0;
}

.mdui-cart-products .mdui-list-item:after {
    height: 0
}

.mdui-cart-products .mdui-list-item.gift-item {
    padding-bottom: 6px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-remark {
    display: block;
    width: 100%;
    color: #BCBCBC;
    line-height: 10px;
    margin-top: 8px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-remark span {
    font-size: 12px;
    display: block;
    transform: scale(0.8);
}

.mdui-cart-products .mdui-list-item .swiper-wrapper .swiper-slide {
    display: flex;
    align-items: center;
}

.mdui-cart-products .mdui-list-item:hover {
    background-color: initial;
}

.mdui-cart-products .mdui-list-item .check-icon,
.mdui-cart-products .mdui-list-item .exchange-check {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title {
    font-size: 14px;
    color: #000;
    height: 18px
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion {
    display: inline-block;
    border-radius: 8px;
    height: 16px;
    padding: 3px 4px;
    box-sizing: border-box;
    background: rgba(242, 143, 45, .1);
    color: #F56207;
    margin-right: 3px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span {
    font-size: 12px;
    display: block;
    text-align: center;
    transform: scale(0.8);
    line-height: 10px;
}

.mdui-cart-products .mdui-list-item .mdui-list-item-text {
    font-size: 12px;
    color: #7B7B7B;
    height: 12px;
    margin-top: 8px
}

.mdui-cart-products .mdui-cart-product-sku-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    vertical-align: middle;
}

.mdui-cart-products .mdui-cart-product-sku-info {
    width: calc(100% - 130px);
    height: 80px;
    position: relative;
    top: 0px;
    float: right;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 {
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price {
    font-size: 14px;
    color: #E94D8A;
    position: absolute;
    bottom: 0;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price {
    font-size: 12px;
    color: #7B7B7B;
    text-decoration: line-through;
    padding-left: 2px
}

.mdui-cart-products .delete-icon {
    width: 18px;
    height: 18px;
    position: relative;
    top: 4px;
    margin-right: 18px;
}

.mdui-cart-products .mdui-list {
    padding: 0px !important;
}

.mdui-cart-products .mdui-list>.mdui-divider-inset {
    height: 8px;
    margin: 0px !important;
    background: #F4F4F4;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags {
    display: inline-block;
    border-radius: 8px;
    height: 16px;
    padding: 3px 4px;
    box-sizing: border-box;
    background: rgba(180, 39, 45, .1);
    color: #E94D8A;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span {
    font-size: 12px;
    display: block;
    text-align: center;
    transform: scale(0.8);
    line-height: 10px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text {
    bottom: 4px;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .promotion-num {
    font-size: 12px;
    color: #7B7B7B;
}

.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price {
    top: 0px;
}

.mdui-cart-products .mdui-cart-products-bottom {
    margin: 0 0 0 4%;
    padding-right: 4%;
    margin-left: calc(4% + 35px);
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #EDEDED;
    cursor: pointer;
}

.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right {
    color: #E94D8A;
}

.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon {
    color: #E94D8A;
    margin-top: -2px;
    margin-right: -10px;
}

.mdui-cart-products .swiper-container {
    width: 100%;
}

.mdui-cart-products .swiper-slide.menu {
    font-size: 14px;
    min-width: 80px;
    width: 20%;
    max-width: 100px;
    background: #dd524d;
    color: #FFF;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
}


/* .mini-cart{z-index: 5099;}
.mini-cart .view-bottom-head{border-bottom: 1px solid #EDEDED;}
.mini-cart .view-bottom-head span{color: #E29EAD; font-size: 14px}
.mini-cart .view-bottom-head button{display: inline-flex; align-items: center;}

.mini-cart .view-bottom-footer{display: flex; align-items: center; padding: 0;box-shadow:0px -1px 0px 0px rgba(237,237,237,1);}
.mini-cart .info{flex: 1;padding: 0 16px; height: 50px;display: flex;flex-direction: column;justify-content: center;}
.mini-cart .info div:first-child{font-size: 12px; line-height: 12px; color: #E94D8A;}
.mini-cart .info div:last-child{font-size: 10px; line-height: 10px; color: #7B7B7B;padding-top: 4px;}
.mini-cart .info div span{font-size: 22px; line-height: 22px;}

.mini-cart #buyNow{height: 50px; width: 36%; border-radius: 0px;line-height: 50px; border: 0;}
.mini-cart #buyNow.no-active{background-color: #EF9A9A !important;}
.mini-cart #buyNow:active{opacity: 1;} */

.empty-carts {
    text-align: center;
    height: 260px;
    background: #FFF;
    box-sizing: border-box;
    margin-top: 48px;
    padding-top: 48px;
}

.empty-carts .cart-img-empty {
    display: block;
    margin: 0px auto;
    width: 24%;
    max-width: 100px;
}

.empty-carts .cart-tip-empty {
    display: block;
    color: #7B7B7B;
    padding: 16px 0px;
}

.empty-carts .cart-empty-to-buy {
    border: 1px solid #E94D8A;
    border-radius: 2px;
    color: #E94D8A;
    line-height: 14px;
    width: 110px;
    height: 30px;
    line-height: 30px;
    background: #FFF;
}

.empty-carts .mdui-page-bottom {
    background: #FFF;
    position: absolute;
    bottom: 0px;
    width: 100%;
}

.delete-confirm {
    width: 84%;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info {
    width: calc(100% - 90px);
    height: 90px;
    position: relative;
    top: 0px;
    float: right;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-title {
    font-size: 14px;
    color: #363636;
    margin-top: 12px;
}

.delete-confirm .mdui-dialog-content .mdui-cart-product-sku-info .mdui-list-item-text {
    font-size: 12px;
    color: #7B7B7B;
    margin-top: 12px;
}

.delete-confirm .mdui-dialog-title {
    text-align: center;
}

.delete-confirm .mdui-dialog-actions {
    padding: 0px;
    display: flex;
}

.delete-confirm .mdui-dialog-actions .mdui-btn {
    margin: 0px;
    padding: 0px;
    width: 50%;
    background: #FAFAFA;
    height: 48px;
    line-height: 48px;
    border-top: 1px solid #EDEDED;
    color: #7B7B7B;
    font-size: 16px;
}

.delete-confirm .mdui-dialog-actions .mdui-btn:first-child {
    border-right: 1px solid #EDEDED;
    color: #E94D8A;
}

.mdui-numbox {
    display: inline-block;
    position: relative;
    width: 100px;
    height: 28px;
    border: 1px solid #EDEDED;
    border-radius: 2px;
    color: #363636;
}

.mdui-numbox .mdui-numbox-btn i {
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -8px;
    position: absolute;
}

.mdui-numbox .mdui-numbox-btn {
    width: 28px;
    height: 28px;
    border: none;
    background: #FFF;
    outline: none;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.mdui-numbox .mdui-numbox-btn .mdui-icon {
    font-size: 16px;
    color: #363636;
}

.mdui-numbox .mdui-numbox-input {
    font-size: 14px;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    word-break: normal;
    border: none;
    outline: none;
    padding: 0;
    text-align: center;
}

.mdui-numbox .mdui-numbox-btn-minus {
    left: 0;
    border-right: 1px solid #EDEDED
}

.mdui-numbox .mdui-numbox-btn-plus {
    right: 0;
    border-left: 1px solid #EDEDED
}

@media (min-width:600px) {
    .mdui-dialog {
        width: 60%
    }
    .mdui-dialog.mdui-noemail-dialog>.mdui-dialog-title+.mdui-dialog-content>p>span {
        display: inline-block;
        margin-left: 20px
    }
}


/*適配iphone5*/

@media (max-width:320px) {
    .mdui-cart-products .mdui-cart-product-sku-img {
        width: 64px;
        height: 64px;
    }
    .mdui-numbox {
        width: 85px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info {
        width: calc(100% - 109px);
        height: 64px;
    }
    .mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon {
        margin: 0px 4px;
    }
    .mdui-cart-products .swiper-slide.menu {
        height: 64px;
    }
}

@media (min-width:750px) {
    .delete-confirm {
        width: 60%;
    }
    .mdui-card-aggregation .mdui-card-product-content .mdui-card-product {
        width: 180px;
    }
    .mdui-card-aggregation>.mdui-card-content {
        height: auto;
    }
}

.mdui-cart-promotion-container {
    background: #FFF5FB;
    padding: 8px 2% 8px 4%;
    display: none;
}

.mdui-cart-promotion-container a {
    color: #E29EAD;
    font-size: 14px;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mdui-overlay {
    background: transparent;
}

.mdui-overlay::after {
    content: '';
    width: 160px;
    height: 70px;
    top: 30%;
    left: 50%;
    margin-left: -80px;
    margin-top: 80px;
    border-radius: 10px;
    position: fixed;
    background: rgba(0, 0, 0, .7)
}

.top-right-options a.text-icon-options {
    margin-left: 0px !important;
}

.mdui-toolbar-title {
    height: 50px; line-height: 50px; padding: 0px 8px 0 16px;
}
.price_top{font-size: 16px; font-weight: 500;}

.mdui-numbox .mdui-numbox-input {
    background: none;
}

@media (max-width:768px) {
.mdui-call-m{
    width:100%;
}
}

@media (min-width: 769px){
.mdui-call-m{
    display: none;
}
}

.mdui-call-m {
    height:50px;
    color:#7b7b7b;
    background: #FFF;
    position: fixed;
    margin: 0;
    bottom:0;
    border-top: 1px solid #DFDEDB;
    z-index:999;
}
.mdui-call-m .mdui-call-text-m {
    text-align: center;
    line-height: 50px;
}
</style>