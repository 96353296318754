<template>
<div>
<div class="mdui-appbar mdui-appbar-fixed mdui-shadow-0">
	<a href="/search">
	<div class="mdui-toolbar-title">
		<div class="search_box">
			<div class="search_box_bg">
				<img src="../assets/images/search.png">
			</div>
		</div>
	</div>
    </a>
</div>
    <div class="mdui-container-fluid mdui-menu-left">
        <div class="navBox">
            <ul class="levelA ">
                <li :class="{active:index == categoryIndex}" v-for="(item,index) in categoryList" @click="getList(index)">
                    <a href="javascript:void(0)"><span>{{item.title}}</span></a>
                </li>
            </ul>
            <ul class="levelB ">
                <li>
                    <a href="javascript:;" @click="utils.goUrl('list',{id:categoryInfo.id})">
                        <div class="level_img_box">
                            <img :src="categoryInfo.images">
                        </div>
                        <span>全部商品</span>
                    </a>
                </li>
                <li v-for="(item,index) in subList">
                    <a href="javascript:;" @click="utils.goUrl('list',{id:categoryInfo.id,sid:item.id})">
                        <div class="level_img_box">
                            <img :src="item.images">
                        </div>
                        <span>{{item.title}}</span>
                    </a>
                </li>

                <div class="tags" v-if="tags.length > 0">
                    <h3 style="margin-bottom:.5rem;">按類型：</h3>
                    <a class="btn" @click="goLink(item)" v-for="item in tags">{{item}}</a>
                </div>
            </ul>
        </div>
    </div>
</div>

</template>

<script>

    import footprint from '../views/common/footprint.vue';
    export default {
        components:{ footprint },
        name: "category",
        data() {
            return {
                categoryList:[],
                categoryIndex:0,
                categoryInfo:{},
                subList:[],
                tags:[],
                showFootprint:false,
                config:{}
            };
        },
        methods: {
            getList(index){
                this.categoryIndex = index
                this.categoryInfo = this.categoryList[index]
                this.api.get('goods/getCategoryListById',{id:this.categoryInfo.id}).then(res => {
                    this.subList = res.data
                })

                this.api.get('goods/getTagsListByCateId',{id:this.categoryInfo.id}).then( res => {
                    this.tags = res.data
                })
            },

            goLink(tag){
                location.href = '/tags?key=' + tag
            }
        },
        watch: {},
        mounted(){
            this.api.get('goods/getAllGoodsCategory',{}).then(res => {
                this.categoryList = res.data
                this.categoryInfo = res.data[0]
                this.getList(0)
            })

            this.config = this.utils.config()
             // 添加像素
            this.utils.addXiangsu()
        },
    };
</script>

<style scoped>

/*
* @Author: BergTang
* @Date:   2020-06-17 07:37:20
 * @Last Modified by: bergtang
 * @Last Modified time: 2020-11-06 16:12:26
*/

body.mdui-bottom-nav-fixed {
    padding-bottom: 50px;
}

.mdui-menu-left {
    position: static;
    display: block;
    height: calc(100vh - 90px);
    padding: 0;
}

.mdui-menu-left .navBox {
    height: 100%;
}

.mdui-menu-left .levelB div {
    height: auto;
    display: block;
}

.mdui-menu-left .levelA,
.mdui-menu-left .levelB {
    overflow-x: hidden;
}

.tags {
    text-align: left;
    display: block;
    border-top: 1px solid #f2f2f2;
    margin-top: 30px;
    padding: 10px;
    width: auto;
}

.tags .btn {
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    text-decoration: none;
    color: #666;
    margin-right: 5px;
    display: inline-block;
    margin-bottom: 5px;
}

.tags .btn:hover {
    border: 1px solid #E94D8A;
    color: #E94D8A;
}
</style>
