<template>
<div id="content-pc">
<nav class="main-navigation">
    <div class="navbar-wrapper">
        <div class="navbar hidden-xs" role="navigation">
            <div class="navbar-header-wrapper">
                <div class="header-container">
                    <div class="navbar-header">
                        <div class="navbar-logo" @click="utils.url('index',{v:1})"><img src="../../assets/images/logo.jpg"></div>
                        <div class="mini-cart-wrap" @click="utils.goUrl('cart')">
                            <div class="btn-mini-cart">
                                <img src="../../assets/images/icon-cart-t.png">
                                <span class="cart-total" v-if="cartCount > 0">{{cartCount}}</span><br/>
                                購物車
                            </div>
                        </div>
                        <div class="navbar-tool"><a href="javascript:;" @click="utils.goUrl('query')"><img src="../../assets/images/supply-1.png"><br/>查詢訂單</a></div>
                        <div class="navbar-tool"><a href="javascript:void(0)" @click="showFootprint = true"><img src="../../assets/images/icon-footprint.png"><br/>瀏覽紀錄</a></div>
                        <div class="navbar-tool"><a href="/search"><img src="../../assets/images/icon-search.png"><br/>搜尋</a></div>
                    </div>
                </div>
            </div>

            <div class="navbar-menu-wrapper">
                <div class="header-container">
                    <div class="navbar-menu hidden-xs">
                        <ul class="text-primary-color" id="headerNav">
                            <li class="nav-dropdown home" :class="{'active':categoryId == ''}">
                                <a @click="utils.url('index',{v:1})">首頁</a>
                            </li>
                            <!--一級分類菜單s-->
                            <li class="nav-dropdown" :class="{active:categoryId == item.id}" v-for="(item,index) in goodsCategoryTop">
                                <a class="nav-dropdown-toggle" @click="utils.goUrl('list',{id:item.id})">{{item.title}}</a> <span id="content-caret" v-if="item.child.length > 0" class="caret"></span>
                                <!--二級分類菜單s-->
                                <ul class="nav-dropdown-menu" v-if="item.child.length > 0">
                                    <li v-for="cate in item.child">
                                        <a href="javascript:;" @click="utils.goUrl('list',{id:item.id,sid:cate.id})">{{cate.title}}</a>
                                    </li>
                                </ul>
                                <!--二級分類菜單e-->
                            </li>
                            <!--一級分類菜單e-->
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</nav>
<footprint v-if="showFootprint" @close="showFootprint = false"></footprint>
</div>

</template>
<script>
    var vm = null;
    import footprint from '../../views/common/footprint.vue';
    export default {
        components:{footprint},
        name: "headerTopPC",
        data() {
            return {
                goodsCategoryTop:[],
                showFootprint : false,
                categoryId:'',
                category:{},
                categorySub:{},
                tags:[],
                showSub:false,
                sid:0,
                cartCount:0,
                config:{},
                swiperOption:{
                    slidesPerView: 'auto',
                    centeredSlides: false,
                    freeMode: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    on:{
                        click(){
                            let id = vm.goodsCategoryTop[this.clickedIndex - 1].id;
                            vm.utils.url('list',{id:id});
                        }
                    }
                }
            }
        },
        methods: {

            goList(id,sid){
                if( sid ){
                    location.href = '/list?id=' + id + '&sid=' + sid ;
                } else {
                    location.href = '/list?id=' + id;
                }
            },

            goLink(key){
                location.href = '/tags?key=' + key
            }
        },
        watch: {
            $route(to,form){
                if( to.name == 'list' ){
                    this.categoryId = to.query.id
                    this.sid = to.query.sid
                    this.showSub = false
                    for(let i in this.goodsCategoryTop){
                        if( this.categoryId == this.goodsCategoryTop[i].id ){
                            this.category = this.goodsCategoryTop[i];

                            if( this.sid ){
                                for(let index in this.category.child ){
                                    if( this.sid == this.category.child[index].id ){
                                        this.categorySub = this.category.child[index]
                                    }
                                }
                            } else {
                                this.categorySub = {};
                            }

                            this.api.get('goods/getTagsListByCateId',{id:this.categoryId}).then(res=>{
                                this.tags = res.data
                            })
                            break;
                        }
                    }
                } else {
                    this.categoryId = ''
                    this.sid = ''
                }
            }
        },
        created(){
            vm = this;
        },
        mounted(){
            this.cartCount = this.utils.cartCount()

            /**
             * 产品一级分类
             */
            this.api.get('goods/getAllGoodsCategory',{}).then( res => {
                try {
                    this.goodsCategoryTop = res.data
                    if( this.categoryId ){
                        for(let i in this.goodsCategoryTop){
                            if( this.categoryId == this.goodsCategoryTop[i].id ){
                                this.category = this.goodsCategoryTop[i];
                                this.$emit('banner',this.goodsCategoryTop[i].banner);
                                this.$emit('summary',this.goodsCategoryTop[i].summary);
                                if( this.sid ){
                                    for(let index in this.category.child ){
                                        if( this.sid == this.category.child[index].id ){
                                            this.categorySub = this.category.child[index]
                                        }
                                    }
                                } else {
                                    this.categorySub = {};
                                }
                                break;
                            }
                        }
                    }
                } catch (err){
                    console.log(err)
                }
            })

            
            if( this.$route.query.id ){
                this.categoryId = this.$route.query.id
                this.sid = this.$route.query.sid
                this.showSub = false
                this.api.get('goods/getTagsListByCateId',{id:this.categoryId}).then(res=>{
                    this.tags = res.data
                })
            }

            this.config = this.utils.config()
        }
    }
</script>
<style scoped>
@media (min-width:768px) {
body .mdui-appbar-fixed{z-index:2001}
.header-container{position:relative}
.header-container>.navbar-header,.header-container-fluid>.navbar-header,.header-container>.navbar-collapse,.header-container-fluid>.navbar-collapse{margin-right:0px;margin-left:0px;}
.navbar{width:100%;padding:10px 1% 5px;border-bottom:1px solid #e0e0e0;margin-bottom:0px;position:fixed; background-color: #fff;z-index:100;top: 0;right: 0;left: 0}
.navbar-header{min-height:0px;max-width:100%;text-align:left}
.navbar-header .logo-image{max-height:140px;max-width:100%;}
.navbar-header .navbar-logo{margin:-10px 0 0 6px;float:left}
.navbar-header .navbar-tool{position: relative;display:flex;float:right;margin-right:20px;font-size: 12px; text-align: center;}
.navbar-header .navbar-tool img{width: 30px;}
.navbar-header .navbar-tool a{color: #000;z-index: 9999;}
.mini-cart-wrap .btn-mini-cart {font-size: 12px;position: relative;float: right;z-index: 9999; cursor: pointer;margin-right: 20px;}
.mini-cart-wrap .btn-mini-cart img{ width: 30px;}
.mini-cart-wrap .btn-mini-cart .cart-total {background-color: #E94D8A;border-radius: 50%;color: #FFFFFF;display: block;font-size: 50%;text-align: center;line-height: 16px;height: 16px;width: 16px;position: absolute;left: 22px;top: 0;
}

.navbar-menu{margin:0px;position:absolute;bottom:50px;right:0;vertical-align:middle}
.navbar-menu>ul{display:inline-block;list-style:none;margin:0px 0px 0px 0px}
.navbar-menu>ul>li{display:inline-block;margin:0 5px;padding:5px 5px}
.navbar-menu>ul>li>a:hover:focus:visited:active,.navbar-menu>ul>li>a.active,.navbar-menu>ul>li>a{text-transform:capitalize;font-size:18px;font-weight:600;text-decoration:none;-webkit-transition:color 0.15s linear;-moz-transition:color 0.15s linear;-o-transition:color 0.15s linear;-ms-transition:color 0.15s linear;transition:color 0.15s linear}
.navbar-menu>ul>li:hover{border-bottom:3px solid #E94D8A;}
.navbar-wrapper{border-bottom:1px solid #ddd;background-color:#fff}
.navbar{margin:0 auto;border-bottom:0}
.navbar-header-wrapper .header-container{width:100%;float: left;text-align:left;}
.navbar-menu-wrapper .header-container{width:100%;text-align:left;}
.header-container>.navbar-header,.header-container-fluid>.navbar-header,.header-container>.navbar-collapse,.header-container-fluid>.navbar-collapse{float:none;display:inline-block;max-width:100%;width:100%}
.navbar-menu{text-align:left;margin:0px auto;position:relative;bottom:0px;right:0;display:inline-block}
.navbar-menu>ul{padding:0;float:left;}
.navbar-menu>ul li{ margin-bottom: 8px;}
.navbar-menu ul li a{border-bottom:3px solid transparent}
.navbar-menu ul li a,.navbar-menu ul li a:hover,.navbar-menu ul li a.active{text-transform:uppercase;font-size:16px}
/*.navbar-menu>ul>li>a:hover{border-bottom:3px solid #E94D8A}*/
.navbar-menu ul li.active { border-bottom:3px solid #E94D8A;}

.nav-dropdown{position:relative}
.nav-dropdown-menu{position:absolute;top:100%;left:0px;z-index:1000;display:none;float:left;width:100%;min-width:160px;padding:10px 0;margin:0;text-align:left;font-size:14px;list-style:none;background-color:#fff;border-width:0;border-radius:0;-webkit-box-shadow:none;box-shadow:none}
.nav-dropdown:hover .nav-dropdown-menu{display:block}
.nav-dropdown-menu>li>a{display:block}
.nav-dropdown-toggle,.nav-dropdown-menu>li>a{-webkit-transition:color 0.15s linear;-moz-transition:color 0.15s linear;-o-transition:color 0.15s linear;-ms-transition:color 0.15s linear;transition:color 0.15s linear}
.btn>.caret,.dropup>.btn>.caret{border-top-color:#000 !important}.label{border:1px solid #000}
.caret{display:inline-block;width:0;height:0;margin-left:2px;vertical-align:middle;border-top:4px solid;border-right:4px solid transparent;border-left:4px solid transparent}
.dropdown{position:relative}
.dropdown-toggle:focus{outline:0}
.text-primary-color{color:#000}
.text-primary-color a{color:#000}
.text-primary-color a:hover{color:rgba(68,68,68,0.8)}
.text-primary-color li a{color:#000}

.nav-dropdown-menu{border:solid 1px #ddd;-moz-box-shadow:0 6px 6px -4px #aaa;-webkit-box-shadow:0 6px 6px -4px #aaa;box-shadow:0 6px 6px -4px #aaa;top:29px;padding:0;background-color:#fcfcfc;left:0px}
.nav-dropdown .nav-dropdown-menu li a,.nav-dropdown .nav-dropdown-menu li{-webkit-transition:all 100ms ease;-moz-transition:all 100ms ease;-ms-transition:all 100ms ease;-o-transition:all 100ms ease;transition:all 100ms ease}
.nav-dropdown .nav-dropdown-menu li{padding:3px 3px}
.nav-dropdown .nav-dropdown-menu li a,.nav-dropdown .nav-dropdown-menu li a:hover,.nav-dropdown .nav-dropdown-menu li.active a{color:#000;text-transform:uppercase;padding:5px 10px;font-size:14px}
.nav-dropdown-menu li:hover,.nav-dropdown-menu li:focus,.nav-dropdown-menu li.active{background-color:#f5f5f5}

.pagination>.active>a,.pagination>.active>span,.pagination>.active>a:hover,.pagination>.active>span:hover,.pagination>.active>a:focus,.pagination>.active>span:focus{z-index:2;color:#fff;background-color:rgba(68,68,68,0.8);border-color:rgba(68,68,68,0.8);cursor:default}
.pagination>li>a:hover,.pagination>li>span:hover,.pagination>li>a:focus,.pagination>li>span:focus{color:#000;background-color:rgba(68,68,68,0.1)}
}
</style>