<template>
  <div>
    <headerTop @banner="setBanner" @summary="setSummary"></headerTop>
    <headerTopPc></headerTopPc>
    <div id="content-pc">
        <section class="breadcrumb-area">
            <div class="breadcrumb-text">
                <span><a @click="utils.url('index',{v:1})">首頁</a></span>
                <span v-if="nav && nav[0]">
                    <span>></span> <span><a @click="utils.goUrl('list',{id:nav[0].id})">{{nav[0].title}}</a></span>
                </span>
                 <span v-if="nav && nav[1]">
                     <span>></span> <span><a @click="utils.goUrl('list',{id:nav[1].id})">{{nav[1].title}}</a></span>
                 </span>
            </div>
        </section>
    </div>
    <!-- 首页结束 -->
    <!-- 产品分类列表 -->   
    <div class="Goodslist">
        <div class="mdui-container-fluid mdui-page-container">
            <div v-if="banner">
                <img width="100%" :src="banner">
            </div>
            <div class="cate-summary" v-if="summary">{{summary}}</div>
            <div class="pro_box">
                <div class="module-31">
                    <div class="module-31-card" @click="utils.url('detail', {id:item.id})" v-for="item in goodsList">
                        <div class="mdui-card-media">
                            <img :src="item.images" width="100%">
                        </div>
                        <div class="mdui-card-content module-31-content">
                            <div class="module-31-title"><div class="te-btn" v-if="item.is_te == 1"></div>{{item.title}}</div>
                            <div class="module-31-bottom">
                                <div class="module-31-price">
                                    <span class="module-31-discountprice">{{item.sale_price}}</span>
                                    <span class="module-31-sales"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="no-data" v-if="isLast"></div>
            </div>
        </div>
    </div>
    <footmain></footmain>
    <loader :loader="loader"></loader>
</div>
</template>
<script>
// @ is an alias to /src

import { WOW } from "wowjs";
import headerTopPc from '../views/common/headerPc.vue';
import headerTop from '../views/common/header.vue';
import vuescroll from '../components/vue-scroll.vue';
import footmain from '../views/common/footmain.vue';
import loader from '../views/common/loader.vue';

export default {
    name: "list",
    components: {
        headerTopPc,
        headerTop,
        vuescroll,
        footmain,
        loader
    },
    created() {},
    data() {
        return {
            banner:'',
            summary:'',
            goodsCategoryTop:[],
            goodsList:[],
            showGoodsList:false,
            config:{},
            cid:'',
            sid:'',
            ops:{},
            noData:false, //判断是否数据全部加载完成 true为全部加载完
            page:1,// 当前页数
            limit:8,// 每页数量
            isLast:false,// 是否最后一页
            isLoading:false,
            loader:false,
            nav:[]
        };
    },
    filters: {},
    methods: {
        setBanner(data){
            this.banner = data;
        },
        setSummary(data){
            this.summary = data;
        },
        getList(){

            if( this.isLast || this.isLoading ) {
                return false;
            }
            this.isLoading = true;
            this.loader = true

            this.api.get('goods/lists',{
                cate_id:this.sid ? this.sid : this.cid,
                page:this.page,
                limit:this.limit,
            }).then( res => {
                setTimeout(()=>{
                    this.loader = false
                    this.isLoading = false;
                },600)
                if( res.code != 200 ){
                    this.$layer.msg( res.message );
                    return false;
                }

                let data = res.data;
                if(data.data.length > 0){
                    this.goodsList = this.utils.merge(this.goodsList,data.data);
                    this.page++
                }
                if( data.last_page == 0 || data.current_page == data.last_page ){
                    this.isLast = true;
                }
                this.cacheList();
            } )
        },
        scrollList() {
            if( this.utils.getFootHeight() < 150){
                this.getList();
            }
        },

        cacheList(){
            let data = {};
            data.page = this.page;
            data.sid = this.sid;
            data.cid = this.cid;
            data.goodsList = this.goodsList;
            data.isLast = this.isLast;
            this.utils.cache('goodsList',data);
        },

        clearGoodsList(){
            this.utils.cache('goodsList',null);
        }

    },
    mounted() {
        this.sid = this.$route.query.sid;
        this.cid = this.$route.query.id;

        this.api.get('goods/get_nav_category',{id:this.sid?this.sid:this.cid}).then( res => {
            console.log(res)
            this.nav = res.data
        })

        // 判断是否有有缓存
        let cacheData = this.utils.cache('goodsList');
        console.log(cacheData);
        console.log(this.utils.cache('goodsListHeight'))
        if( cacheData.sid ==  this.sid && cacheData.cid == this.cid ){
            this.page = cacheData.page;
            this.isLast = cacheData.isLast;
            this.goodsList = cacheData.goodsList;
            setTimeout(() => {
                window.scrollTo(0,this.utils.cache('goodsListHeight'));
            },500)
        } else {
            this.clearGoodsList();
            this.page = 1;
            this.isLast = false;
            this.goodsList = [];
            this.getList();
        }
        addEventListener("scroll",this.scrollList,true);
        this.config = this.utils.config()
         // 添加像素
        this.utils.addXiangsu()
    },
    destroyed() {
        removeEventListener('scroll',this.scrollList,true)
    },

    beforeRouteLeave(to, form, next) {

        // 离开前缓存当前浏览高度
        if( form.name == 'list' && to.name != 'list' ){
            var scrollTop=0;
            if(document.documentElement&&document.documentElement.scrollTop){
                scrollTop=document.documentElement.scrollTop;
            }else if(document.body){
                scrollTop=document.body.scrollTop;
            }
            this.utils.cache('goodsListHeight',scrollTop)
        } else {
            this.utils.cache('goodsListHeight',0)
        }
        next();
    },
};
</script>

<style scoped>
.mdui-overlay{background:rgba(0,0,0,.5)}
.mdui-toolbar-title{box-shadow:none}
.mdui-page-container{padding-left:0;padding-right:0;margin-left:0;margin-right:0;}
.mdui-loading{text-align:center;padding:8%;font-size:12px;color:#7B7B7B}
.mdui-loading i{font-size:16px;margin-left:4px;}
.mdui-depletion-box{display:none;}
.mdui-appbar-scroll-toolbar-hide.mdui-headroom-unpinned-toolbar{-webkit-transform:translate3d(0,-NaNpx,0)!important;transform:translate3d(0,-NaNpx,0)!important;}
.cate-summary{width:100%;padding:8px 5px 8px;text-align:center;color:#E29EAD;font-size: 12px;background: #FFF5FB;}

@media (max-width:767px) {
    .module-31{background:#f4f4f4;color:#000;padding:8px 2.4% 0;box-sizing:border-box;display:flex;justify-content:space-between;flex-wrap:wrap;}
    .module-31 .module-31-card{width:48.8%;margin-bottom:2%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
    .module-31 .module-31-card:nth-last-child(1){margin-left:0;}
}

@media (min-width:768px) {
    .module-31{width:750px;margin:0 auto;background:#f4f4f4;color:#000;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:992px) {
    .module-31{width:970px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}

@media (min-width:1200px) {
    .module-31{width:1170px;margin:0 auto;background:#f4f4f4;color:#000;padding:0;box-sizing:border-box;display:flex;flex-wrap:wrap;}
    .module-31 .module-31-card{width:23%;margin:1%;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;float:left;}
}
.module-31 .module-31-card > a{text-decoration:none;}
.module-31 .module-31-card .mdui-card-media{padding-bottom:100%;}
.module-31 .module-31-card .mdui-card-media > img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.module-31 .module-31-content{padding:8px;color:#000;}
.module-31 .module-31-content .module-31-title{height:36px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;width:100%;font-size:13px;line-height:18px;}
.module-31 .module-31-content .module-31-price{font-size: 0;line-height: normal;padding:6px 0 0;display:flex; justify-content: space-between;align-items: center;}
.module-31 .module-31-content .module-31-price > span:last-child{font-size:12px;color:#777777;line-height:12px;display:inline-block;}
.module-31 .module-31-content .module-31-discountprice{font-size:22px;color:#E94D8A;line-height:22px;margin-right:8px;}
.module-31 .module-31-content .module-31-discountprice:before{content:'NT$';margin-right:1px;font-size:12px;line-height:12px;}
.module-31 .module-31-content .module-31-sales{font-size: 12px;color: #777777; line-height: 22px; float:right;}
.module-31 .module-31-content .module-31-originalprice{text-decoration:line-through}
.module-31 .module-31-content .module-31-originalprice:before{content:'NT$';}
.module-31 .module-31-content .module-31-btn{display:flex;align-items:baseline;justify-content:space-between;}
.module-31 .module-31-content .module-31-btn button{background:#E94D8A;border-radius:3px;width:88px;height:26px;border:none;box-shadow:none;font-size:12px;color:#FFFFFF;line-height:12px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}
.module-31 .module-31-content .module-31-btn span{font-size:13px;color:#777777;text-align:right;line-height:13px;}
.module-31 .module-31-content .module-31-tag{height:16px;overflow:hidden;font-size:0;margin:5px 0;}
.module-31 .module-31-content .module-31-tag span{display:inline-block;padding:0 7px;border:none;border-radius:8px;font-size:12px;color:#E56214;line-height:16px;height:16px;background:#FCE9D5;box-sizing:border-box;margin-right:6px;}
.module-31 .module-31-content .module-31-tag span:last-child{margin-right:0;}
.module-31 .module-31-more{background:#FFF;color:#7B7B7B;padding:0;border:none!important}
.module-31 .module-31-more a{position:relative;color:#7b7b7b;display:block;padding-bottom:100%;border:1px solid #f4f4f4;}
.module-31 .module-31-more a .textBox{position:absolute;left:50%;margin-left:-25px;top:50%;margin-top:-28px;text-align:center;}
.module-31 .module-31-more a span{display:inline-block;}
.module-31 .module-31-more a span.circle{border:1px solid #7B7B7B;border-radius:50%;padding:3px;margin-top:12px;}
input{border:0;background-color:#eaeaea;padding-left:5px;width:204px;outline:none;}
.mdui-row,[class*="mdui-row-"]{margin-left:0;margin-right:0;}

.flex { display: flex; }
.hide { display: none; }
.swiper-slide img { width:100%; }
</style>
