<template>
    <div>
        <headerTopPc></headerTopPc>
        <div id="content-mobile" class="mdui-appbar mdui-appbar-fixed mdui-shadow-0 mdui-appbar-scroll-toolbar-hide mdui-headroom mdui-appbar-scroll-toolbar-hide mdui-headroom-pinned-toolbar">
            <div class="mdui-tab">
                <div class="mdui-toolbar-title">
                    <div class="top-left-options" >
                        <a class="text-icon-options" href="javascript:void(0)"  @click="goBack">
                            <img src="../assets/images/icon-back.png">
                        </a>
                    </div>
                    <div class="top-right-options">
                        <a class="text-icon-options" href="javascript:;" @click="utils.url('index',{v:1})">
                            <img src="../assets/images/icon-homepage.png">
							<span class="text-foot">首頁</span>
                        </a>
                        <a id="footprint" class="text-icon-options" href="javascript:void(0)" @click="showFootprint = true">
                            <img src="../assets/images/icon-footprint.png">
							<span class="text-foot">瀏覽</span>
                        </a>
                        <a id="share" class="text-icon-options" href="javascript:void(0)" @click="showShare = true">
                            <img src="../assets/images/icon-share.png">
                            <span class="text-foot">分享</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div id="content-pc">
            <section class="breadcrumb-area">
                <div class="breadcrumb-text">
                    <span><a @click="utils.url('index',{v:1})">首頁</a></span> <span>></span> <span>商品詳情</span>
                </div>
            </section>
        </div>

        <div class="detail-main">
        <!-- 細節內容s -->
        <div class="mdui-container-fluid">
            <!-- 细节图s -->
            <div id="detail-mobile" class="swiper-slide-main">
            <div class="mdui-row" style="position:relative">
                <div class="mdui-col-xs-12 swiper-container round-sowing swiper-container-horizontal" style="padding:0;" v-if="goodsInfo && goodsInfo.picture">
                    <swiper :options="swiperOption" v-if="goodsInfo.picture && goodsInfo.picture.length">
                        <swiper-slide class="swiper-slide" v-for="item in goodsInfo.picture">
                            <img :src="item"/>
                        </swiper-slide>
                        <div class="swiper-pagination"  slot="pagination"></div>   
                    </swiper>
                </div>
            </div>
            </div>
            <!-- 细节图e -->

            <!-- 電腦细节图s -->
            <div id="detail-pc" class="swiper-slide-main">
            <div class="mdui-row" style="position:relative">
                <div class="mdui-col-xs-12 swiper-container round-sowing swiper-container-horizontal" style="padding:0;" v-if="goodsInfo && goodsInfo.picture">
                    <swiper :options="swiperOption" v-if="goodsInfo.picture && goodsInfo.picture.length" ref="bigSwiper">
                        <swiper-slide class="swiper-slide" v-for="item in goodsInfo.picture">
                            <img :src="item"/>
                        </swiper-slide>
                        <div class="swiper-pagination"  slot="pagination"></div>   
                    </swiper>
                    <div style="position:relative">
                        <swiper :options="swiperOpt" v-if="goodsInfo.picture && goodsInfo.picture.length" style="margin-top:10px">
                            <swiper-slide class="swiper-slide" v-for="item in goodsInfo.picture">
                                <img :src="item"/>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                </div>
                <div class="mdui-col-xs-12 swiper-container round-sowing swiper-container-horizontal skupic" v-show="selectStr">
                    <img id="productSpecificationImg" :src="image?image:goodsInfo.images">
                </div>
            </div>
            </div>
            <!-- 细节图e -->

            <div class="detail-area-main">           
            <!-- 手機顯示s -->
            <div id="detail-mobile">
            <div class="mdui-row mdui-product-price mdui-color-white hav-promotions">
                <div class="mdui-typo-body-2 product-price mdui-text-color-red-900">
                    <span class="price1" v-if="goodsInfo.min_sku_price == goodsInfo.max_sku_price">
                        <span>NT$</span>{{goodsInfo.max_sku_price}}
                    </span>
                    <span class="price1" v-else>
                        <span>NT$</span>{{goodsInfo.min_sku_price}} ~ <span>$</span>{{goodsInfo.max_sku_price}}
                    </span>
                </div>
            </div>
            <div class="product-id-tag" v-if="goodsInfo.item_no">商品編號：{{goodsInfo.item_no}}</div>
            <div class="mdui-row mdui-product-title mdui-color-white">
                <div class="mdui-col-xs-12 mdui-p-x-0">
                    <div class="mdui-typo-body-2 product-title">
                        <span class="product-title-text"><div class="product-title-text-tag" v-if="goodsInfo.is_te == 1"></div>{{goodsInfo.title}}</span>
                    </div>
                    <div class="mdui-typo-body-2 product-description">{{goodsInfo.summary}}</div>
                </div>
            </div>
            </div>
            <!-- 手機顯示e -->

            <!-- 電腦顯示s -->
            <div id="detail-pc">
            <div class="mdui-row mdui-product-title mdui-color-white">
                <div class="mdui-col-xs-12 mdui-p-x-0">
                    <div class="mdui-typo-body-2 product-title">
                        <span class="product-title-text"><div class="product-title-text-tag" v-if="goodsInfo.is_te == 1"></div>{{goodsInfo.title}}</span>
                    </div>
                    <div class="mdui-typo-body-2 product-description">{{goodsInfo.summary}}</div>
                </div>
            </div>
            <div class="mdui-row mdui-product-price mdui-color-white hav-promotions">
                <div class="mdui-typo-body-2 product-price mdui-text-color-red-900">
                    <div v-if="sku.sale_price">
                        <span class="price1" id="productSpecificationPrice">
                            <span>NT$</span>{{sku.sale_price}}
                        </span>
                    </div>
                    <div id="productSpecificationPrice" v-else>
                        <span class="price1" v-if="goodsInfo.min_sku_price == goodsInfo.max_sku_price">
                            <span>NT$</span>{{goodsInfo.max_sku_price}}
                        </span>
                        <span class="price1" v-else>
                            <span>NT$</span>{{goodsInfo.min_sku_price}} ~ <span>$</span>{{goodsInfo.max_sku_price}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="product-id-tag" v-if="goodsInfo.item_no">商品編號：{{goodsInfo.item_no}}</div>          
            </div>
            <!-- 電腦顯示e -->

            <div class="mdui-row mdui-color-white mdui-m-t-1 mdui-pannel expressPrice">
                <span class="title">折扣</span><span>任意2件88折,3件8折,5件75折,訂愈多折扣愈多</span>
            </div>
            <a :href="detail_service">
            <div class="mdui-row mdui-color-white mdui-m-t-1 mdui-pannel expressPrice">
                <span class="title">活動</span><span>點這加入LINE@，立即領取<span style=" color:#E94D8A">$100</span>折價券</span>
            </div>
            </a>
            <div class="mdui-row mdui-color-white mdui-m-t-1 mdui-pannel expressPrice">
                <span class="title">運送</span><span><img src="../assets/images/express.png" height="16px" alt="宅配"><img src="../assets/images/7-11.png" height="16px" alt="7-11超商"><img src="../assets/images/family.png" height="16px" alt="全家超商">全館免運費，大概10~12天送到</span>
            </div>

            <div class="mdui-row mdui-color-white mdui-m-t-1 mdui-pannel mdui-p-b-0" @click="showBaozhang = true">
                <ul class="mdui-list">
                    <li class="mdui-list-item">
                        <div class="mdui-list-item-content custom-info">
                            <div class="custom-info-details">                       
                                <span><i class="iconfont icon-card font-pink"></i>支援線上付款</span>
                                <span><i class="iconfont icon-huodao-pay font-pink"></i>貨到付款</span>
                                <span><i class="iconfont icon-seven-days font-pink"></i>七日鑑賞期</span>
                            </div>
                            <i class="iconfont icon-xiangyoujiantou"></i>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- 電腦顯示s -->
            <div class="mdui-typo-body-2 product-mark"></div>
            <div id="detail-pc">
                <div class="sku">
                    <div class="mdui-color-white cartDialog confirmSelected sku">
                        <div class="product-prams-box">
                            <div class="mdui-row">
                                <div class="mdui-p-x-2 product-prams" v-for="(attr,index) in goodsInfo.goodsattr">
                                    <div class="mdui-typo-body-1">{{attr.name}}</div>
                                    <button class="mdui-btn mdui-color-default mdui-btn-dense mdui-btn-small" :class="{active:attrIds[index] == item.attr_val_id}" v-for="(item,i) in attr.lists"  @click="selectSku(index,item)">{{item.attr_value}}</button>
                                </div>
                            </div>
                            <div class="mdui-row mdui-p-x-2 ">
                                <div class="mdui-typo-body-1">數量</div>
                                <div class="mdui-numbox" data-numbox-step="1" data-numbox-min="1" data-numbox-max="999">
                                    <button class="mdui-numbox-btn mdui-numbox-btn-minus" @click="jian">
                                        <i class="mdui-icon material-icons">-</i>
                                    </button>
                                    <input class="mdui-numbox-input" type="number" value="number" v-model="number">
                                    <button class="mdui-numbox-btn mdui-numbox-btn-plus" @click="number++">
                                        <i class="mdui-icon material-icons">+</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="mdui-col-xs-3" style="width:100%;margin-top:20px;float:left;">
                    <button class="mdui-btn mdui-btn-block mdui-text-color-white mdui-color-red-900 hasPrice" style="font-size:16px;float:left;width:150px; height:44px;display: inline-block;" @click="submit">
                    <span></span><i class="iconfont icon-gouwuche2"></i>加入購物車
                    </button> 
                    <button class="mdui-btn mdui-btn-block mdui-text-color-white mdui-color-red-900 hasPrice" style="font-size:16px;float:left;width:150px; height:44px;display: inline-block;margin-left:15px" @click="submit(1)">
                    <span></span>直接購買
                    </button>
                </div> 
                <div class="share-pannel">
                    <span>分享到</span>
                    <ul>
                        <li>
                            <a target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURI(url)+'&amp;src=sdkpreparse'">
                            <img src="../assets/images/share-facebook.png">
                            </a>
                        </li>
                        <li>
                            <a target="_blank" :href="'http://line.naver.jp/R/msg/text/?分享一個好物給你，商品連結：'+encodeURI(url)">
                            <img src="../assets/images/share-line.png">
                            </a>
                        </li>
                        <li>
                            <a href="javascript:;" class="copy-btn"  v-clipboard:copy="url" v-clipboard:success="copy">
                            <img src="../assets/images/share-link.png">
                            </a>
                    </li>
                    </ul>
                </div>
                <div class="mdui-row mdui-product-title mdui-color-white">
                    <div class="mdui-typo-body-2 product-tag">
                        <span v-for="tag in goodsInfo.tags">
                            <a :href="'/tags?key=' + tag" >{{tag}}</a>
                        </span>
                    </div>
                </div> 
            </div>
            <!-- 電腦顯示e -->
        </div>
        <!-- 細節內容e -->

            <div class="mdui-row product-details mdui-pannel mdui-color-white mdui-m-t-1" id="description">
                <div id="detail-pc" class="details-desc-title">
                    <span class="h2">【商品詳情】</span>
                </div>
                <div style="width:100%;height: 38px; line-height: 38px; color: #333333; background: #D6EBFF; font-weight: bold; font-size:12px; padding: 0 1%;float:left;"><svg class="icon" style="width: 24px; height: 24px;vertical-align: middle;margin-top: -4px; margin-right: 4px; fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024"><path d="M528.032 98.048l5.216 1.6 320 112.608a64 64 0 0 1 42.56 55.296L896 272.64v175.456l-0.128 12.48c-4.16 231.936-125.664 396.8-359.872 490.24l-12.64 4.96-4.48 1.312a31.872 31.872 0 0 1-18.24-1.312c-242.464-92.48-368.256-259.136-372.512-495.2L128 448.064 596.064 448l-117.824 147.296-103.616-103.616a32 32 0 0 0-45.248 45.248l103.776 103.776a64 64 0 0 0 90.496 0l2.368-2.656 152-190.048H832l-153.888-0.096 28.928-36.128a32 32 0 1 0-49.984-40l-60.864 76.064L192 447.616H128V272.608a64 64 0 0 1 38.08-58.496l4.672-1.856 320-112.64a64 64 0 0 1 37.28-1.6z" fill="#2C85DE" p-id="477"></path></svg>全館所有商品全程嚴格執行多次消毒清潔,請放心購買!</div>
                <video controls="controls" muted style="max-width:100%;heitht:auto;" :src="goodsInfo.video_url" :poster="goodsInfo.video_pic" v-if="goodsInfo.video_url"></video>
                <div v-html="goodsInfo.details"> </div>
            </div>
            <div class="mdui-row suggestPro" style="display: block;">
                <div class="mdui-header" style="height:auto;">
                    <div class="mdui-header-title">
                        <span>同類商品推薦</span>
                        <a :href="'/list?id=' + goodsInfo.top_cate_id + '&sid=' + goodsInfo.cate_id" style="float:right; color:#000">更多 ></a>
                    </div>
                </div>
                <div class="mdui-card mdui-card-aggregation">
                    <div class="mdui-card-product-content">
                        <div class="mdui-card module-28-card" v-for="item in asGoodsList" @click="utils.url('detail',{id:item.id})">
                            <a href="javascript:void(0)">
                                <div class="mdui-card-media">
                                    <img :src="item.images">
                                </div>
                                <div class="mdui-card-content module-28-content">
                                    <div class="module-28-title">{{item.title}}</div>
                                    <div class="module-28-price">
                                        <span class="module-28-discountprice">{{item.sale_price}}</span>
                                        <span></span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    
    <footmain></footmain>

    <div id="content-mobile-d">
    <div class="d-h-50"></div>
    <div class="mdui-bottom-nav-fixed">
    <div class="mdui-bottom-nav">
        <div class="mdui-col-xs-2 mdui-color-white customer_service_container">
            <a :href="detail_service" class="text-icon-options">
                <div><img src="../assets/images/icon-customer-service.png" width="28px"></div>
                <div style="font-size: 10px; text-align: center;">客服</div>
            </a>
        </div>
    
        <div class="mdui-col-xs-2 mdui-color-white customer_service_container" @click="utils.url('cart')">
            <a id="shopping_cart" href="javascript:;" class="text-icon-options">
                <div style=" width:100%;">
                    <img src="../assets/images/icon-shopping-cart.png">
                    <label class="shoppingCart-num" v-if="cartCount > 0">{{cartCount}}</label>
                </div>
                <div style="font-size: 10px; text-align: center;">購物車</div>
            </a>
        </div>
        <div class="mdui-col-xs-4" style="float: left;width: 40%; height: 50px" @click="showSku = true">
            <button class="mdui-btn mdui-btn-block mdui-text-color-white mdui-color-red-300 hasPrice" style="display: inline-block;">
                <span></span><i class="iconfont icon-gouwuche2"></i>加入購物車
            </button>
        </div>
        <div class="mdui-col-xs-3" style="float: left;width: 40%; height: 50px" @click="showSkuBuy = true">
            <button class="mdui-btn mdui-btn-block mdui-text-color-white mdui-color-red-900 hasPrice" style="display: inline-block;">
                <span></span>直接購買
            </button>
        </div>
        </div>
            <div class="mdui-call-m">
                <div class="mdui-call-text-m">“直接購買”可下標該商品,若要訂多件可“加入購物車”</div>
            </div>
        </div>		  
    </div>
    </div>
    <sku v-if="showSku" :goods="goodsInfo" @cancel="showSku = false"></sku>
    <skubuy v-if="showSkuBuy" :goods="goodsInfo" @cancel="showSkuBuy = false"></skubuy>
    <deleteConfirm v-if="showDelete" @submit="deleteSubmit"></deleteConfirm>   
    <baozhang v-if="showBaozhang" @submit="showBaozhang = false"></baozhang>
    <share v-if="showShare" @cancel="shareCancel"></share>
    <footprint v-if="showFootprint" @close="showFootprint = false"></footprint>
    <loader :loader="loader"></loader>
    </div>
</template>

<script>
    import headerTopPc from '../views/common/headerPc.vue';
    import share from '../views/common/share.vue';
    import deleteConfirm from '../views/common/delete.vue';
    import baozhang from '../views/common/baozhang.vue';
    import sku from '../views/common/sku.vue';
    import skubuy from '../views/common/skubuy.vue';
    import footprint from '../views/common/footprint.vue';
    import footmain from '../views/common/footmain.vue';
    import loader from '../views/common/loader.vue';
    import '../assets/css/font_d.css'

    var vm = null;

    export default {
        components:{
            headerTopPc,
            share,
            deleteConfirm,
            baozhang,
            sku,
            skubuy,
            footprint,
            footmain,
            loader
        },
        name: "detail",
        data() {
            return {
                loader:false,
                goodsId:0,
                // 产品详情
                goodsInfo:{},
                // 热卖产品列表
                hotGoodsList:[],
                // 相似产品列表
                asGoodsList:[],
                // 分享
                showShare:false,
                // 删除确认
                showDelete:false,
                // 退换保障
                showBaozhang:false,
                // sku
                showSku:false,
                showSkuBuy:false,
                // 足迹
                showFootprint:false,
                cartCount:0,
                swiperOption:{
                     //显示分页
                    pagination: {
                      el: '.swiper-pagination',
                      clickable :true,
                    },
                    //自动轮播
                    autoplay: {
                      delay: 2000,
                      //当用户滑动图片后继续自动轮播
                      disableOnInteraction: false,
                    },
                    //开启循环模式
                    loop: false,
                },
                
                swiperOpt :{
                    slidesPerView: 5,
                    spaceBetween: 10,
                    //slidesPerGroup: 5,
                    loop: false,
                    //loopFillGroupWithBlank: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    on:{
                        click:function(){
                            let index = this.clickedIndex;
                            let swiper = vm.$refs.bigSwiper.$swiper;
                            swiper.slideTo(this.clickedIndex, 1000, false);
                            console.log(index)
                        }
                    }
                },
                swiperGoods:{
                    slidesPerView: 3,
                    spaceBetween: 5,
                    slidesPerGroup: 3,
                    loop: false,
                    loopFillGroupWithBlank: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    on:{
                        click:function(){
                            let id = vm.hotGoodsList[this.clickedIndex].id;
                            vm.utils.url('detail',{id:id});
                        }
                    }
                },
                countDownTime: 25200,
                countDown:{
                    hours:'00',
                    minutes:'00',
                    seconds:'00',
                },
                url:'',
                config:{},
                detail_service:{},
                number:1,
                attrIds:[],
                attrArr:[],
                sku:{},
                selectStr:'',
                image:'',
                goods:{}
            }
        },
        created(){
            vm = this;
            document.body.scrollTop = 0 //返回顶部
            document.documentElement.scrollTop = 0
        },
        props:{},
        methods: {

             submit(is_buy){
                this.$emit('submit',true)
                if( !this.sku.sku_id ){
                    this.$layer.msg( '請選擇規格' );
                    return false;
                }
                let arr = {};
                arr.sku_id = this.sku.sku_id
                arr.number = this.number;
                arr.goods_id = this.goods.id
                arr.ac = 1;
                this.utils.cart(arr);
                if( is_buy == 1 ){
                    this.utils.url('confirm')
                } else {
                    this.utils.url('cart');
                }
            },

            jian(){
                if( this.number <= 1 ){
                    return false;
                }
                this.number--
            },

            selectSku(index,item){
                this.$set(this.attrIds,index,item.attr_val_id)
                this.$set(this.attrArr,index,item);
                let path = this.attrIds.toString();
                if( item.image ){
                    this.image = item.image
                } else {
                    this.image = this.goodsInfo.images
                }
                let str = '';
                for(let i = 0; i < this.attrArr.length; i++){
                    if( this.attrArr[i] ){
                        str += str == '' ? this.attrArr[i].attr_value : ' / ' + this.attrArr[i].attr_value
                    }
                }
                this.selectStr = str;
                for(let i = 0; i < this.goodsInfo.goodssku.length; i++){
                    if( this.goodsInfo.goodssku[i].attr_val_path == path){
                        this.sku = this.goodsInfo.goodssku[i];
                        if( this.sku.images ){
                            this.image = this.sku.images
                        } else {
                            this.image = this.goodsInfo.images
                        }
                    }
                }
            },

            /**
             * 取消分享
             */
            shareCancel(msg){
                this.showShare = false;
            },

            /**
             * 取消删除
             */
            deleteSubmit(data)
            {
                if( data === false){
                    this.showDelete = false;
                } else {
                    this.showDelete = false;
                }
            },

            getDetail(){


                this.utils.footprint(this.goodsId);
                this.loader = true 

                /**
                 * 获取产品详情
                 */
                this.api.get('goods/detail',{id:this.goodsId}).then( res => {
                    try{
                        res.data.picture.reverse();
                        this.goodsInfo = res.data
                        //document.title = res.data.title
                    } catch( err){
                        console.log(err)
                    }
                }).then(() => {

                    /**
                     * 相似产品列表
                     */
                    this.api.get('goods/lists',{cate_id:this.goodsInfo.cate_id}).then(res => {
                        this.asGoodsList = res.data.data
                    })

                    /**
                     * 热卖产品列表
                     */
                    this.api.get('goods/hotgoods',{}).then(res=>{
                        this.hotGoodsList = res.data.data
                    })

                    setTimeout(()=>{
                        this.loader = false;
                    },600)
                })
            },

            /**
             * 倒计时
             */
            // getTimer(){
            //     if( this.countDownTime <= 0 ){
            //         this.countDownTime = 25200
            //     }
            //     this.countDown.hours = '0' + Math.floor( this.countDownTime / 3600 );
            //     let minutes = Math.floor( this.countDownTime % 3600 / 60 );
            //     this.countDown.minutes = minutes < 10 ? '0'+ minutes : minutes
            //     let seconds = this.countDownTime % 3600 % 60;
            //     this.countDown.seconds = seconds < 10 ? '0' + seconds : seconds
            //     this.countDownTime--;
            //     setTimeout(() => {
            //         this.getTimer()
            //     },1000)
            // },
            
            goBack () {
                if( document.referrer == null || !document.referrer ){
                    this.utils.url('list',{
                        id:this.goodsInfo.top_cate_id
                        //sid:this.goodsInfo.cate_id
                    })
                } else {
                    this.utils.goBack();
                }
            },

            cancel:function(){
                this.$emit('cancel',true);
            },

            copy(e){
                this.$emit('cancel',true);
                this.$layer.msg( '商品連結複製成功' );
            }

        },
        watch: {
            $route(to,from){
                if( to.name == 'detail'){
                    this.goodsId = to.query.id
                    this.getDetail()
                }
            }
        },
        mounted() {
            this.goodsId = this.$route.query.id
            this.getDetail();
            // 倒计时
            //this.getTimer();
            this.cartCount = this.utils.cartCount();
            let url = window.location.protocol+"//"+window.location.host + this.$route.fullPath;
            this.detail_service = 'line://oaMessage/'+this.utils.config('line')+'/?我要洽詢這件商品，連結是：' + url;
            this.url = window.location.protocol+"//"+window.location.host + this.$route.fullPath;
            this.config = this.utils.config()

             // 添加像素
            this.utils.addXiangsu()
        }
    }
</script>

<style scoped>
/* 公共 */
body,html {background: #f4f4f4!important;}
div::-webkit-scrollbar{display:none}
body.mdui-bottom-nav-fixed .mdui-bottom-nav .mdui-btn{font-size:16px}
.sku { position:relative!important;z-index:1}
.skupic{padding:0;margin:0;position:absolute;top:0;left:0;z-index:2}
.skupicno{display:none;}
@media (max-width:767px) {
    .mdui-container-fluid {width: 100%;}
    .swiper-slide-main{width:100%;}
    #detail-pc {display: none;}
    body.mdui-bottom-nav-fixed .mdui-bottom-nav {position: fixed;right: 0;bottom: 0;left: 0}
}
@media (min-width:768px) {
    .mdui-container-fluid {width:750px;margin:0 auto;}
    .swiper-slide-main{width:48%; float:left;}
    .detail-area-main { width:52%; float:left; padding: 0 20px;}
    #detail-mobile {display: none;}
    .custom-info-details{padding-left: 10px;}
    .expressPrice{padding-left: 10px;}
}
@media (min-width:992px) {
    .mdui-container-fluid {width:970px;margin:0 auto;}
    .swiper-slide-main{width:48%; float:left;}
    .detail-area-main { width:52%; float:left; padding: 0 20px;}
    #detail-mobile {display: none;}
    .custom-info-details{padding-left: 10px;}
    .expressPrice{padding-left: 10px;}
}
@media (min-width:1200px) {
    .mdui-container-fluid {width: 1170px;margin:0 auto;}
    .swiper-slide-main{width:48%; float:left;}
    .detail-area-main { width:52%; float:left; padding: 0 20px;}
    #detail-mobile {display: none;}
    .custom-info-details{padding-left: 10px;}
    .expressPrice{padding-left: 10px;}
}
/* 头部 */
.mdui-tab a{padding:0}
.d-h-50{width:100%; height:50px; float:left;}

/* 下部按钮 */
.mdui-bottom-nav-fixed .mdui-bottom-nav {
    z-index: 100;
    width: 100%;
    left: initial;
    right: initial;
    height: 100px;
    font-size:18px;
}

.mdui-col-xs-2{ height: 50px;}
.mdui-bottom-nav [class*=mdui-col-xs-]{padding:0}
.mdui-bottom-nav .mdui-btn-block{border-radius:0;height:50px;min-width:inherit}
.mdui-bottom-nav button{display:none}
.mdui-bottom-nav button.hasPrice,.mdui-bottom-nav button.hasPrice_normarl{line-height:14px;padding:0;font-size:13px}
.mdui-bottom-nav button.hasPrice > span,.mdui-bottom-nav button.hasPrice_normarl > span{display:block;font-size:12px;line-height:16px;padding-bottom:4px}
.mdui-bottom-nav #depletionEnd-depletion{color:rgba(255,255,255,.5);background:#ACACAC;}

/* 客服 */
.customer_service_container{display:flex;align-items:center;justify-content:center;border-top:1px solid #DFDEDB;border-right:1px solid #DFDEDB; width:20%}
.customer_service_container>a{opacity:initial}
.customer_service_container>a>img{width:28px!important;height:auto!important;}
.customer_service_container>a>span{margin-top:4px}
#customer_service_real img{margin-top:1px}

/* 购物车 */
#shopping_cart{position:static;border:0;height:100%;display:flex;align-items:center;justify-content:center;border-radius:0;padding:0 8px;}
#shopping_cart .cartIcon{display:flex;align-items:center;justify-content:center;position:relative}
#shopping_cart .cartTime p{margin:0;color:#777;text-align:right;overflow:hidden}
#shopping_cart .cartTime p:first-child{font-size:14px;line-height:14px;padding-bottom:2px}
#shopping_cart .cartTime p:last-child{font-size:10px;line-height:12px;}
#shopping_cart .shoppingCart-num{border:1px solid #fff;width:auto;min-width:16px;box-sizing:border-box;padding:0 2px;border-radius:8px;top:calc(43% - 18px);right:calc(50% - 20px);font-size:10px}

/* 轮播 */
.swiper-slide img{width:100%}
.swiper-pagination-fraction{right:15px;bottom:8px;left:inherit;width:initial;text-align:right;background:rgba(255,255,255,.85);padding:2px 4px;font-size:12px;border-radius:2px}

/* 标题 */
.mdui-product-title{box-sizing:border-box;padding:10px 0 10px 4%;font-size:0}
.product-title{margin-bottom:6px;display:flex;width:100%;color:#363636;box-sizing:border-box}
.product-title > span{display:block;}

@media (max-width:767px) {
    .product-title .product-title-text{flex:1;font-weight:700;line-height:22px;font-size:16px;padding-right:8px;vertical-align:middle}
}
@media (min-width:768px) {
    .product-title .product-title-text{flex:1;line-height:30px;font-size:25px;}
}
.product-title .product-title-text .product-title-text-tag{margin-top:-3px;vertical-align:middle;background:#E05D44;border-radius:3px;display:inline-block;padding:0 4px 0 5px;font-size:12px;line-height:16px;color:#FFCDA6;word-break:keep-all;margin-right:6px;font-weight:500}
.product-description{margin-right:4%;width:96%;font-size:12px;color:#7B7B7B;overflow:hidden;text-overflow:ellipsis;display:inline-block;white-space:nowrap;padding-bottom:10px;border-bottom:1px dashed #EDEDED;line-height:12px}
.product-title-text-tag::after {content:'特價'}
.product-mark{margin-left:2%;width:95%;border-bottom:1px dashed #EDEDED;line-height:12px}

/* 标签 */
.product-tag{margin-right:4%;width:96%;}
.product-tag span{display:inline-block;margin:11px 8px 0 0;border:1px solid #E94D8A;border-radius:12px;font-size:12px;color:#E94D8A;line-height:12px;padding:3px 8px;word-break:keep-all;}
.product-tag span a{color:#E94D8A;text-decoration:none}
.product-tag span .mdui-icon{line-height:inherit;text-align:center;font-size:16px;vertical-align:bottom;width:12px}

.expressPrice{ height:auto;}
.expressPrice .title{color:#000;float:left;}
.expressPrice img{ margin-right:6px;}
.expressPrice table{box-shadow:none;border-radius:2px}
.expressPrice table tr:first-child{font-weight:700;background:#FBFBFB;}
.expressPrice table td{border-right:1px solid rgba(0,0,0,.12);text-align:center;color:black;padding:10px 28px}
.expressPrice table td:last-child{border-right:0}
.expressPrice > span{color:#777777; line-height:16px;padding:8px 5px 5px 12px;display:block;}

/* 退换货 */
.custom-info{display:flex}
.custom-info .label{width:130px;min-width:70px}

@media (max-width:480px) {
    .expressPrice .title{font-size:14px;}
    .expressPrice > span{font-size:12px;}
    .custom-info .custom-info-details{flex-grow:1;margin-top:-4px;transform:scale(1.02);}
    .custom-info .custom-info-details>span{position:relative;display:inline-block;padding:0 8px;color:#E94D8A;margin-top:4px;font-size:12px}
}
@media (min-width:481px) and (max-width:768px) {
    .expressPrice .title{font-size:14px;}
    .expressPrice > span{font-size:12px;}
    .custom-info .custom-info-details{flex-grow:1;margin-top:-4px;transform:scale(1.02);}
    .custom-info .custom-info-details>span{position:relative;display:inline-block;padding:0 8px;color:#E94D8A;margin-top:4px;font-size:14px}
}
@media (min-width:769px) {
    .expressPrice .title{font-size:16px;}
    .expressPrice > span{font-size:14px;}
    .custom-info .custom-info-details{margin-top:-4px;transform:scale(1.02);}
    .custom-info .custom-info-details>span{position:relative;display:inline-block;padding:0 8px;color:#E94D8A;margin-top:4px;font-size:14px}
}
.custom-info .custom-info-details>span:after{position:absolute;width:4px;height:4px;left:0;top:8px}
.de-service-list{padding:0;}
.de-service-list li{padding:8px 8px 8px 35px;border-bottom: 1px solid #e6e6e6;position: relative; list-style-type:none;}
.de-service-list .iconfont{display: block;font-size: 20px;margin-right:5px;line-height:20px;position: absolute;left: 8px;}
.de-service-text{line-height: 20px;}
.de-service-title{width: 100%;font-size: 14px;line-height: 20px;}

/* 行 */
.mdui-list{margin-bottom:0;padding:0;padding-left: 8px;}
.mdui-list-item{border-bottom:1px solid #eaeaea;padding:0 4%;min-height:initial; height: 36px;padding-left: 0%;border-bottom: 0;}
.mdui-list-item:hover{background:#FFF}
.mdui-list-item .mdui-icon{color:#7B7B7B;margin-right:-8px}
.mdui-list .mdui-list-item:last-child{border-bottom:none}
.mdui-list-item .mdui-list-item-content{font-size:14px;color:#7B7B7B;padding-top: 5px;padding-bottom: 5px;}

/* 价格 */
.mdui-product-price{padding:10px 4% 0 4%;}
@media (max-width:768px) {
    .mdui-product-price.hav-promotions{background-image:linear-gradient(135deg,#E94D8A 0%,#E29EAD 100%);display:flex;padding:8px 2.8% 10px 4%;}
}
@media (min-width:769px) {
    .mdui-product-price.hav-promotions{background-image:linear-gradient(135deg,#E94D8A 0%,#E29EAD 100%);display:flex;padding:10px 2.8% 10px 4%; margin-left: 5px;}
}
.mdui-product-price .product-price{margin-top:2px;margin-bottom:0;display:flex;align-items:baseline;}
.mdui-product-price.hav-promotions .product-price{display:block}
.mdui-product-price .price1{font-size:26px;letter-spacing:-1px;line-height:26px;}
.mdui-product-price.hav-promotions .price1{font-weight:500;letter-spacing:0;}
.mdui-product-price .price1>span{font-size:16px;margin-right:2px;vertical-align:middle}
.mdui-product-price .price1:after{font-size:12px;content:"up";margin:0 8px 0 2px;display:none}
.mdui-product-price .price2{font-size:12px;margin-left:5px;color:#777;vertical-align:text-bottom;letter-spacing:0}
.mdui-product-price .price3{font-size:12px;color:#E94D8A;flex:1;text-align:right;padding-top:2px}
.mdui-product-price.hav-promotions .price3{text-align:left;display:block}
.mdui-product-price.hav-promotions .price1,.mdui-product-price.hav-promotions .price2,.mdui-product-price.hav-promotions .price3{color:#fff!important}
.product-id-tag{font-size:12px;line-height:22px;height:22px;color:#FFF;text-align:right;padding:0 4% 0 2.8%;background: rgba(0, 0, 0, .5);border-radius:13.5px 0px 0px 13.5px; float:right; margin-top: -45px; margin-right: -8px;}

/* 出清 */
.mdui-product-price .mdui-promotions{display:none;}
.mdui-product-price.hav-promotions .mdui-promotions{display:block}
.mdui-promotions{text-align:right;flex:1;color:#fff;}
.mdui-promotions .mdui-date-text{font-size:12px;padding-bottom:8px;display:block}
.mdui-promotions .mdui-date{display:flex;align-items:center;justify-content:flex-end;}
.mdui-promotions .mdui-date > .point{padding:0 3px}
.mdui-promotions .mdui-date .leftHours,.mdui-promotions .mdui-date .leftMinutes,.mdui-promotions .mdui-date .leftSecond{font-weight:500;display:inline-block;background:#FFFFFF;border-radius:2px;height:20px;line-height:20px;text-align:center;width:20px;color:#E29EAD;font-size:12px}
.mdui-promotions .mdui-date .leftDay{background:transparent;color:#fff;display:inline-block;height:20px;line-height:20px;}
.mdui-promotions .mdui-date .leftDay,.mdui-promotions .mdui-date .leftHours{margin-left:4px}
.mdui-promotions .mdui-date .leftSecond{width:36px;font-size:0}
.mdui-promotions .mdui-date .leftSecond .leftSeconds{width:16px;}
.mdui-promotions .mdui-date .leftSecond .leftMSeconds{width:8px;}
.mdui-promotions .mdui-date .leftSecond span{font-size:12px}

/* 块 */
.mdui-pannel .mdui-card-header-title{font-size:14px;margin-left:0}
.mdui-pannel .mdui-card-header-title .mdui-float-left{font-size:12px;color:#7B7B7B}
.mdui-pannel .mdui-card-header-title .mdui-float-left>span{margin-right:10px}
.mdui-pannel .mdui-card-header-title .mdui-float-right>img{width:10px;height:10px;margin-left:4px}
.mdui-pannel .mdui-pannel-header{height:32px;padding:6px 4% 4px 4%}
.mdui-pannel .mdui-pannel-header .mdui-pannel-header-title{float:left;height:32px;line-height:32px;color:#363636;font-size:16px;}
.mdui-pannel .mdui-pannel-header .mdui-pannel-header-subtitle{float:right;height:32px;line-height:32px;color:#7B7B7B;text-decoration:none}
.mdui-pannel .mdui-pannel-content{padding:8px 4% 8px 4%}
.mdui-pannel .mdui-pannel-content.product-param{padding:4px 4% 4px 4%}
.mdui-pannel .mdui-pannel-content .mdui-card-header{padding:10px 0 0 10px;height:inherit}
.mdui-pannel .mdui-pannel-content .mdui-card-content{padding:5px 0 5px 10px;height:36px;line-height:18px;font-size:inherit;color:#363636;}
.mdui-pannel .mdui-pannel-length{display:flex;overflow-x:auto}
.mdui-pannel .mdui-pannel-length .mdui-product-card{width:40%}
.mdui-pannel .mdui-pannel-footer{text-align:center;padding:10px 10px 16px 10px}
.mdui-pannel .mdui-pannel-footer .mdui-btn{border:1px solid #DFDEDB;margin-top:10px;color:#979797}

/* 详情 */
.product-details p {margin-bottom:0;}
.product-details img{width:100%;height:auto;}
.product-details .product-detail{padding:0;}

/* 评价 */
.mdui-product-rate .mdui-product-rate-info{padding:10px;line-height:20px}
.mdui-product-rate #mdui-product-rate-content{height:auto;}
.mdui-product-rate .mdui-pannel-content{height:260px;overflow:hidden;padding:0 4% 0 4%}
.mdui-product-rate .mdui-pannel-content .mdui-card{border-bottom:1px dashed #EDEDED}
.mdui-product-rate .mdui-pannel-content .mdui-card:last-child{border-bottom:0}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-header{padding-left:0;margin-bottom:4px;}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-header .user{display:flex;}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-header .user img{width:30px;height:30px;border-radius:50%;margin-right:8px}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-header .user .username{color:#000;line-height:12px;padding:9px 0;margin-bottom:8px}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-header .time{color:#BCBCBC;padding:8px 0;line-height:12px}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content{height:auto;padding:0;margin-bottom:6px}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content span{padding:4px 0;font-size:14px;color:#000000;line-height:22px;}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content .ratePicBox{display:flex;padding:8px 0;}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content .ratePicBox a{width:calc((100% - 24px)/4);margin-right:8px}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content .ratePicBox a:last-child{margin-right:0}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content .ratePic{padding-bottom:100%;position:relative;overflow:hidden;}
.mdui-product-rate .mdui-pannel-content .mdui-card .mdui-card-content .ratePicBox img{width:100%;position:absolute;top:0;}
.mdui-product-rate .mdui-pannel-content.swiper-container .mdui-card-header{height:30px}
.mdui-product-rate .mdui-pannel-content.swiper-container .mdui-card-content{height:30px;font-size:14px}

/* 评价-图 */
.commentPicView{position:fixed;height:100vh;width:100vw;top:0;background:#000;z-index:9999;}
.commentPicView .more{font-size:12px;color:#FFFFFF;text-align:center;display:block;width:100%;padding:24px 0 0;line-height:12px;position:absolute;z-index:100;background:rgba(0,0,0,.7);}
.commentPicView .swiper-container{height:100%;}
.commentPicView .swiper-container .swiper-slide{overflow-y:auto;display:flex;align-items:center;justify-content:center;}
.commentPicView .swiper-container .swiper-slide img{max-width:100%;width:auto;}
.commentPicView .swiper-container .swiper-pagination-fraction{background:rgba(0,0,0,.7);padding:12px 0;top:36px;right:0;left:0;bottom:auto;text-align:center;color:#fff}
.commentPicView .picContent{width:100%;z-index:2;position:absolute;bottom:0;height:90px;background:rgba(0,0,0,.7);color:#fff;font-size:14px;line-height:22px;padding:10px 4%;box-sizing:border-box;word-break:break-all;overflow-y:auto}

/* 商品参数 */
.product-param{color:#333;padding:10px 0 10px 0;max-height:220px;overflow:hidden;}
.product-param table{border-collapse:collapse;border-spacing:0;clear:both;table-layout:inherit;width:100%;font-size:12px;color:#363636}
.product-param table>tbody>tr{border-bottom:1px dashed #EDEDED}
.product-param table>tbody>tr:last-child{border-bottom:none}
.product-param table>tbody>tr>td{line-height:18px;padding:10px 10px 10px 0;font-size:13px;text-align:left;word-wrap:break-word;word-break:break-all}
.product-param table>tbody>tr>td>*{margin:0!important}
.product-param table>tbody>tr>td:first-child{color:#7B7B7B;width:30%}
.product-param + div .mdui-btn-dense{height:28px;line-height:28px}

/* sku选择 */
.cartDialog{padding:0;width:100%;max-height:100%;}
.cartDialog.btnBox .btnBox{display:block}
.cartDialog.btnBox #confirmSelected{display:none}
.cartDialog.confirmSelected .btnBox{display:none}
.cartDialog.confirmSelected #confirmSelected{display:block}
.cartDialog .mdui-row{margin-right:0;margin-left:0;padding-right:0;}
.cartDialog .mdui-row img:not(.close-pub){width:85%;background:#F4F4F4}
.cartDialog .mdui-row .mdui-btn-small{border:1px solid #DFDEDB;min-width:50px;margin-bottom:8px;margin-right:8px;padding:0 10px;color:#363636;font-size:12px!important;height:initial!important;line-height:initial!important;min-height:28px;}
.cartDialog .mdui-row .mdui-btn-small.noInventory{border:1px dashed #DFDEDB;color:#979797}
.cartDialog .mdui-row .mdui-typo-body-1{line-height:30px;color:#000;font-size:16px;}
.cartDialog .mdui-row .mdui-typo-body-1,.mdui-typo-body-1-opacity{margin-top:8px;margin-bottom:0}
.cartDialog .product-prams-box{overflow-y:auto;max-height:initial!important;-webkit-overflow-scrolling:auto;}
.cartDialog .mdui-row .product-prams .mdui-btn.active{color:#E94D8A;border-color:#E94D8A}
.cartDialog .originalPriceHtml{font-size:12px;color:#bcbcbc;text-decoration:line-through;}
.cartDialog .originalPriceHtml::before{margin-left:6px;content:'$';}
.cartDialog .btnBox{margin:0 auto;margin-top:16px;width:92%;border-radius:19px!important;overflow:hidden!important}
.cartDialog .btnBox .mdui-col-xs-6{padding:0;font-size:16px}
.cartDialog .btnBox #buyNow-dialog{border-top-left-radius:19px;border-bottom-left-radius:19px}
.cartDialog .btnBox #addToCart-dialog{border-top-right-radius:19px;border-bottom-right-radius:19px}
.cartDialog .btnBox button{height:38px;border-radius:0}
.cartDialog .mdui-btn.no-active{opacity:0.7!important}
#productSpecificationCurrency{font-size:8px}
#productSpecificationPrice+span{font-size:12px}
#confirmSelected{width:92%;height:38px;border-radius:19px;margin-top:16px!important;margin:0 auto;font-size:16px}
#noInventoryBtn{width:92%;height:38px;border-radius:19px;margin-top:16px!important;margin:0 auto;background:#ACACAC;color:#FFFFFF;font-size:16px;display:none}

/* 数量 */
.mdui-numbox + span{display:inline-block;font-size:12px;color:#E29EAD;line-height:12px;margin-left:6px}
.mdui-numbox{display:inline-block;position:relative;width:120px;height:28px;border:1px solid #EDEDED;border-radius:2px;color:#363636}
.mdui-numbox .mdui-numbox-btn{box-sizing:border-box;width:28px;height:28px;border:none;background:#FFF;outline:0;position:absolute;top:0;font-size:18px}
.mdui-numbox .mdui-numbox-btn i{top:50%;left:50%;margin-left:-8px;margin-top:-8px;position:absolute}
.mdui-numbox .mdui-numbox-btn .mdui-icon{font-size:16px;color:#363636}
.mdui-numbox .mdui-numbox-input{font-size:14px;width:100%;height:100%;margin:0;overflow:hidden;text-align:center;text-overflow:ellipsis;word-break:normal;border:none;outline:0;padding:0;text-align:center}
.mdui-numbox .mdui-numbox-btn-minus{left:0;border-right:1px solid #EDEDED}
.mdui-numbox .mdui-numbox-btn-plus{right:0;border-left:1px solid #EDEDED}

/* 换购 */
.mdui-promotion-container .mdui-promotions-product{position:relative;border-radius:2px;box-sizing:border-box;padding:12px 4%;display:flex;border-bottom:1px solid #EDEDED}
.mdui-promotion-container .mdui-promotions-product:last-child{border-bottom:none}
.mdui-promotion-container .mdui-pannel-content{padding:6px 0}
.mdui-promotion-container .mdui-pannel-header-subtitle{font-size:12px}
.mdui-promotion-container .mdui-pannel-header-subtitle .mdui-icon{font-size:20px;margin-top:-2px}
.mdui-promotion-container .mdui-promotions-product img{width:80px;height:80px;background:#F4F4F4}
.mdui-promotion-container .mdui-promotions-product img.check-icon{width:20px;height:20px;margin-top:30px;margin-right:10px;background:0 0}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info{flex-grow:1;border-left:none;padding-left:10px;box-sizing:border-box;max-width:calc(92% - 80px);height:80px;position:relative}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .title{overflow:hidden;text-overflow:ellipsis;display:inline-block;width:100%;white-space:nowrap;font-size:14px;color:#363636;margin-top:4px}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .sku{font-size:12px;color:#7B7B7B;margin-top:10px}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .bottom{margin-top:3px;}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .bottom .promotion-sku{font-size:12px;color:#7B7B7B;}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .bottom .promotion-price{font-size:14px;color:#E94D8A}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .bottom .promotion-original-price{font-size:12px;color:#7B7B7B;line-height:12px;text-decoration:line-through;margin-left:4px}
.mdui-promotion-container .mdui-promotions-product .mdui-promotions-product-info .bottom .bottom-price{position:absolute;bottom:0}

/* 选择规格 */
#chooseSize .label{color:#7B7B7B}
#chooseSize .mdui-list-item-content{text-align:right}
#chooseSize .mdui-list-item-content span{float:left}

/* 推荐 */
.mdui-card-aggregation>.mdui-card-media{padding-bottom:32%}
.mdui-card-aggregation>.mdui-card-media:after{content:'';display:block;position:absolute;bottom:-10px;left:50%;margin-left:-10px;width:20px;height:20px;background:#fff;border-radius:4px;transform:rotate(45deg)}
.mdui-card-aggregation>.mdui-card-media>img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0!important}
.mdui-card-aggregation .mdui-card-product-content{font-size:0;box-sizing:border-box;color:#363636;left:0;top:0;white-space:nowrap;padding:4%;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{display:inline-block;box-shadow:none;text-decoration:none;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product:last-child{margin-right:0}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-title{overflow:hidden;text-overflow:ellipsis;width:100%;white-space:nowrap;display:block;line-height:20px;font-size:14px}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-subtitle{overflow:hidden;text-overflow:ellipsis;width:100%;white-space:nowrap;display:block;line-height:20px;color:#7B7B7B;font-size:12px}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-discountprice{font-size:16px;color:#E94D8A;line-height:16px;display:inline-block;margin-right:6px}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-discountprice:before{content:'NT$';font-size:12px;margin-right:2px}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-originalPrice{color:#7B7B7B;font-size:12px;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-originalPrice:before{content:'NT$';font-size:12px;color:#7B7B7B;}
.mdui-card-aggregation .mdui-card-product-content .mdui-card-product-salecount{color:#7B7B7B;float:right;font-size:12px}
.suggestPro{display:none;}
.suggestPro .mdui-card{box-shadow:none}
.suggestPro .mdui-header{width:100%;height:14px;padding:20px 15px 2px;line-height:14px;color:#363636;font-size:16px;}
.suggestPro .mdui-card-aggregation{border-radius:0}
.suggestPro .mdui-card-aggregation .mdui-card-product-content{-webkit-overflow-scrolling:touch;overflow-x:auto;}
.suggestPro .mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:37%;margin-right:2%;}

@media (max-width:480px) {
.suggestPro .mdui-card-product-content{background:#f4f4f4;color:#000;padding:10px 4% 10px;box-sizing:border-box;display:flex;justify-content:space-between;flex-wrap:wrap; margin-bottom: 5px;}
.module-28-card{width:48.8%;}
}
@media (min-width:481px) and (max-width:768px) {
.suggestPro .mdui-card-product-content{background:#f4f4f4;color:#000;padding:8px 1%;box-sizing:border-box;display:flex;flex-wrap:wrap;}
.module-28-card{width:32.3%; float:left; margin:0 0.5%;}
}
@media (min-width:769px) {
.suggestPro .mdui-card-product-content{background:#f4f4f4;color:#000;padding:14px 1%;box-sizing:border-box;display:flex;flex-wrap:wrap;}
.module-28-card{width:24%; float:left; margin:0 0.5%;}
}
.module-28-card{margin-bottom:8px;box-shadow:none;box-sizing:border-box;text-decoration:none;border-radius:0px;background:#FFFFFF;border-radius:6px;}
.module-28-card > a{text-decoration:none;}
.module-28-card .mdui-card-media{padding-bottom:100%;}
.module-28-card .mdui-card-media > img{position:absolute;width:100%;height:100%;top:0;left:0;border-radius:0px!important;}
.module-28-content{padding:8px 8px 0;color:#000;}
.module-28-content .module-28-title{height:36px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;width:100%;font-size:13px;line-height:18px;margin-bottom:5px;}
.module-28-content .module-28-price{display:flex;align-items:baseline;justify-content:space-between;padding-top:4px;padding-bottom:2px;}
.module-28-content .module-28-price > span:last-child{font-size:12px;color:#777777;}
.module-28-content .module-28-discountprice{font-size:16px;color:#E94D8A;line-height:16px;display:inline-block;}
.module-28-content .module-28-discountprice:before{content:'NT$';font-size:12px;margin-right:2px;}
.module-28-content .module-28-tag{height:16px;overflow:hidden;font-size:0;}
.module-28-content .module-28-tag span{display:inline-block;padding:0 7px;border:none;border-radius:8px;font-size:12px;color:#E56214;line-height:16px;height:16px;background:#FCE9D5;box-sizing:border-box;margin-right:6px;}
.module-28-content .module-28-tag span:last-child{margin-right:0;}
.module-28-more{background:#FFF;color:#7B7B7B;padding:0;border:none!important}
.module-28-more a{position:relative;color:#7b7b7b;display:block;padding-bottom:100%;border:1px solid #f4f4f4;}
.module-28-more a .textBox{position:absolute;left:50%;margin-left:-25px;top:50%;margin-top:-28px;text-align:center;}
.module-28-more a span{display:inline-block;}
.module-28-more a span.circle{border:1px solid #7B7B7B;border-radius:50%;padding:3px;margin-top:12px;}

/* iPhone5 */
@media(max-width:320px){
	.mdui-card-aggregation > .mdui-card-content{height:170px;}
}

/* 桌面级 */
@media(min-width:750px){
	.mdui-card-aggregation > .mdui-card-content{height:350px;}
}

/* 须知 */
#return-product-dialog{width:85%}
#return-product-dialog .mdui-dialog-actions{padding:0}
#return-product-dialog .mdui-dialog-actions .mdui-btn{background:#E94D8A;color:#FFF;text-align:center}
#return-product-dialog > .mdui-dialog-title{font-size:18px;color:#363636;padding:20px 15px 10px 15px;text-align:center}
#return-product-dialog > .mdui-dialog-title + .mdui-dialog-content{font-size:12px;color:#181818;padding:0 15px 10px 15px;line-height:18px;}
#return-product-dialog > .mdui-dialog-title + .mdui-dialog-content > p.red{display:block;color:#E94D8A;line-height:18px;margin:0}
#return-product-dialog > .mdui-dialog-title + .mdui-dialog-content > p.red:first-child{margin-top:12px;}
#return-product-dialog > .mdui-dialog-title + .mdui-dialog-content > p.red:last-child{margin-bottom:12px;}
@media (min-width:600px){
	#return-product-dialog{width:60%}
	#return-product-dialog > .mdui-dialog-title + .mdui-dialog-content > p > span{display:inline-block;margin-left:20px}
}

/* 供应 */
.supply-detail{padding:18px 4%;font-size:12px}

/* .supply-detail p{margin: 0 0 8px 0;color: #000;text-align: center;line-height: 12px} */
.supply-detail p{margin:18px -4% 0 0;color:#000;text-align:left;line-height:14px;font-size:14px;padding:15px 0 0;border-top:1px solid #EDEDED}
.supply-detail .supply-mes ul{list-style:none;padding:0 29px;display:flex;align-items:center;margin:0;justify-content:space-between;}
.supply-detail .supply-mes ul:last-child{margin-top:5px}
.supply-detail .supply-mes ul:first-child li:nth-child(2n+1){width:42px;height:42px;border:1px solid #BCBCBC;border-radius:50%;text-align:center}
.supply-detail .supply-mes ul li:nth-child(2n+1) img{height:22px;margin-top:10px;}
.supply-detail .supply-mes ul:first-child li:nth-child(2n){flex:1;border-bottom:1px solid #BCBCBC;margin:0 4px}

/* 赠 */
.giftTip{background:rgba(180,39,45,.1);color:#E94D8A;font-size:12px;line-height:12px;width:100%;padding-left:16px!important;box-sizing:border-box;margin-bottom:15px;margin-top:-10px;font-weight:bold;padding:8px 0px;}
.giftNumber{display:inline-block;height:24px;line-height:24px;background:rgba(180,39,45,.1);color:#E94D8A;padding:0px 8px;border-radius:12px;}
.fixed-productId{display:none;}
@media(max-width:750px){
	.fixed-productId{position:fixed;right:2.8%;top:18%;z-index:9;background:rgba(0,0,0,.3);color:#FCFCFC;padding:4px 10px;border-radius:12px;font-size:12px;display:block;}
	.fixed-productId-show{display:block;}
	.fixed-productId-hide{display:none;}
}
.productSpecificationName{overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;}

/* 購物車 */
.mdui-cart-products-container{margin-top:-1px;padding:18px 5px}
.mdui-cart-products{background:#FFFFFF;margin-bottom:10px;border-radius:6px;overflow:hidden}
.mdui-cart-products .mdui-list-item{padding:0;font-size:0;flex-wrap:wrap;margin-bottom:24px;border:0;}
.mdui-cart-products .mdui-list-item:after{height:0}
.mdui-cart-products .mdui-list-item.gift-item{padding-bottom:6px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark{display:block;width:100%;color:#BCBCBC;line-height:10px;margin-top:8px}
.mdui-cart-products .mdui-list-item .mdui-list-item-remark span{font-size:12px;display:block;transform:scale(0.8);}
.mdui-cart-products .mdui-list-item .swiper-wrapper .swiper-slide{display:flex;align-items:center;}
.mdui-cart-products .mdui-list-item:hover{background-color:initial;}
.mdui-cart-products .mdui-list-item .check-icon,.mdui-cart-products .mdui-list-item .exchange-check{width:20px;height:20px;margin-right:15px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title{font-size:14px;color:#000;height:18px}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(242,143,45,.1);color:#F56207;margin-right:3px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-title .mdui-list-item-promotion-tags-depletion span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-list-item .mdui-list-item-text{font-size:12px;color:#7B7B7B;height:12px;margin-top:8px}
.mdui-cart-products .mdui-cart-product-sku-img{width:80px;height:80px;margin-right:10px;vertical-align:middle;}
.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 130px);height:80px;position:relative;top:0px;float:right;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2{position:absolute;width:100%;bottom:0px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price{font-size:14px;color:#E94D8A;position:absolute;bottom:0;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .mdui-list-item-text-price .original-price{font-size:12px;color:#7B7B7B;text-decoration:line-through;padding-left:2px}
.mdui-cart-products .delete-icon{width:22px;height:22px;position:relative;top:2px;margin-right:18px;}
.mdui-cart-products .mdui-list{padding:0px!important;}
.mdui-cart-products .mdui-list > .mdui-divider-inset{height:8px;margin:0px!important;background:#F4F4F4;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags{display:inline-block;border-radius:8px;height:16px;padding:3px 4px;box-sizing:border-box;background:rgba(180,39,45,.1);color:#E94D8A;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-promotion-tags span{font-size:12px;display:block;text-align:center;transform:scale(0.8);line-height:10px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text{bottom:4px;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .promotion-num{font-size:12px;color:#7B7B7B;}
.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2.promotion-text .mdui-list-item-text-price{top:0px;}
.mdui-cart-products .mdui-cart-products-bottom{margin:0 0 0 4%;padding-right:4%;margin-left:calc(4% + 35px);height:40px;line-height:40px;border-top:1px solid #EDEDED;cursor:pointer;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right{color:#E94D8A;}
.mdui-cart-products .mdui-cart-products-bottom .mdui-float-right .mdui-icon{color:#E94D8A;margin-top:-2px;margin-right:-10px;}
.mdui-cart-products .swiper-container{width:100%;}
.mdui-cart-products .swiper-slide.menu{font-size:14px;min-width:80px;width:20%;max-width:100px;background:#dd524d;color:#FFF;height:80px;display:flex;justify-content:center;align-items:center;margin-left:4px;}
.mini-cart{z-index:5099;}
.mini-cart .view-bottom-head{border-bottom:1px solid #EDEDED;}
.mini-cart .view-bottom-head span{color:#E29EAD;font-size:14px;line-height:14px;}
.mini-cart .view-bottom-head button{display:inline-flex;align-items:center;}
.mini-cart .view-bottom-footer{display:flex;align-items:center;padding:0;box-shadow:0px -1px 0px 0px rgba(237,237,237,1);}
.mini-cart .info{flex:1;padding:0 16px;height:50px;display:flex;flex-direction:column;justify-content:center;}
.mini-cart .info div:first-child{font-size:12px;line-height:12px;color:#E94D8A;}
.mini-cart .info div:last-child{font-size:12px;line-height:12px;color:#7B7B7B;padding-top:4px;}
.mini-cart .info div span{font-size:22px;line-height:22px;}
.mini-cart #buyNow{height:50px;width:36%;border-radius:0px;line-height:50px;border:0;}
.mini-cart #buyNow.no-active{background-color:#EF9A9A!important;}
.mini-cart #buyNow:active{opacity:1;}
.mdui-numbox{display:inline-block;position:relative;width:100px;height:28px;border:1px solid #EDEDED;border-radius:2px;color:#363636;}
.mdui-numbox .mdui-numbox-btn i{top:50%;left:50%;margin-left:-8px;margin-top:-8px;position:absolute;}
.mdui-numbox .mdui-numbox-btn{width:28px;height:28px;border:none;background:#FFF;outline:none;position:absolute;top:0;cursor:pointer;}
.mdui-numbox .mdui-numbox-btn .mdui-icon{font-size:16px;color:#363636;}
.mdui-numbox .mdui-numbox-input{font-size:14px;width:100%;height:100%;margin:0;overflow:hidden;text-align:center;text-overflow:ellipsis;word-break:normal;border:none;outline:none;padding:0;text-align:center;}
.mdui-numbox .mdui-numbox-btn-minus{left:0;border-right:1px solid #EDEDED}
.mdui-numbox .mdui-numbox-btn-plus{right:0;border-left:1px solid #EDEDED}
@media (min-width:600px){
	.mdui-dialog{width:60%}
	.mdui-dialog.mdui-noemail-dialog > .mdui-dialog-title + .mdui-dialog-content > p > span{display:inline-block;margin-left:20px}
}

/*適配iphone5*/
@media (max-width:320px){
	.mdui-cart-products .mdui-cart-product-sku-img{width:64px;height:64px;}
	.mdui-numbox{width:85px;}
	.mdui-cart-products .mdui-cart-product-sku-info{width:calc(100% - 109px);height:64px;}
	.mdui-cart-products .mdui-cart-product-sku-info .mdui-list-item-text2 .delete-icon{margin:0px 4px;}
	.mdui-cart-products .swiper-slide.menu{height:64px;}
	.mdui-card-aggregation > .mdui-card-product-content .mdui-card-product{width:110px;}
	.mdui-card-aggregation > .mdui-card-content{height:190px;}
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:100px;}
}
@media (min-width:750px){
	.mdui-card-aggregation .mdui-card-product-content .mdui-card-product{width:180px;}
	.mdui-card-aggregation > .mdui-card-content{height:auto;}
}
.delgoods{top:218.5px;height:230px;}

.product-details div { max-width:100%; }
.product-details img { max-width:100%; }
.mdui-call-m {
    width: 100%;
    height:50px;
    color:#7b7b7b;
    background: #FFF;
    position: fixed;
    margin: 0;
    bottom:0;
    border-top: 1px solid #DFDEDB;
    z-index:999;
}
.mdui-call-m .mdui-call-text-m {
    text-align: center;
    line-height: 50px;
}
.share-pannel{width:100%;height:34px;line-height: 34px;float:left;margin: 20px 5px;}
.share-pannel span{font-size: 14px;float:left;}
.share-pannel ul{list-style: none;padding: 0 5px;margin: 0;display: flex;}
.share-pannel ul li{ margin-right: 5px;}
.details-desc-title{width:100%; height:40px; margin-top: 50px; float:left;}
.details-desc-title .h2{ font-size:24px; font-weight: 600;}
.summa{ font-size:10px; color:#acaaaa;padding:10px 4px; float:left;}
</style>