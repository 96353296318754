<template>
    <div class="shareDetail tip-screen">
        <div class="tip-screen-box tip-screen-box-open">
            <div class="tip-screen-content share-content">
                <h3>分享到</h3>
                <ul>
                    <li>
                        <a :href="'https://www.facebook.com/sharer/sharer.php?u='+encodeURI(url)+'&amp;src=sdkpreparse'">
                            <img src="../../assets/images/icon-facebook.png">
                            <span>Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a :href="'http://line.naver.jp/R/msg/text/?分享一個好物給你，商品連結：'+encodeURI(url)">
                            <img src="../../assets/images/icon-line.png">
                            <span>LINE</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:;" class="copy-btn"  v-clipboard:copy="url" v-clipboard:success="copy">
                            <img src="../../assets/images/icon-copy.png">
                            <span>複製連結</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="tip-screen-footer close" @click="cancel">取消</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "share",
        data(){
            return {
                url:'',
                config:{}
            }
        },
        props:{},
        methods: {
            cancel:function(){
                this.$emit('cancel',true);
            },

            copy(e){
                this.$emit('cancel',true);
                this.$layer.msg( '商品連結複製成功' );
            }
        },
        watch: {},
        mounted() {
            this.url = window.location.protocol+"//"+window.location.host + this.$route.fullPath;
            this.config = this.utils.config()

        }
    }
</script>